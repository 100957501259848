import React, { useState, useEffect } from "react";
import { Table, Checkbox } from "antd";
import { deleteRequestList, getNonJoinersDetails } from "../../../Services/authHeader";
import NonJoiner from '../../AdminDashboard/Reviews/addNonJoinersForm';


const NonJoiners = ({ referenceDoctype, isDeleteMode }) => {
  const [employees, setEmployees] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const getDeletionListing = async () => {
    try {
      const doctype = "Deletion Request";
      const res = await deleteRequestList(doctype);

      if (res?.data?.message) {
        const fetchedData = res.data.message;

        const filteredData = fetchedData.filter(
          (item) =>
            item.reference_doctype === referenceDoctype &&
            item.request_type === "Delete"
        );

        setEmployees(filteredData);
      } else {
        console.log("No data received from API");
      }
    } catch (error) {
      console.error("Error fetching deletion listing:", error);
    }
  };

  const handleSelectRow = (e, key) => {
    if (e.target.checked) {
      setSelectedRows((prev) => [...prev, key]);
    } else {
      setSelectedRows((prev) => prev.filter((row) => row !== key));
    }
  };

  const isSuperAdmin = true;

  useEffect(() => {
    getDeletionListing();
  }, [referenceDoctype]);

  const columns = [
    ...(isDeleteMode
      ? [
          {
            title: "Select",
            key: "select",
            render: (_, record) => (
              <Checkbox
                onChange={(e) => handleSelectRow(e, record.reference_name)}
                checked={selectedRows.includes(record.reference_name)}
              />
            ),
          },
        ]
      : []),
    { title: "ID", dataIndex: "reference_name", key: "reference_name" },
    { title: "Submitted By", dataIndex: "request_creator", key: "request_creator" },
    { title: "Amended From", dataIndex: "amended_from", key: "amended_from" },
    { title: "Details", dataIndex: "details", key: "details" },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Action",
      key: "view",
      render: (_, record) => (
        <button
          onClick={() => handleViewClick(record)}
          className="text-blue-600 hover:underline"
        >
          View
        </button>
      ),
    },
  ];

  const handleViewClick = async (record) => {
    try {
      const res = await getNonJoinersDetails(record.reference_name);
      if (res?.data) {
        setSelectedEmployee(res.data.data); // Assuming the response data contains the details you need
        setShowForm(true);
      } else {
        console.log("No details found for this record");
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setSelectedEmployee(null);
  };

  return (
    <div>
      {showForm ? (
       <NonJoiner
       employee={selectedEmployee}
       onClose={handleCloseForm}
      //  reset={handleReset}
       // onFormSubmit={handleFormSubmit}
       isSuperadmin={isSuperAdmin}
     />
      ) : (
        <Table
          dataSource={employees}
          columns={columns}
          pagination={{ pageSize: 10 }}
          rowKey="reference_name"
        />
      )}
    </div>
  );
};

export default NonJoiners;
