import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import "bootstrap/dist/js/bootstrap.bundle.min";
import "../src/style.css";

// import Navbar from './Widgets/navbar'
// Pages
import Home from "./Component/Pages/home";
import Contact from "./Component/Pages/contact";
import AboutUs from "./Component/Pages/aboutUs";
import Privacypolicy from "./Component/Pages/privacypolicy";
import TermsCondition from "./Component/Pages/termcondition";
import Career from "./Component/Pages/career";
import Dashboardnavbar from "./Widgets/Dashboardnavbar";
// import CommonNavbar from "./Widgets/CommonNavbar";
//Admin Authentication
import Login from "./Component/AdminAuth/login";
import ForgetPassword from "./Component/AdminAuth/forgetPassword";
import SignUp from "./Component/AdminAuth/signUp";
import SignupConfirmOtp from "./Component/AdminAuth/signupConfirmOtp";
import NotFound from "./Component/Pages/notFound";
import ConfirmOtp from "./Component/AdminAuth/confirmOTP";
import NewPassword from "./Component/AdminAuth/newPassword";
import Logout from "./Component/AdminAuth/logout";
// Super Admin Dashboard
import LoginSuperAdmin from "./Component/SuperAdminAuth/login";
import ForgetPasswordSuperAdmin from "./Component/SuperAdminAuth/forgetPassword";
import ConfirmOtpSuperAdmin from "./Component/SuperAdminAuth/confirmOtp";
import NewPasswordSuperAdmin from "./Component/SuperAdminAuth/newPassword";
import LoginOtpSuperAdmin from "./Component/SuperAdminAuth/loginOtp";
import SuperDashboard from "./Component/SuperAdminDashboard/SuperAdminDashboard";
import SuperSignup from "./Component/SuperAdminAuth/signup";
import CompanyApproveRequest from "./Component/SuperAdminDashboard/Company/approve-request";
import CompanyList from "./Component/SuperAdminDashboard/Company/company-list"; 
import SuperAdminsearch from "./Component/SuperAdminDashboard/search";
import SuperAdminDeleteRequest from "./Component/SuperAdminDashboard/deletion-request";
import SuperAdminDeleteRequestView from "./Component/SuperAdminDashboard/delete-request-view";
import SuperAdminDeleteSubmit from "./Component/SuperAdminDashboard/delete-submit";
import SuperAdminReports from "./Component/SuperAdminDashboard/Reports/reports";
import SuperAdminReportedReviews from "./Component/SuperAdminDashboard/Reports/reportedreviews";
import SuperAdminReportedReviewList from "./Component/SuperAdminDashboard/Reports/reportedreviewlist";
import SuperAdminReportedReviewLink from "./Component/SuperAdminDashboard/Reports/reportedreviewlink";
import SuperAdminReportedReviewView from "./Component/SuperAdminDashboard/Reports/reportedreviewview";
import SuperAdminReportedReviewSave from "./Component/SuperAdminDashboard/Reports/reportedreviewssave";
import SuperAdminReportedReviewDelete from "./Component/SuperAdminDashboard/Reports/reportedreviewsdelete";
import SuperAdminIssues from "./Component/SuperAdminDashboard/Issues/issues";
//import SuperAdminIssuecreated from "./Component/SuperAdminDashboard/issuecreated";

// Admin Dashboard
import HomeDashboard from "./Component/AdminDashboard/homeDashboard";
import Profile from "./Component/AdminDashboard/profile";
import Data from "./Component/AdminDashboard/Reviews/data";
import Billing from "./Component/AdminDashboard/billing"; 

// dummy
import Dummy from "../src/Component/AdminDashboard/dummy";
import { RestrictedAccess } from "./private-component/RestrictedAccess";
import AddUser from "./Component/AddUser"; 
import Reviews from "../src/Component/AdminDashboard/Reviews/review"
import PaymentCallback from "./Component/Pages/paymentCallback";
import Users from "./Component/AdminDashboard/Users"; 
import UploadButton from "./Component/uploadButton";
import ReviewsList from "./Component/SuperAdminDashboard/Reviews/Reviews-list"; 
import SuperAdminBilling from "./Component/SuperAdminDashboard/Billing/billing";
import Search from "./Component/AdminDashboard/SearchUser/search";


const App = () => {
  return (
    <>
      <Router>
        <Routes>
          {/* Pages */}
          <Route path="/" element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Carrer" element={<Career />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Dashboardnavbar" element={<Dashboardnavbar/>}/>
          {/* <Route path="/CommonNavbar" element={<CommonNavbar/>}/> */}
          <Route path="/Adduser" element={<AddUser/>}/>
          <Route path="/Reviews" element={<Reviews/>}/>
          <Route path="/Privacypolicy" element={<Privacypolicy/>} />
          <Route path="/TermsCondition" element={<TermsCondition/>} />
          <Route path="/Reviews" element={<Reviews/>} />
          <Route path="/UploadButton" element={<UploadButton/>} />

          {/* Authentication */}
          <Route path="/Login" element={<Login />} />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />
          <Route path="/ConfirmOtp" element={<ConfirmOtp />} />
          {/* <Route path="/NewPassword" element={<NewPassword />} /> */}
          <Route path="/NewPassword" element={<NewPassword />} />
          <Route path="/SignUp" element={<SignUp />} />
          <Route path="/signup/confirm-otp" element={<SignupConfirmOtp />} />
          <Route path="/Logout" element={<Logout />} />
          {/* Super Admin Authentication */}
          <Route path="/superAdmin/Login" element={<LoginSuperAdmin />} />
          <Route
            path="/superAdmin/forgetPassword"
            element={<ForgetPasswordSuperAdmin />}
          />
          <Route
            path="/superAdmin/confirmOtp"
            element={<ConfirmOtpSuperAdmin />}
          />
          <Route
            path="/superAdmin/newPassword"
            element={<NewPasswordSuperAdmin />}
          />
          <Route path="/superAdmin/loginOtp" element={<LoginOtpSuperAdmin />} />
          <Route path="/superAdmin/Dashboard" element={<SuperDashboard />} />
          <Route path="/superAdmin/Signup" element={<SuperSignup />} />
          <Route element={<RestrictedAccess />}>
            <Route
              path="/superAdmin/CompanyApproveRequest"
              element={<CompanyApproveRequest />}
            />
            <Route path="/superAdmin/CompanyList" element={<CompanyList />} />
            <Route path="/superAdmin/ReviewsList" element={<ReviewsList />} />
            <Route path="/superAdmin/Billing" element={<SuperAdminBilling />} />
            <Route path="/superAdmin/Search" element={<SuperAdminsearch />} />
            <Route
              path="/superAdmin/DeleteRequest"
              element={<SuperAdminDeleteRequest />}
            />
            <Route
              path="/superAdmin/DeleteRequestview"
              element={<SuperAdminDeleteRequestView />}
            />
            <Route
              path="/superAdmin/DeleteSubmit"
              element={<SuperAdminDeleteSubmit />}
            />
            <Route path="/superAdmin/Reports" element={<SuperAdminReports />} />
            <Route
              path="/superAdmin/ReportedReviews"
              element={<SuperAdminReportedReviews />}
            />
            <Route
              path="/superAdmin/ReportedReviewList"
              element={<SuperAdminReportedReviewList />}
            />
            <Route
              path="/superAdmin/ReportedReviewLink"
              element={<SuperAdminReportedReviewLink />}
            />
            <Route
              path="/superAdmin/ReportedReviewView"
              element={<SuperAdminReportedReviewView />}
            />
            <Route
              path="/superAdmin/ReportedReviewSave"
              element={<SuperAdminReportedReviewSave />}
            />
            <Route
              path="/superAdmin/ReportedReviewDelete"
              element={<SuperAdminReportedReviewDelete />}
            />
            <Route path="/superAdmin/Issues" element={<SuperAdminIssues />} />
            {/* <Route
              path="/superAdmin/Issuecreated"
              element={<SuperAdminIssuecreated />}
            /> */}
            {/* Dashboard */}
            <Route path="/HomeDashboard" element={<HomeDashboard />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Data" element={<Data />} />
            <Route path="/Users" element={<Users/>} />
            <Route path="/Billing" element={<Billing />} />
            <Route path="/Search" element={<Search />} />
            {/* <Route path="/user-details/:name" element={<UserDetails/>} /> */}
            <Route path="/dummy" element={<Dummy />} />
            <Route path="/paymentCallback" element={<PaymentCallback/>} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
