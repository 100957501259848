import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import AuthHeader from "../../Services/authHeader";
import Logo from "../../Assets/Images/Logo.png";
import Login1 from "../../Assets/Images/Login.jpg";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Axios from "../../Services/commonHttp";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Login = () => {
  const navigate = useNavigate();
  const [alertMssg, setAlertMssg] = useState(undefined);
  const loginValidation = Yup.object().shape({
    usr: Yup.string().required("Please enter your email or phone"),
    pwd: Yup.string().required("Please enter your password"),
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(true);
  };
  var handleMouseDownPassword = function (event) {
    event.preventDefault();
  };
  const formik = useFormik({
    initialValues: {
      usr: "",
      pwd: "",
    },
    validationSchema: loginValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      authenticateLogin(values);
    },
  });
  async function authenticateLogin(values) {
    try {
      await Axios({
        method: "Post",
        url: "/api/method/login",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          usr: values.usr,
          pwd: values.pwd,
        },
      })
        .then((response) => {
          console.log("response", response.status);
          setAlertMssg({ type: "Sucess" });
          if (response.status === 200) {
            navigate("/homeDashboard");
          } else {
            navigate("/Login");
          }
          if (response.data.full_name) {
            return sessionStorage.setItem("usr", response.data.full_name);
          }
          return response.data;
        })
        .catch((error) => {
          setAlertMssg({ type: "error", error });
        });
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <>
      {/* Logo */}

      <section class="gradient-form h-full bg-neutral-200 dark:bg-neutral-700">
        {/* <div class="container h-full p-10">
          <div class="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 dark:text-neutral-200"> */}
        <div class="w-100%">
          <div class="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
            <div class="g-0 lg:flex lg:flex-wrap">
              {/* <!-- Left column container--> */}
              <div class="px-4 md:px-0 form-side">
                <div className="bg-white  px-4 lg:px-6 py-12 ">
                  <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                    <a href="/" class="flex items-center">
                      {" "}
                      <img
                        src={Logo}
                        className="mr-3 h-6 sm:h-9 "
                        alt="Flowbite Logo"
                      />
                    </a>
                  </div>
                </div>
                <div class="md:mx-6 md:p-12 padding-top-1">
                  {/* show Alert Message Error */}
                  {/* {alertMssg?.type === "Sucess" && (
                        <p>Login Sucess</p>
                      )}
                      {alertMssg?.type === "error" && (
                        <p class ="color">
                          Username and password is wrong
                        </p>
                      )}                   */}
                  {/* <!--Logo--> */}

                  <div className="w-300 bg-white rounded-lg  dark:border md:mt-0 sm:max-w-md xl:p-1 w-100   dark:bg-white dark:border-white">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                      <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl pb-6 dark:text-gray-800">
                        Log In
                      </h1>
                      <form
                        onSubmit={formik.handleSubmit}
                        className="space-y-4 md:space-y-6"
                        action="#"
                      >
                        <div>
                          <label
                            for="email"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                          >
                            {/* Email or Phone */}
                          </label>
                          <TextField
                            label="Email or Phone"
                            variant="outlined"
                            type={"text"}
                            name="usr"
                            id="email"
                            value={formik.values.usr}
                            onChange={formik.handleChange}
                            className="bg- border border-gray-900 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-700 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            required=""
                          />
                          <p style={{ color: "red" }}>
                            {formik.errors.usr ? formik.errors.usr : ""}
                          </p>
                        </div>
                        <div>
                          <label
                            for="password"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                          >
                            {/* Password */}
                          </label>
                          <TextField
                            label="Password"
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            name="pwd"
                            onChange={formik.handleChange}
                            id="password"
                            value={formik.values.pwd}
                            className="bg-gray-50 border border-gray-900 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-800 dark:placeholder-gray-400 dark:text-black-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            required=""
                            InputProps={{
                              // <-- This is where the toggle button is added.
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    background-color="dark-black"
                                    class="password-eye"
                                    aria-label="toggle password visibility"
                                    onClick={handleShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? (
                                      <AiFillEye />
                                    ) : (
                                      <AiOutlineEyeInvisible />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <p style={{ color: "red" }}>
                            {formik.errors.pwd ? formik.errors.pwd : ""}
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="flex items-start">
                            <div className="flex items-center h-5"></div>
                            <div className="ml-3 text-sm"></div>
                          </div>
                          <Link
                            to="/ForgetPassword"
                            className="text-sm font-normal text-gray-900  dark:text-gray-800"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                        {alertMssg?.type === "error" && (
                          <div
                            class="mb-4 rounded-lg  px-6 py-5 text-base text-red-600"
                            role="alert"
                          >
                            Username and Password is Wrong !
                          </div>
                        )}
                        <button
                          type="submit"
                          className="w-full gradient-btn text-white bg-gray-900 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-800 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                        >
                          Log In
                        </button>
                        <p className="text-md text-center font-dark text-gray-800 dark:text-gray-900 ">
                          Don’t have an account?{" "}
                          <Link
                            to="/SignUp"
                            className="font-lg text-primary-800 hover: dark:text-primary-800"
                          >
                            Sign Up
                          </Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Right column container with background and description--> */}
              <div class="flex items-center rounded-b-lg image-side rounded-r-lg lg:rounded-bl-none justify-end">
                <img
                  src={Login1}
                  className="second-judge-banner"
                  alt="Flowbite Logo"
                />
              </div>
            </div>
          </div>
        </div>
        {/* </div>
        </div> */}
      </section>
    </>
  );
};

export default Login;
