import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../Assets/Images/Logo.png";
import Login from "../../Assets/Images/Login.jpg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { TextField } from "@material-ui/core";
import AuthHeader from "../../Services/authHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ConfirmOtp = () => {
  const navigate = useNavigate();
  const ValidateOTP = Yup.object().shape({
    otp: Yup.string().required("Please enter the OTP "),
  });
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: ValidateOTP,
    onSubmit: (values) => {
      console.log(values);
      getOTP(values);
    },
  });

  function getOTP(values) {
    AuthHeader.otpValues(values.otp).then(
      () => {
        navigate("/newPassword");
        //window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        //setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  }

  return (
    <>
      <section class="gradient-form h-full bg-white dark:bg-white">
        <ToastContainer></ToastContainer>
        <div class="h-full">
          <div class=" h-full text-neutral-800 dark:text-neutral-200">
            <div class="w-90%">
              <div class="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
                <div class="g-0 lg:flex lg:flex-wrap">
                  {/* <!-- Left column container--> */}
                  <div class="px-4 md:px-0 form-side">
                    <div className="bg-white  px-4 lg:px-6 py-12 ">
                      <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                        <a href="/" class="flex items-center">
                          {" "}
                          <img
                            src={Logo}
                            className="mr-3 h-6 sm:h-9 nav-logo"
                            alt="Flowbite Logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="md:mx-6 md:p-12 padding-top-1">
                      {/* <!--Logo--> */}

                      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold pb-6 leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                          Confirm OTP
                        </h1>
                        <form
                          onSubmit={formik.handleSubmit}
                          className="space-y-4 md:space-y-6"
                          action="#"
                        >
                          <div>
                            <label
                              for="email"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                            >
                              {/* Enter OTP */}
                            </label>
                            <TextField
                              label="Confirm OTP"
                              variant="outlined"
                              type={"text"}
                              value={formik.values.otp}
                              name="otp"
                              onChange={formik.handleChange}
                              id="email"
                              className="bg- border border-gray-400 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-700 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Enter OTP"
                              required=""
                            />
                            <p style={{ color: "red" }}>
                              {formik.errors.otp ? formik.errors.otp : ""}
                            </p>
                          </div>
                          <a class="resend-otp mt-3 float-right" href="#">
                            Resend OTP
                          </a>
                          <button
                            type="submit"
                            className="w-full gradient-btn text-white bg-gray-800 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-800 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                          >
                            Verify OTP
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Right column container with background and description--> */}
                  <div class="flex items-center rounded-b-lg image-side lg:rounded-r-lg lg:rounded-bl-none justify-end">
                    <img
                      src={Login}
                      className="second-judge-banner"
                      alt="Flowbite Logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConfirmOtp;
