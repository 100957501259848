import React,{useState} from "react";
import { MdNavigateNext } from "react-icons/md";

const Approve = () => {

  return (
    <>

<div class="verflow-x-auto mr-10 shadow-md border border-gray-300 sm:rounded-lg">
          <table class="w-full approve-table text-sm text-left text-gray-500 dark:text-gray-400">
            <caption class="caption-top top-border p-1 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
              <div class="container1 mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <span class="ml-1 text-xl review-list">Deletion Request</span>
                  </div>
                  </caption>
                  <thead class="text-xs text-gray-700 uppercase search-table dark:bg-gray-700 dark:text-gray-400">
                    <tr><th scope="col" class="px-6 py-3">check box</th><th scope="col" class="px-6 py-3">ID</th><th scope="col" class="px-6 py-3">Company</th><th scope="col" class="px-6 py-3">Submitted by</th><th scope="col" class="px-6 py-3">creation Date</th><th scope="col" class="px-6 py-3">Request Date</th><th scope="col" class="px-6 py-3">status</th><th scope="col" class="px-6 py-3">View</th></tr></thead>
                    <tbody>
                        <tr className="border-bottom-row">
                      <td  class="px-6 py-6 detele-submit-checkbox"><input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/><label for="styled-checkbox-1"></label></td>
                      <td  class="px-6 py-6">12345662</td>
                      <td  class="px-6 py-6">Company 1</td>
                      <td  class="px-6 py-6">Rajat</td>
                      <td  class="px-6 py-6">10/10/22</td>
                      <td  class="px-6 py-6">10/10/22</td>
                      <td  class="px-6 py-6">Draft</td>
                      
                      <td  class="px-6 py-6"><a href='/superAdmin/DeleteRequestview' className="view-btn">View</a></td>
                      </tr>
                      <tr className="border-bottom-row">
                      <td  class="px-6 py-6 detele-submit-checkbox"><input class="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2"/><label for="styled-checkbox-2"></label></td>

                      <td  class="px-6 py-6">12345662</td>
                      <td  class="px-6 py-6">Company 2</td>
                      <td  class="px-6 py-6">Kapil</td>
                      <td  class="px-6 py-6">10/10/22</td>
                      <td  class="px-6 py-6">10/10/22</td>
                      <td  class="px-6 py-6">Done</td>
                      <td  class="px-6 py-6"><a href='/superAdmin/DeleteRequestview' className="view-btn">View</a></td>

                      </tr>
                      <tr className="border-bottom-row">
                      <td  class="px-6 py-6 detele-submit-checkbox"><input class="styled-checkbox" id="styled-checkbox-3" type="checkbox" value="value3"/><label for="styled-checkbox-3"></label></td>

                      <td  class="px-6 py-6">12345662</td>
                      <td  class="px-6 py-6">Company 3</td>
                      <td  class="px-6 py-6">Nancy</td>
                      <td  class="px-6 py-6">10/10/22</td>
                      <td  class="px-6 py-6">10/10/22</td>
                      <td  class="px-6 py-6">Done</td>

                      <td  class="px-6 py-6"><a href='/superAdmin/DeleteRequestview' className="view-btn">View</a></td>

                      </tr>
                      <tr className="border-bottom-row">
                      <td  class="px-6 py-6 detele-submit-checkbox"><input class="styled-checkbox" id="styled-checkbox-4" type="checkbox" value="value4"/><label for="styled-checkbox-4"></label></td>

                      <td  class="px-6 py-6">12345662</td>
                      <td  class="px-6 py-6">Company 4</td>
                      <td  class="px-6 py-6">Mahi</td>
                      <td  class="px-6 py-6">10/10/22</td>
                      <td  class="px-6 py-6">10/10/22</td>
                      <td  class="px-6 py-6">Done</td>

                      <td  class="px-6 py-6"><a href='/superAdmin/DeleteRequestview' className="view-btn">View</a></td>

                      </tr>
                      <tr className="border-bottom-row">
                      <td  class="px-6 py-6 detele-submit-checkbox"><input class="styled-checkbox" id="styled-checkbox-5" type="checkbox" value="value5"/><label for="styled-checkbox-5"></label></td>

                      <td  class="px-6 py-6">12345662</td>
                      <td  class="px-6 py-6">Company 5</td>
                      <td  class="px-6 py-6">Sneha</td>
                      <td  class="px-6 py-6">10/10/22</td>
                      <td  class="px-6 py-6">10/10/22</td>
                      <td  class="px-6 py-6">Done</td>

                      <td  class="px-6 py-6"><a href='/superAdmin/DeleteRequestview' className="view-btn">View</a></td>

                      </tr>
                      <tr className="border-bottom-row">
                      <td  class="px-6 py-6 detele-submit-checkbox"><input class="styled-checkbox" id="styled-checkbox-6" type="checkbox" value="value6"/><label for="styled-checkbox-6"></label></td>

                      <td  class="px-6 py-6">12345662</td>
                      <td  class="px-6 py-6">Company 6</td>
                      <td  class="px-6 py-6">Sujal</td>
                      <td  class="px-6 py-6">10/10/22</td>
                      <td  class="px-6 py-6">10/10/22</td>
                      <td  class="px-6 py-6">Draft</td>

                      <td  class="px-6 py-6"><a href='/superAdmin/DeleteRequestview' className="view-btn">View</a></td>

                      </tr>
                      <tr className="border-bottom-row">
                      <td  class="px-6 py-6 detele-submit-checkbox"><input class="styled-checkbox" id="styled-checkbox-7" type="checkbox" value="value7"/><label for="styled-checkbox-7"></label></td>

                      <td  class="px-6 py-6">12345662</td>
                      <td  class="px-6 py-6">Company 7</td>
                      <td  class="px-6 py-6">Aryan</td>
                      <td  class="px-6 py-6">10/10/22</td>
                      <td  class="px-6 py-6">10/10/22</td>
                      <td  class="px-6 py-6">Done</td>

                      <td  class="px-6 py-6"><a href='/superAdmin/DeleteRequestview' className="view-btn">View</a></td>

                      </tr>

                      </tbody>
                      </table>
                      
                      </div>
                      <div className="container1 mx-auto flex flex-wrap p-8 flex-col md:flex-row items-center pr-0 pb-0 mr-10 mb-20">
          <div class="md:ml-auto flex flex-wrap items-center text-base justify-center pr-0">
            <p className="mr-3">Next</p>
            <button
              // onClick={nextPage}
              class="bg-gray-800 hover: text-white font-base py-2 px-4 rounded"
            >
              <MdNavigateNext />
            </button>
          </div>
                      </div>
    </>
  );
};
export default Approve;
