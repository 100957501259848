import React from "react";

// import AuthHeader from "../../Services/authHeader";
import Logo from "../../Assets/Images/Logo.png";
import Login1 from "../../Assets/Images/Login.jpg";
import { TextField } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import Axios from '../../Services/commonHttp'
const LoginOtp = () => {
  //   const navigate = useNavigate();
  const EmailValidation = Yup.object().shape({
    usr: Yup.string().required("Please Enter Your Email or Phone"),
  });
  const OtpValidation = Yup.object().shape({
    otp: Yup.string().required("Please Enter Your Otp"),
    pwd: Yup.string().required("Please Enter Your Password"),
  });

  const formkiValidation = useFormik({
    initialValues: {
      usr: "",
    },
    validationSchema:EmailValidation,
    onSubmit: (values) => {
        fetchOtpandPassword(values)
    },
  });
  const formikOtpValidation = useFormik({
    initialValues: {
      otp: "",
      pwd: "",
    },
    validationSchema:OtpValidation ,
    onSubmit: (values) => {
        getOtpValue(values)
    },
  });
  const getOtpValue=async(values)=>{
    try{
        await Axios({
        method:'Post',
        url:'',
        data:{
            usr:values.usr
        }
        }).then(response=>{
            return response.data
        })
    }catch(error){
        console.log('error',error)
    }
  }
  const fetchOtpandPassword=async(values)=>{
    try{
        await Axios({
            method:'Post',
            url:'',
            data:{
                otp:values.otp,
                pwd:values.pwd
            }
        }).then(response=>{
            return response.data
        })
    }catch(error){
        console.log('error',error) 
    }
  }

  return (
    <>
      {/* Logo */}

      <section class="gradient-form h-full bg-neutral-200 dark:bg-neutral-700">
        {/* <div class="container h-full p-10">
          <div class="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 dark:text-neutral-200"> */}
        <div class="w-100%">
          <div class="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
            <div class="g-0 lg:flex lg:flex-wrap">
              {/* <!-- Left column container--> */}
              <div class="px-4 md:px-0 lg:w-6/12">
                <div className="bg-white  px-4 lg:px-6 py-12 ">
                  <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                    <img
                      src={Logo}
                      className="mr-3 h-6 sm:h-9"
                      alt="Flowbite Logo"
                    />
                  </div>
                </div>
                <div class="md:mx-6 md:p-12">
                  {/* <!--Logo--> */}

                  <div className="w-300 bg-white rounded-lg  dark:border md:mt-0 sm:max-w-md xl:p-1 w-100   dark:bg-white dark:border-white">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                      <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                        Login
                      </h1>
                      <form
                        onSubmit={formkiValidation.handleSubmit}
                        className="space-y-4 md:space-y-6"
                        action="#"
                      >
                        <div>
                          <label
                            for="email"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                          >
                            {/* Email or Phone */}
                          </label>
                          <TextField
                            label="Email or phone"
                            variant="outlined"
                            type="text"
                            name="usr"
                            id="email"
                            value={formkiValidation.values.usr}
                            onChange={formkiValidation.handleChange}
                            className="bg- border border-gray-900 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-700 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            required=""
                          />
                          <p style={{ color: "red" }}>
                            {formkiValidation.errors.usr
                              ? formkiValidation.errors.usr
                              : ""}
                          </p>
                        </div>
                        <button
                          type="submit"
                          className="w-full bg-otp-btn text-gray-700 bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-800 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                        >
                          Get OTP
                        </button>
                      </form>
                      <form onSubmit={formikOtpValidation.handleSubmit}>
                        <div>
                          <label
                            for="password"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                          >
                            {/* Otp*/}
                          </label>
                          <TextField
                            label="Enter OTP"
                            variant="outlined"
                            type="text"
                            name="otp"
                            onChange={formikOtpValidation.handleChange}
                            id="password"
                            value={formikOtpValidation.values.otp}
                            className="bg- border border-gray-900 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-800 dark:placeholder-gray-400 dark:text-black-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            required=""
                          />
                          <p style={{ color: "red" }}>
                            {formikOtpValidation.errors.otp
                              ? formikOtpValidation.errors.otp
                              : ""}
                          </p>
                        </div>
                        &nbsp;
                        <div>
                          <label
                            for="password"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                          >
                            {/* Password */}
                          </label>
                          <TextField
                            label="Password"
                            variant="outlined"
                            type="password"
                            name="pwd"
                            onChange={formikOtpValidation.handleChange}
                            id="password"
                            value={formikOtpValidation.values.pwd}
                            className=" border border-gray-900 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-800 dark:placeholder-gray-400 dark:text-black-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            required=""
                          />
                          <p style={{ color: "red" }}>
                            {formikOtpValidation.errors.pwd
                              ? formikOtpValidation.errors.pwd
                              : ""}
                          </p>
                        </div>
                        <div className="p-3 flex items-center justify-between">
                          <div className="flex items-start"></div>
                          <Link
                            to="/ForgetPassword"
                            className="text-sm font-medium text-gray-900  dark:text-gray-800"
                          >
                            Forgot Password ?
                          </Link>
                        </div>
                        <button
                          type="submit"
                          className="w-full text-white bg-gray-900 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-800 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                        >
                          LogIn
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Right column container with background and description--> */}
              <div class="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none">
                <img src={Login1} className="" alt="Flowbite Logo" />
              </div>
            </div>
          </div>
        </div>
        {/* </div>
        </div> */}
      </section>
    </>
  );
};

export default LoginOtp;
