import React, { useState } from "react";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

function DynamicTable({
  data,
  columns,
  title,
  recordsPerPage,
  isNonExEmployee,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  if (!Array.isArray(data)) {
    console.error("Data should be an array");
    return null;
  }

  const totalPages = Math.ceil(data.length / recordsPerPage);

  const renderTableHeaders = () => {
    if (!columns || columns.length === 0) return null;

    return (
      <>
        {columns.map((column, index) => (
          <th key={index} className="px-6 py-3">
            {column.header}
          </th>
        ))}
        <th className="px-6 py-3">View</th>
      </>
    );
  };

  const handleViewClick = (entry) => {
    const apiEndpoint = isNonExEmployee
      ? `/api/resource/Non Joiners/${entry.name}`
      : `/api/resource/Ex Employee Reviews/${entry.name}`;
    
    fetch(apiEndpoint)
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched data:", data);
        // Logic to open the form and populate it with data
        // For example, set state to show modal/form
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const renderTableRows = () => {
    const startIndex = (currentPage - 1) * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;
    const visibleData = data.slice(startIndex, endIndex);

    return visibleData.map((rowData, rowIndex) => (
      <tr
        key={rowIndex}
        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
      >
        {columns.map((column, cellIndex) => (
          <td key={cellIndex} className="px-6 py-4">
            {rowData[column.dataKey]}
          </td>
        ))}
        <td className="px-6 py-4">
          <button
            onClick={() => handleViewClick(rowData)}
            className="text-blue-600 hover:underline"
          >
            View
          </button>
        </td>
      </tr>
    ));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = startPage + maxPagesToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`px-3 py-1 mx-1 ${
            i === currentPage
              ? "bg-gray-800 text-white"
              : "bg-white text-gray-800"
          }`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }

    if (startPage > 1) {
      pageNumbers.unshift(
        <span key="start-ellipsis" className="px-3 py-1 mx-1">
          ...
        </span>
      );
      pageNumbers.unshift(
        <button
          key={1}
          className={`px-3 py-1 mx-1 ${
            1 === currentPage
              ? "bg-gray-800 text-white"
              : "bg-white text-gray-800"
          }`}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <span key="end-ellipsis" className="px-3 py-1 mx-1">
          ...
        </span>
      );
      pageNumbers.push(
        <button
          key={totalPages}
          className={`px-3 py-1 mx-1 ${
            totalPages === currentPage
              ? "bg-gray-800 text-white"
              : "bg-white text-gray-800"
          }`}
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className={`${isNonExEmployee ? "non-ex-employee" : ""}`}>
      <div className={`relative overflow-x-auto shadow-md sm:rounded-lg`}>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <caption className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            {title}
          </caption>
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 table-header">
            <tr>{renderTableHeaders()}</tr>
          </thead>
          <tbody className="table-data">{renderTableRows()}</tbody>
        </table>
      </div>
      <div className="flex justify-between">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="bg-gray-800 hover:text-white font-base mr-6 py-2 px-4 rounded"
        >
          <div className="flex">
            <MdNavigateBefore />
            <p>Previous</p>
          </div>
        </button>

        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="bg-gray-800 hover:text-white font-base mr-6 py-2 px-4 rounded"
        >
          <div className="flex">
            <MdNavigateNext />
            <p>Next</p>
          </div>
        </button>
      </div>
      <div className="flex justify-center mt-4">{renderPageNumbers()}</div>
    </div>
  );
}

export default DynamicTable;
