import React from "react";
import Coma from "../Assets/Images/coma.webp";
import Client from "../Assets/Images/sagar.webp";

const Box = () => {
 
  return (
    
    <>
    <div className="relative p-8 bg-white shadow-md boxes">
    <img src={Coma} className="coma" alt="coma" />
    <p className="mt-8 text-gray-600 box-para">
              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.
            </p>
            <div className="flex items-center mt-4">
            <img src={Client} className="client" alt="Client" />

              <div className="ml-3">
                <p className="text-sm font-semibold">Sagar Saroha</p>
                <p className="text-xs text-gray-500">Sagar Ltd.</p>
              </div>
      </div>
      </div>
    </>
  );
};

export default Box;
