import React from "react";
import Logo from "../../Assets/Images/logo.svg";

function LoginLeft({ children }) {
  return (
    <div className="px-4 md:px-0 form-side">
      <div className="logo">
        <a href="/" className="flex items-center">
          <img src={Logo} className="nav-logo" alt="Flowbite Logo" />
        </a>
      </div>
      <div className="login-form">
        {children}
      </div>
    </div>
  );
}

export default LoginLeft;