import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Select } from "antd";

const SubscriptionChart = () => {
  const [timeRange, setTimeRange] = useState("Weekly");

  // Data for different views
  const dataWeekly = [
    { name: "Week 1", uv: 30 },
    { name: "Week 2", uv: 45 },
    { name: "Week 3", uv: 25 },
    { name: "Week 4", uv: 60 },
  ];

  const dataMonthly = [
    { name: "Jan", uv: 50 },
    { name: "Feb", uv: 80 },
    { name: "Mar", uv: 45 },
    { name: "Apr", uv: 90 },
    { name: "May", uv: 75 },
    { name: "Jun", uv: 100 },
    { name: "Jul", uv: 85 },
    { name: "Aug", uv: 95 },
    { name: "Sep", uv: 60 },
    { name: "Oct", uv: 90 },
    { name: "Nov", uv: 80 },
    { name: "Dec", uv: 110 },
  ];

  const dataYearly = [
    { name: "2019", uv: 500 },
    { name: "2020", uv: 700 },
    { name: "2021", uv: 650 },
    { name: "2022", uv: 800 },
    { name: "2023", uv: 750 },
  ];

  const handleChange = (value) => {
    setTimeRange(value);
  };

  let chartData;

  switch (timeRange) {
    case "Weekly":
      chartData = dataWeekly;
      break;
    case "Monthly":
      chartData = dataMonthly;
      break;
    case "Yearly":
      chartData = dataYearly;
      break;
    default:
      chartData = dataWeekly;
  }

  return (
    <div className="subscription-chart">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Subscription</h2>
        <Select defaultValue="Weekly" style={{ width: 120 }} onChange={handleChange}>
          <Select.Option value="Weekly">Weekly</Select.Option>
          <Select.Option value="Monthly">Monthly</Select.Option>
          <Select.Option value="Yearly">Yearly</Select.Option>
        </Select>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="uv" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SubscriptionChart;
