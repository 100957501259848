import React from 'react';
import footerlogo from '../Assets/Images/footer-logo.png'

  const Footer = () => {
    return (
      <footer className="dark:bg-white footer-bg">
        <div className="mx-auto w-full max-w-screen-xl">
          <div className="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-5">
            {/* Column 1 */}
            <div>
              <a href='/' className="mb-6 text-md underline font-semibold text-white uppercase dark:text-white">
                <img src={footerlogo} className='footer-image' alt=''/>
              </a>
              <ul className="text-white dark:text-gray-400 font-medium">
                <li className="mb-4 mt-4">
                  <p className="">The Second Judge Website a company/Individual will post reviews of its employees.</p>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">Download our app</a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">Brand Center</a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">Blog</a>
                </li>
              </ul>
            </div>
  
            {/* Column 2 */}
            <div>
              <h2 className="mb-6 text-md font-semibold text-white uppercase dark:text-white">LEARN</h2>
              <ul className="text-white dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">Toxin Trends</a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">Poison List</a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">Owners Guide</a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">Veterinarians</a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">Emergency</a>
                </li>
              </ul>
            </div>
  
            {/* Column 3 */}
            <div>
              <h2 className="mb-6 text-md font-semibold text-white uppercase dark:text-white">ABOUT</h2>
              <ul className="text-white dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">News & Blog</a>
                </li>
                <li className="mb-4">
                  <a href="/AboutUs" className="hover:text-xl">About Us</a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">Contact Us</a>
                </li>
                <li className="mb-4">
                  <a href="/Privacypolicy" className="hover:text-xl">Privacy Policy</a>
                </li>
                <li className="mb-4">
                  <a href="/TermsCondition" className="hover:text-xl">Terms & Condition</a>
                </li>
              </ul>
            </div>
  
            {/* Column 4 */}
            <div>
              <h2 className="mb-6 text-md font-semibold text-white uppercase dark:text-white">HELP</h2>
              <ul className="text-white dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">ASSISTANCE:</a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">1 ( 989 ) 765-7654</a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">Assistance:</a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">info@secondjudge.com</a>
                </li>
              </ul>
            </div>
  
            {/* New Column */}
            <div>
              <h2 className="mb-6 text-md font-semibold text-white uppercase dark:text-white">STAY CONNECTED</h2>
              <ul className="text-white dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">Get the latest</a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:text-xl">Link 2</a>
                </li>
                {/* Add more links as needed */}
              </ul>
            </div>
          </div>
  
          {/* Second part of the footer */}
          <div className="px-4 py-6 footer-bg dark:bg-gray-700 md:flex md:items-center md:justify-between">
          </div>
        </div>
   
      </footer>
    );
  };
  
export default Footer;
  
