import React from 'react'
import Navbar from '../../Widgets/navbar'
const termcondition = () => {
  const containerStyle = {
    maxWidth: '1100px',
    margin: '0 auto',
    padding: '20px',
    borderRadius: "12px",
  };

  const headingStyle = {
    color: '#333',
  };

  const sectionStyle = {
    marginBottom: '30px',
    border: '1px solid #ddd',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
  };

  const paragraphStyle = {
    lineHeight: '1.6',
  };
  return (
    <>
    <Navbar/>
      <>
      <div style={containerStyle}>
      <h1 style={headingStyle} className='mb-4 text-3xl font-bold'>User Terms and Conditions</h1>
      <section style={sectionStyle}>
        <h2></h2>
        <p style={paragraphStyle}>These User Terms and Conditions ("Terms") govern your use of SecondJudge.com 
        located at SecondJudge.com (the "Website"), including any content, features, products, and services 
        offered by SecondJudge.com (collectively, the "Services"). By accessing or using the Website, you agree 
        to be bound by these Terms. If you do not agree with these Terms, please refrain from using the Website.</p>
      </section>

      <section style={sectionStyle}>
      <h2 className='font-bold mb-2'>1. Acceptance of Terms</h2>
      {/* <h3 className='font-bold mb-2'>1.1 Personal Information:</h3> */}
        <p style={paragraphStyle}>You must read, understand, and agree to these Terms before using the Website.
         By accessing or using the Website, you affirm that you are of legal age and have the legal capacity to
          enter into this agreement or have obtained permission from a legal guardian to do so. If you are accessing
        or using the Website on behalf of an organization, you warrant that you have authority to bind the organization to these Terms.
        </p>
      </section>

      <section style={sectionStyle}>
      <h2 className='font-bold mb-2'>2. User Conduct
    </h2>
      {/* <h3 className='font-bold mb-2'>2.1 Personal Information:</h3> */}
        <p style={paragraphStyle}>You agree to use the Services solely for lawful purposes and in compliance with 
        all applicable laws and regulations. You will refrain from engaging in any conduct that could harm or 
        interfere with the functionality or security of the Website, or that could reasonably be considered objectionable, 
        offensive, or abusive to other users or to SecondJudge.com. You must not misrepresent yourself or impersonate any person or entity.
        </p>
      </section>

      <section style={sectionStyle}>
      <h2 className='font-bold mb-2'>3. Online Reviews and Content</h2>
        <p style={paragraphStyle} className='mb-2'>a. Posting Reviews: By submitting content, including but not limited to reviews, 
        ratings, comments, or any other form of user-generated content ("Content"), you grant SecondJudge.com a non-exclusive,
         worldwide, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish,
          translate, distribute, display, and perform such Content in connection with the Services. You represent and warrant that
           you own or have sufficient rights to grant these permissions.
        </p>
        <p className='mb-2'>b. Responsibility for Content: You are solely responsible for the accuracy, legality, and originality of the Content 
          you post through our Services. SecondJudge.com does not endorse, assume responsibility for, or guarantee the accuracy, 
          integrity, or quality of any Content. You understand that any reliance on Content posted by other users is at your own risk.
        </p>
        <p className='mb-2'>c. Prohibited Content: You agree not to post Content that is defamatory, abusive, harassing, threatening, 
          obscene, sexually explicit, fraudulent, deceptive, infringing, or violates any applicable laws or regulations.
           SecondJudge.com reserves the right to remove or block any Content that violates these provisions or is otherwise 
           objectionable, in its sole discretion.
        </p>
      </section>
      <section style={sectionStyle}>
      <h2 className='font-bold'>4. Cancelations & Refunds</h2>
      <h3 className='font-bold mb-2'>1. Refunds:</h3>
        <p style={paragraphStyle} className='font-medium'>1.1 Paid Services: </p>
        <p style={paragraphStyle} className='mb-2'>SecondJudge.com offers paid services using which you can check online reviews. Once you have made a payment, refunds are available under the following circumstances:</p>
        <p style={paragraphStyle} className='mb-2'>a: If your view review request has not been processed or started, you may request a refund and we will issue a full refund within 7 business days.</p>
        <p style={paragraphStyle} className='mb-2'>b: If there is an error on our part which prevents us from processing or completing your view review request, you may request a refund and we will issue a full refund within 7 business days.</p>
        <p style={paragraphStyle} className='mb-2'>c: If you request a refund after your view review request has been processed or started, we may evaluate the refund request on a case-by-case basis. Please contact our customer support team at admin@secondjudge.com   to discuss your refund request.</p>
        <p style={paragraphStyle} className='mb-3 font-medium'>1.2 Free Services: </p>
        <p style={paragraphStyle}>If you have used our free services and are dissatisfied with the experience, we encourage you to contact our customer support team to address your concerns. However, as our free services do not involve any monetary transactions, refunds are not applicable.</p>
        <h3 className='font-bold mb-2'>2. Cancellations:</h3>
        <p style={paragraphStyle} className='mb-3 font-medium'>2.1 Paid Services: </p>
        <p style={paragraphStyle}>If you wish to cancel your view review request before it has been processed or started, please contact our customer support team at admin@secondjudge.com  . We will evaluate your request and if cancellation is possible, we will issue a full refund within 7 business days.</p>
        <p style={paragraphStyle} className='font-medium'>2.2 Free Services: </p>
        <p style={paragraphStyle} className='mb-2'>Cancellations are not applicable for our free services, as they do not involve any binding commitments or monetary transactions.</p>
        <h3 className='font-bold mb-2'>3. Contact and Support:</h3>
        <p>If you have any questions, concerns, or requests regarding our Refund and Cancellation Policy, please contact our customer support team at admin@secondjudge.com  . We are here to assist you and address any issues you may have.</p>
        <p style={paragraphStyle} className='mb-2'>We reserve the right to update or modify this Refund and Cancellation Policy at any time. The updated policy will be posted on our website with a revised effective date. We encourage you to review this policy periodically to stay informed about our refund and cancellation practices.</p>
      </section>
      <section style={sectionStyle}>
      <h2 className='font-bold'>5. Intellectual Property Rights</h2>
        <p style={paragraphStyle} className='mb-2'>a. Ownership: All intellectual property rights in the Website, including but not limited to copyrights, trademarks, and logos, are owned by SecondJudge.com or its licensors. You acknowledge and agree that you have no right, title, or interest in or to any such intellectual property, except as expressly granted under these Terms.
        </p>
        <p style={paragraphStyle} className=''>b. Use of Content: You may access and use the Content provided through the Services solely for personal, non-commercial purposes. You agree that you will not remove, alter, or obscure any copyright, trademark, or proprietary notices displayed on the Website.
        </p>
      </section>
      <section style={sectionStyle}>
      <h2 className='font-bold'>6. Privacy and Data Protection</h2>
        <p style={paragraphStyle}>Your use of the Website is subject to our Privacy Policy, which explains how we collect, store, use, and disclose your personal information. You affirm that you have read and understood the Privacy Policy before using the Website.
        </p>
      </section>
      <section style={sectionStyle}>
      <h2 className='font-bold'>7. Termination</h2>
        <p style={paragraphStyle}>SecondJudge.com reserves the right to suspend or terminate your access to the Website and Services, in whole or in part, at any time and for any reason, without prior notice or liability. These Terms will continue to apply to all past use of the Services by you, even if your access is terminated.
        </p>
      </section>
      <section style={sectionStyle}>
      <h2 className='font-bold'>8. Disclaimer of Warranties and Limitation of Liability</h2>
        <p style={paragraphStyle} className='mb-2'>The Website and Services are provided "as is," without any warranties or representations, express or implied. SecondJudge.com expressly disclaims all warranties, including but not limited to merchantability, fitness for a particular purpose, and non-infringement. You use the Website and Services at your own risk.
        </p>
        <p className=''>In no event shall SecondJudge.com or its directors, officers, employees, or affiliates be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with your use of the Website or Services. You agree to indemnify and hold SecondJudge.com harmless from any claims, losses, liabilities, damages, costs, and expenses, including attorney fees, arising out of or related to your use of the Website or violation of these Terms.</p>
      </section>
      <section style={sectionStyle}>
      <h2 className='font-bold'>9. Modifications</h2>
        <p style={paragraphStyle}>SecondJudge.com reserves the right to modify or update these Terms at any time without prior notice. Your continued use of the Website after modifications constitutes your acceptance of the revised Terms.
        </p>
      </section>
      <section style={sectionStyle}>
      <h2 className='font-bold'>10. Governing Law and Jurisdiction</h2>
        <p style={paragraphStyle}>These Terms shall be governed by and construed in accordance with the laws of Hyderabad, Telangana. Any disputes arising out of or in connection with these Terms shall be submitted to the exclusive jurisdiction of Medchal-Malkajgiri courts.
        </p>
        <p style={paragraphStyle}>If any provision of these Terms is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary, and the remaining provisions shall remain in full force and effect.
        </p>
      </section>

    </div>
      </>
    </>
    
  )
}

export default termcondition