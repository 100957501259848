import React from 'react';
import RightNavbar from '../Widgets/RightNavbar'; 

const AddUser = () => {
  const handleCustomSubmit = (event) => {
    // Your custom submit logic here
  };

  const handleCustomClose = () => {
    // Your custom close logic here
  };

  return (
    <div className="App">
      {/* Call the AddUser component with the required props */}
      <RightNavbar
        buttonText="Hiii" // Customize the button text
        formFields={[
          { type: "text", placeholder: "Name" },
          { type: "text", placeholder: "Phone" },
          { type: "email", placeholder: "Email" },
          // Add more form fields as needed
        ]}
        onSubmit={handleCustomSubmit}
        onClose={handleCustomClose}
      />
    </div>
  );
};

export default AddUser;
