import React, { useState } from "react";
import NavbarDashboard from "../../../Widgets/SuperAdminDashboardNavbar";
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthHeader from "../../../Services/authHeader";
import { useNavigate } from "react-router-dom"; 
import SideNav from "../../../Widgets/SideNav";
import Sider from "antd/es/layout/Sider";
import { Button, Col, Layout, Row } from "antd";
import SuperAdminNav from "../../../Widgets/SuperAdminNavbar";
import ExEmployees from "../reportedreview.js/exemployee";
import NonJoiners from "../reportedreview.js/nonjoiners";
import { Content } from "antd/es/layout/layout";

const ReportReview = () => {
  const navigate = useNavigate();
  const [showbutton, setShowButton] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpen2, setIsOpen2] = React.useState(false);
  const [tabType, setTabType] = useState("ExEmployee");
  const [formType, setFormType] = useState(null);

  const addUser = Yup.object().shape({
    email: Yup.string().required("Please enter your email"),
    first_name: Yup.string().required("Enter your first name"),
    phone: Yup.string().required("Please enter your mobile number"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      phone: "",
    },
    validationSchema: addUser,
    onSubmit: (values) => {
      addReviewUser(values);
      navigate("/data");
    },
  });

  async function addReviewUser(values) {
    try {
      await AuthHeader.UserAdd(values.email, values.first_name, values.phone);
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleClose = () => {
    setIsOpen(false);
    document.getElementById("dark-mode-on").classList.remove("dark-mode-on");
  };

  const handleClose2 = () => {
    setIsOpen2(false);
    document.getElementById("dark-mode-on").classList.remove("dark-mode-on");
  };

  const handleCloseButton = () => {
    setShowButton(true);
  };

 

  const handleResetButton = (value) => {
    if (value === true) {
      setTabType("ExEmployee");
      setFormType(null);
    }
  };

  const closeForm = () => {
    setFormType(null);
    setTabType("ExEmployee");
  };

  // Determine the reference doctype based on the tabType
  const referenceDoctype =
    tabType === "ExEmployee" ? "Ex Employee Reviews" : "Non Joiners Reviews";

  document.body.classList.add("companyreportsave-page");
  document.body.classList.remove(
    "dashboard-page",
    "profile-page",
    "billing-page",
    "search-page",
    "users-page",
    "reports-page",
    "review-page",
    "approve-page",
    "deletion-page",
    "issue-page"
  );

  return (
    <>
      <Layout className="h-screen">
        <div id="dark-mode-on"></div>
        <Sider width={256} className="site-layout-background h-screen">
          <SideNav />
        </Sider>
        <Layout className="panel-right">
          <SuperAdminNav />
          <Content>
            <div className="site-layout-background">
              <Row
                justify="space-between"
                align="middle"
                className="tabs-addReviewBtn"
              >
                <Col className="toggle-btns">
                  <Button
                    type="primary"
                    className={
                      tabType === "ExEmployee" ? "dark-color" : "light-color"
                    }
                    onClick={() => {
                      setTabType("ExEmployee");
                    }}
                  >
                    Ex-Employee
                  </Button>
                  <Button
                    type="primary"
                    className={
                      tabType === "NonJoiner" ? "dark-color" : "light-color"
                    }
                    onClick={() => {
                      setTabType("NonJoiner");
                    }}
                  >
                    Non-Joiners
                  </Button>
                </Col>
                <Col>
                  {/* <Button type="default" onClick={editableForm}>
                    Add Review
                  </Button> */}
                </Col>
              </Row>
              {/* Conditional rendering based on formType */}
              {formType === null && (tabType === "ExEmployee" ? (
                <ExEmployees referenceDoctype={referenceDoctype} />
              ) : (
                <NonJoiners referenceDoctype={referenceDoctype} />
              ))}
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default ReportReview;
