import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginRight from "./LoginRight";
import LoginLeft from "./LoginLeft";
import { Login, getCurrentUser } from "../../Services/authHeader";

// Function to clear specific cookies
const clearAllCookies = () => {
  const cookiesToClear = [
    "full_name",
    "sid",
    "system_user",
    "user_id",
    "user_image"
  ];

  cookiesToClear.forEach((cookieName) => {
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=localhost`;
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=localhost;Secure;SameSite=Lax`;
  });
};

const LoginComponent = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    // Check if the reload has already occurred
    if (location.pathname.includes("/login")) {
      const hasReloaded = sessionStorage.getItem("hasReloaded");

      if (!hasReloaded) {
        clearAllCookies();
        sessionStorage.setItem("hasReloaded", "true"); // Mark reload as done
        window.location.reload(); // Trigger a page refresh after clearing cookies
      }
    }

    const auth = getCurrentUser();
    if (auth) {
      if (auth.response_code === 1) {
        navigate("/superAdmin/Dashboard");
      } else {
        navigate("/HomeDashboard");
      }
    }
  }, [location.pathname, navigate]);

  const loginValidation = Yup.object().shape({
    identifier: Yup.string()
      .required("Please enter your email or phone number")
      .test(
        "identifier",
        "Please enter a valid email or phone number",
        (value) => {
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const phonePattern = /^[+]?[0-9]{10,15}$/;

          if (value === "Administrator") {
            return true;
          }

          return emailPattern.test(value) || phonePattern.test(value);
        }
      ),
    pwd: Yup.string().required("Please enter your password"),
  });

  const formik = useFormik({
    initialValues: {
      identifier: "",
      pwd: "",
    },
    validationSchema: loginValidation,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await Login(values.identifier, values.pwd);
        if (response.message.success_key === 1) {
          toast.success("Login successful!");
          setShowSuccessMessage(true);
          setTimeout(() => {
            if (response.message.response_code === 1) {
              navigate("/superAdmin/Dashboard");
            } else {
              navigate("/HomeDashboard");
            }
          }, 2000);
        } else {
          toast.error("Login failed. Please check your credentials.");
        }
      } catch (error) {
        console.error("Login error:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <section className="flex m-auto container-fluid w-full h-screen login-section">
      <LoginLeft>
        <h1>Log In</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="space-y-4 md:space-y-6"
          action="#"
        >
          <div>
            <TextField
              label="Email or Phone"
              variant="outlined"
              type="text"
              name="identifier"
              id="identifier"
              value={formik.values.identifier}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full mt-2"
              required
              error={
                formik.touched.identifier && Boolean(formik.errors.identifier)
              }
              helperText={formik.touched.identifier && formik.errors.identifier}
            />
          </div>
          <div>
            <TextField
              label="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              name="pwd"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="password"
              value={formik.values.pwd}
              className="w-full mt-2"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="text-gray-500"
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <AiFillEye /> : <AiOutlineEyeInvisible />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={formik.touched.pwd && Boolean(formik.errors.pwd)}
              helperText={formik.touched.pwd && formik.errors.pwd}
            />
          </div>
          <div className="forgot-pwd">
            <Link to="/ForgetPassword">Forgot Password?</Link>
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 text-white auth-btn"
          >
            <span>Log In</span>
            {loading && (
              <span className="ml-2 spinner-border spinner-border-sm">
                .....
              </span>
            )}
          </button>
          <p className="flex justify-center text-center auth-bottom-text">
            Don’t have an account?
            <Link to="/SignUp" className="signup-link">
              &nbsp;Sign Up
            </Link>
          </p>
        </form>
      </LoginLeft>

      <LoginRight />
      <ToastContainer />
    </section>
  );
};

export default LoginComponent;
