import React, { useState } from "react";
import Logo from '../../Assets/Images/Logo.png'
import { useNavigate } from 'react-router-dom'
import Login1 from '../../Assets/Images/Login.jpg'
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import {AiFillEye,AiOutlineEyeInvisible} from 'react-icons/ai'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import AuthHeader from '../../Services/authHeader'

const NewPassword = () => {
    const navigate=useNavigate()
      const validateNewPassword=Yup.object().shape({
        new_password:Yup.string().required('Please enter new password'),
        confirm_password: Yup.string().when("password", (new_password ,field) => {
          if (new_password) {
            return field.required("Please enter your confirm password").oneOf([Yup.ref("new_password")], "The passwords do not match");
          }
        }),
      })
      const [showPassword, setShowPassword] = useState(false);
      const handleShowPassword = () => {
        setShowPassword(true);
      };
      var handleMouseDownPassword = function (event) {
        event.preventDefault();
      };
      const formik=useFormik({
        initialValues:{
          new_password:'',
          confirm_password:''
        },
        validationSchema:validateNewPassword,
        onSubmit:((values)=>{
           createPassword(values)

        })
      })
      async function createPassword(values){
        try{
         await AuthHeader.getNewPassword(values.new_password,values.confirm_password)
         navigate('/Login')
        }catch(error){
          console.log('error',error)
        }
      }
  return (
    <>
  

                  <section class="gradient-form h-full bg-neutral-200 dark:bg-neutral-700">
      
      {/* <div class="container h-full p-10">
        <div class="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 dark:text-neutral-200"> */}
          <div class="w-100%">
            <div class="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
              <div class="g-0 lg:flex lg:flex-wrap">
                {/* <!-- Left column container--> */}
                <div class="px-4 md:px-0 form-side">
                <div className="bg-white  px-4 lg:px-6 py-12 ">
                        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                        <a href="/" class="flex items-center"> <img
                          src={Logo}
                          className="mr-3 h-6 sm:h-9"
                          alt="Flowbite Logo"
                        />
                        </a>
                        </div>
                      </div>
                    <div class="md:mx-6 md:p-12 padding-top-1">
                      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight pb-6 tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                          Create New Password?
                        </h1>
                        <form
                          onSubmit={formik.handleSubmit}
                          className="space-y-4 md:space-y-6"
                          action="#"
                        >
                          <div>
                            <label
                              for="email"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                            >
                              {/* New Password */}
                            </label>
                            <TextField
                            label="Password" 
                            variant="outlined"
                              type={"password"}
                              name="new_password"
                              value={formik.values.new_password}
                              onChange={formik.handleChange}
                              id="email"
                              className="bg- border border-gray-400 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-700 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Password"
                              required=""
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                    background-color="dark-black"
                                    class="password-eye"
                                      aria-label="toggle password visibility"
                                      onClick={handleShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <AiFillEye />
                                      ) : (
                                        <AiOutlineEyeInvisible />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                               <p style={{ color: "red" }}>
                              {formik.errors.new_password ? formik.errors.new_password: ""}
                            </p>
                          </div>
                          <div>
                            <TextField
                            label="Confirm Password" 
                            variant="outlined"
                              type="password"
                              name="confirm_password"
                              value={formik.values.confirm_password}
                              onChange={formik.handleChange}
                              id="email"
                              className="bg- border border-gray-400 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-700 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Confirm Password"
                              required=""
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                    background-color="dark-black"
                                    class="password-eye"
                                      aria-label="toggle password visibility"
                                      onClick={handleShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <AiFillEye />
                                      ) : (
                                        <AiOutlineEyeInvisible />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                             <p style={{ color: "red" }}>
                              {formik.errors.confirmPassword ? formik.errors.confirmPassword : ""}
                            </p>
                          </div>

                          <button
                            type="submit"
                            className="w-full gradient-btn text-white bg-gray-800 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-800 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                          >
                            Update Password
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>

                {/* <!-- Right column container with background and description--> */}
                <div class="flex items-center rounded-b-lg image-side lg:rounded-r-lg lg:rounded-bl-none justify-end">
                  <img src={Login1} className="second-judge-banner" alt="Flowbite Logo" />
                </div>
              </div>
            </div>
          </div>
        {/* </div>
      </div> */}
    </section>
    </>
    
  )
}

export default NewPassword