import APIs from "../api";
import Axios from "./commonHttp";

export const Login = async (identifier, password) => {
  try {
    const response = await Axios.get(APIs.login, {
      params: {
        usr: identifier,
        pwd: password,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.data.message.success_key === 1) {
      sessionStorage.removeItem("usr");
      sessionStorage.setItem("usr", JSON.stringify(response.data.message));
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCurrentUser = () => {
  const user = sessionStorage.getItem("usr");
  try {
    return user ? JSON.parse(user) : null;
  } catch (error) {
    return user;
  }
};

export const isAdmin = (identifier) => {
  return (
    identifier &&
    (identifier.toLowerCase() === "administrator" ||
      identifier.toLowerCase() === "administrator")
  );
};

export const signUp = async (userData) => {
  try {
    const response = await Axios.post(
      APIs.signUp,
      { data: userData },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.success === 1) {
      console.log(response.data.message);
      sessionStorage.setItem("usr", userData.email);
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyOTP = async (email, otp) => {
  try {
    const response = await Axios.post(
      APIs.verifyOTP,
      {
        email: email,
        otp: otp,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response.data, "success message from server");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRegions = async () => {
  try {
    const response = await Axios.get(APIs.getRegions, {
      params: {
        fields: '["*"]',
      },
    });
    return response.data.result;
  } catch (error) {
    console.error("Error fetching regions:", error);
    throw error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await Axios.post(
      APIs.forgotPassword,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.message === "OTP has been sent to your email account.") {
      console.log("Success message from server: ", response.data.message);
      return response.data;
    } else {
      console.error("Unexpected response from server: ", response.data);
      throw new Error("Failed to send OTP");
    }
  } catch (error) {
    console.error("Error in forgotPassword API:", error);
    throw error;
  }
};


export const getExEmployee = async () => {
  try {
   
    const response = await Axios.get(APIs.exEmployeeReviews, {
      params: {
        fields: '["*"]',
        // limit_start: 0,
        // limit_page_length: 1,
        order_by: "creation desc",
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log(response.data.result, "Success message Ex Employee List");
    return response.data.result;
    // return response.data;
  } catch (error) {
    console.error("Error in getExEmployee API:", error);
    throw error;
  }
};

export const getNonJoinerEmployee = async () => {
  try {
    const response = await Axios.get(APIs.nonJoinersReviews, {
      params: {
        fields: '["*"]',
        // limit_start: 0,
        // limit_page_length: 10,
        order_by: "creation desc",
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(response.data, "Success message Ex Employee List");
    return response.data.result;
  } catch (error) {
    console.error("Error in getExEmployee API:", error);
    throw error;
  }
};

export const getTypeOfReview = async () => {
  const payload = {
    doctype: "Type Of Review",
    fields: [
      "`tabType Of Review`.`name`",
      "`tabType Of Review`.`owner`",
      "`tabType Of Review`.`creation`",
      "`tabType Of Review`.`modified`",
      "`tabType Of Review`.`modified_by`",
      "`tabType Of Review`.`_user_tags`",
      "`tabType Of Review`.`_comments`",
      "`tabType Of Review`.`_assign`",
      "`tabType Of Review`.`_liked_by`",
      "`tabType Of Review`.`docstatus`",
      "`tabType Of Review`.`idx`",
    ],
    filters: [],
    order_by: "`tabType Of Review`.`modified` desc",
    start: 0,
    page_length: 20,
    view: "List",
    group_by: "",
    with_comment_count: true,
  };

  try {
    const response = await Axios.post(APIs.getTypeOfReview, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(
      response.data,
      "Success message from server TYPE OF REVIEWS______________"
    );
    return response.data;
  } catch (error) {
    console.error("Error in getTypeOfReview API:", error);
    throw error;
  }
};

export const submitExEmployeeReview = async (employeeData) => {
  try {
    const response = await fetch(APIs.exEmployeeReviews, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: employeeData }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok" + response.statusText);
    }

    const responseData = await response.json();
    console.log("Response:", responseData);

    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const submitNonJoinersReview = async (employeeData) => {
  try {
    const response = await fetch(APIs.nonJoinersReviews, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: employeeData }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok" + response.statusText);
    }

    const responseData = await response.json();
    console.log("Response:", responseData);

    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const addUser = async (userData) => {
  try {
    const response = await Axios.post(
      APIs.addUser,
      {
        email: userData.email,
        first_name: userData.first_name,
        phone: userData.phone,
        company: userData.company,
        // new_password: userData.new_password,
        call_from: "User",
        admin: 0,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // console.log(response.data, "User added successfully");

    if (response.data.data.first_name) {
      sessionStorage.setItem("name", response.data.data.first_name);
    }

    return response.data;
  } catch (error) {
    console.error("Error in createUser API:", error);
    if (error.response) {
      console.error("Server Response Data:", error.response.data);
    }
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const response = await Axios.get(APIs.getUsers, {
      params: {
        doctype: "User",
        fields: JSON.stringify(["*"]),
        order_by: "creation desc",
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    const { keys, values } = response.data.message;

    const users = values.map((userArray) => {
      let userObject = {};
      keys.forEach((key, index) => {
        userObject[key] = userArray[index];
      });
      return userObject;
    });

    // console.log(users, "Success message Users List");
    return users;
  } catch (error) {
    console.error("Error in getting Users List:", error);
    throw error;
  }
};

export const getSelectedUser = async (doctype, name) => {
  try {
    const response = await Axios.get(APIs.getUsers, {
      params: {
        doctype,
        filters: JSON.stringify([[doctype, "name", "=", name]]),
        fields: JSON.stringify(["*"]),
        order_by: "creation desc",
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
 
    const { keys, values } = response.data.message;

    const users = values.map((userArray) => {
      let userObject = {};
      keys.forEach((key, index) => {
        userObject[key] = userArray[index];
      });
      return userObject;
    });

    const user = users.length > 0 ? users[0] : null;

    console.log(user, "Success message User Details");
    return user;
  } catch (error) {
    console.error("Error in getting User Details:", error);
    throw error;
  }
};

// export const searchUsers = async (filters) => {
//   try {
//     const response = await Axios.get(APIs.searchEmployees, {
//       params: {
//         limit_start: 0,
//         ...filters,
//         fields: JSON.stringify([
//           "doctype",
//           "creation",
//           "company",
//           "owner",
//           "email",
//           "username",
//           "phone",
//           "mobile_no",
//           "designation",
//         ]),
//       },
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     console.log("API response count:", response.data.length);
//     console.log("API response:", response.data);
//     const result = response.data.message;
//     const users = result.map((user) => ({
//       doctype: user.doctype,
//       name: user.name,
//       creation: user.creation,
//       company: user.company,
//       owner: user.owner,
//       email: user.email,
//       username: user.username,
//       phone: user.phone,
//       mobile_no: user.mobile_no,
//       designation: user.designation,
//     }));
//     console.log(users, "Success message Users List");
//     return users;
//   } catch (error) {
//     console.error("Error in searching Users List:", error);
//     throw error;
//   }
// };

export const searchUsers = async (filters) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value)
    );

    const response = await Axios.get(APIs.searchEmployees, {
      params: {
        limit_start: 0,
        ...filteredParams,
        fields: JSON.stringify([
          "doctype",
          "creation",
          "company",
          "owner",
          "email",
          "username",
          "phone",
          "mobile_no",
          "designation",
        ]),
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    // console.log("API response count:", response.data.message.length);
    // console.log("API response:", response.data.message);

    // const users = response.data.message.map((user) => ({
    //   doctype: user.doctype,
    //   name: user.name,
    //   creation: user.creation,
    //   company: user.company,
    //   owner: user.owner,
    //   email: user.email,
    //   username: user.username,
    //   phone: user.phone,
    //   mobile_no: user.mobile_no,
    //   designation: user.designation,
    // }));

    const OnlyReview = response.data.message.filter((user) => {
      if (user.doctype === "Ex Employee Reviews" || user.doctype === "Non Joiners Reviews") {
        return ({
          doctype: user.doctype,
          name: user.name,
          creation: user.creation,
          company: user.company,
          owner: user.owner,
          email: user.email,
          username: user.username,
          phone: user.phone,
          mobile_no: user.mobile_no,
          designation: user.designation,
        })
      }
    }
    );
    return OnlyReview;
  } catch (error) {
    console.error("Error in searching Users List:", error);
    throw error;
  }
};

export const getCount = async (doctype) => {
  try {
    const response = await Axios.post(
      APIs.getCount,
      {
        doctype,
        filters: [],
        fields: [],
        distinct: false,
        limit: 1001,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.message;
  } catch (error) {
    console.error("Error in getting count:", error);
    throw error;
  }
};

export const deleteUser = async (email) => {
  try {
    const response = await Axios.delete(`${APIs.deleteUser}/${email}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(response, "Success message Delete User");
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

export const contactUs = async (
  fullName,
  email,
  phone,
  region,
  details,
  workflow_state // Pass the workflow state as an ID
) => {
  try {
    const response = await Axios.post(
      APIs.contactUs,
      {
        data: {
          full_name: fullName,
          email: email,
          phone: phone,
          region: region,
          details: details,
          workflow_state: workflow_state, // Send the workflow state as an ID
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const contactUsStatus = async (payload) => {
  return await fetch(APIs.updateIssueStatus, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((response) => response.json());
};

export const contactList = async () => {
  try {
    const response = await Axios.get(APIs.contactUs, {
      params: {
        limit_start: 0,
        limit_page_length: 1000,
        order_by: "creation desc",
        fields: JSON.stringify([
          "name",
          "creation",
          "modified_by",
          "phone",
          "region",
          "email",
          "workflow_state",
          "details",
          "full_name",
        ]),
      },
    });

    console.log(response.data.result, "contact list json");
    return response.data.result;
  } catch (error) {
    console.error("Error fetching contact list:", error);
    throw error;
  }
};

const addExEmployee = async (
  aadhar_card,
  boarduniversity,
  company_name,
  date_of_birth,
  designation,
  email,
  first_name,
  highest_education,
  joined_on,
  last_name,
  location,
  other_id_proof,
  other_id_proof_number,
  phone,
  released_on,
  review_details,
  schoolcollegeuniversity
) => {
  // console.log(data);
  return Axios({
    method: "Post",
    url: APIs.createExEmployeeReview,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      aadhar_card,
      boarduniversity,
      company_name,
      date_of_birth,
      designation,
      email,
      first_name,
      highest_education,
      joined_on,
      last_name,
      location,
      other_id_proof,
      other_id_proof_number,
      phone,
      released_on,
      review_details,
      schoolcollegeuniversity,
    },
  }).then((response) => {
    if (response.data.data.name) {
      sessionStorage.setItem("reviewID", response.data.data.name);
    }
    return response.data;
  });
};

const addNonJoiner = async (
  aadhar_card,
  boarduniversity,
  company_name,
  date_of_birth,
  designation,
  email,
  first_name,
  highest_education,
  joined_on,
  last_name,
  location,
  other_id_proof,
  other_id_proof_number,
  phone,
  released_on,
  review_details,
  schoolcollegeuniversity
) => {
  return Axios({
    method: "Post",
    url: "/api/resource/Non Joiners Reviews",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      aadhar_card,
      boarduniversity,
      company_name,
      date_of_birth,
      designation,
      email,
      first_name,
      highest_education,
      joined_on,
      last_name,
      location,
      other_id_proof,
      other_id_proof_number,
      phone,
      released_on,
      review_details,
      schoolcollegeuniversity,
    },
  }).then((response) => {
    if (response.data.data.name) {
      sessionStorage.setItem("reviewID", response.data.data.name);
    }
    return response.data;
  });
};

export const ProfileData = async (getId) => {
  try {
    const response = await Axios.get(`/api/resource/User/${getId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data && response.data.data) {
      // console.log(response.data.data, "User Profile Data");
      return response.data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error in ProfileData API:", error);
    throw error;
  }
};

// const ProfileData = async (getId) => {
//   try {
//     const response = await Axios({
//       method: "GET",
//       url: `/api/resource/User/${getId}`,
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     if (response.data && response.data.data) {
//       return response.data.data;
//     } else {
//       return null;
//     }
//   } catch (error) {
//     console.log("error", error);
//     throw error;
//   }
// };

const fetchTotalEarnedPoints = async () => {
  try {
    const response = await Axios({
      method: "GET",
      url: "/api/resource/Subscription%20Transaction",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.data && response.data.data) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("Error:", error);
  }
};

const getNewPassword = async (new_password, confirm_password) => {
  try {
    await Axios({
      method: "Post",
      url: "/api/method/watcherp.customizations.user.create_new_password",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        new_password,
        confirm_password,
      },
    }).then((response) => {
      return response.data;
    });
  } catch (error) {
    console.log("error", error);
  }
};

const UserAdd = async (data) => {
  console.log(data, "data");
  await Axios({
    method: "Post",
    url: "/api/resource/User",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
};

const UserDetail = async () => {
  await Axios({
    method: "GET",
    url: "/api/resource/User",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const SuperAdminUserList = () => {
  return Axios({
    method: "get",
    url: "/api/resource/User",
    mode: "cors",
    params: {
      fields: '["*"]',
      limit_start: 0,
      limit_page_length: 10,
    },
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response.data;
  });
};

export const getUserDetails = async (id) => {
  try {
    const response = await Axios.get(`${APIs.exEmployeeReviews}/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(response.data.result, "Ex Employee Details");
    return response.data.result;
  } catch (error) {
    console.error("Error in getExEmployee API:", error);
    throw error;
  }
};
export const getExEmployeeDetails = async (id) => {
  try {
    const response = await Axios.get(`${APIs.exEmployeeReviews}/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Error in getExEmployee API:", error);
    throw error;
  }
};

export const updateExEmployee = async (payload, id) => {
  try {
    const APIEndPoint = `${APIs.exEmployeeReviews}/${id}`;
    const response = await Axios.put(APIEndPoint, payload);
    return response;
  } catch (error) {
    console.error("Error in ExEmployee Update API:", error);
    throw error;
  }
};

export const updateNonJoiners = async (payload, id) => {
  try {
    const APIEndPoint = `${APIs.nonJoinersReviews}/${id}`;
    // console.log(APIEndPoint, "Check");
    const response = await Axios.put(APIEndPoint, payload);
    return response;
  } catch (error) {
    console.error("Error in NonJoiners Update API:", error);
    throw error;
  }
};

export const activateDeactivateUser = async (email, payload) => {
  try {
    const APIEndPoint = `${APIs.activateDeactivateUser}/${email}`;
    // console.log(APIEndPoint, "Check");
    const response = await Axios.put(APIEndPoint, payload);
    return response;
  } catch (error) {
    console.error("Error in activateDeactivateUser Update API:", error);
    throw error;
  }
};
export const getUserCompany = async (email) => {
  try {
    const APIEndPoint = `${APIs.activateDeactivateUser}/${email}`;
    // console.log(APIEndPoint, "Check");
    const response = await Axios.get(APIEndPoint);
    return response;
  } catch (error) {
    console.error("Error in getUserComapany API:", error);
    throw error;
  }
};

export const getAllUserCompany = async () => {
  try {
    const APIEndPoint = `${APIs.getUserComapany}`;
    // console.log(APIEndPoint, "Check");
    const response = await Axios.get(APIEndPoint, {
      params: {
        fields: JSON.stringify(["name", "creation", "company"]),
        order_by: "creation desc",
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Error in getUserComapany API:", error);
    throw error;
  }
};

export const addSubscriptionPlan = async (subscriptionData) => {
  try {
    const response = await Axios.post(
      APIs.addSubscriptionPlan,
      { data: subscriptionData },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response.data, "Success message from server______________");
    return response.data;
  } catch (error) {
    console.error("Error in addSubscriptionPlan API:", error);
    throw error;
  }
};
export const getSubscriptionList = async (userID) => {
  try {
    const response = await Axios.get(APIs.getSubscriptionPlanList, {
      params: {
        filters: JSON.stringify([
          ["Subscription Transaction", "user", "=", `${userID}`],
          ["payment_status", "=", "Success"],
        ]),
        fields: ["*"],
      },
    });
    // console.log(response.data, "Success message for List+++++++");
    return response.data;
  } catch (error) {
    console.error("Error fetching subscription transactions:", error);
    throw error;
  }
};

export const getSubscriptionHistoryByCompany = async (company) => {
  try {
    const response = await Axios.get(
      `${APIs.getsubscriptionhistory}&filters={%22data%22%3A[%22like%22%2C%22%25${company}%25%22]}`
    );
    // console.log(response.data, "Success message for List+++++++");
    return response.data;
  } catch (error) {
    console.error("Error fetching subscription transactions:", error);
    throw error;
  }
};

export const getSubscriptionListWithoutUserFilter = async () => {
  try {
    const response = await Axios.get(APIs.getSubscriptionPlanList, {
      params: {
        filters: JSON.stringify([
          ["Subscription Transaction", "payment_status", "=", "Success"],
        ]),
        fields: ["*"],
      },
    });
    console.log(
      response.data,
      "Success message for List without user filter+++++++"
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching subscription transactions without user filter:",
      error
    );
    throw error;
  }
};

export const userSubscriptionData = async () => {
  try {
    const APIEndPoint = `${APIs.userSubscription}`;
    console.log(APIEndPoint, "Check subscriptionData");
    const response = await Axios.get(APIEndPoint);
    console.log(response, "Check subscriptionData response");
    return response;
  } catch (error) {
    console.error("Error in subscriptionData  API:", error);
    throw error;
  }
};

export const updateUserDetails = async (payload, id) => {
  try {
    const APIEndPoint = `${APIs.addUser}/${id}`;
    console.log(APIEndPoint, "Check");
    const response = await Axios.put(APIEndPoint, payload);
    return response;
  } catch (error) {
    console.error("Error in NonJoiners Update API:", error);
    throw error;
  }
};
export const updateCompanyDetails = async (payload) => {
  try {
    const APIEndPoint = `${APIs.putCompanyDetails}/${payload.name}`;
    const response = await Axios.put(APIEndPoint, payload);
    return response;
  } catch (error) {
    console.error("Error in updateCompanyDetails Update API:", error);
    throw error;
  }
};

export const updateUserPassword = async (payload) => {
  try {
    const APIEndPoint = `${APIs.changePasswrd}`;
    console.log(APIEndPoint, "Check");
    const response = await Axios.put(APIEndPoint, payload);
    return response;
  } catch (error) {
    console.error("Error in NonJoiners Update API:", error);
    throw error;
  }
};

export const getrecentlySearchedReviewsData = async (doctype) => {
  try {
    const response = await Axios.get(APIs.getUsers, {
      params: {
        doctype,
        //filters: JSON.stringify([["User", "name", "=", name]]),
        fields: JSON.stringify(["*"]),
        order_by: "creation desc",
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    const { keys, values } = response.data.message;

    // Map the values to user objects using the keys
    const users = values.map((userArray) => {
      let userObject = {};
      keys.forEach((key, index) => {
        userObject[key] = userArray[index];
      });
      return userObject;
    });

    // Since we are filtering by name, there should be only one user in the array
    // const user = users.length > 0 ? users[0] : null;

    console.log(users, "Success message User Details");
    return users;
  } catch (error) {
    console.error("Error in getting User Details:", error);
    throw error;
  }
};

export const getrecentlyAddedReviewsData = async (doctype) => {
  try {
    const response = await Axios.get(APIs.getUsers, {
      params: {
        doctype,
        //filters: JSON.stringify([["User", "name", "=", name]]),
        fields: JSON.stringify(["*"]),
        order_by: "creation desc",
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    const { keys, values } = response.data.message;

    // Map the values to user objects using the keys
    const users = values.map((userArray) => {
      let userObject = {};
      keys.forEach((key, index) => {
        userObject[key] = userArray[index];
      });
      return userObject;
    });

    // Since we are filtering by name, there should be only one user in the array
    // const user = users.length > 0 ? users[0] : null;

    console.log(users, "Success message User Details");
    return users;
  } catch (error) {
    console.error("Error in getting User Details:", error);
    throw error;
  }
};

export const deleteReviewRequest = async (payload) => {
  try {
    const APIEndPoint = `${APIs.deleteRequest}`;
    const response = await Axios.post(APIEndPoint, payload);
    return response;
  } catch (error) {
    console.error("Error in NonJoiners Update API:", error);
    throw error;
  }
};

export const uploadFile = async ({
  fileName,
  fileData,
  doctype,
  docname,
  fieldName,
}) => {
  console.log(fileName, doctype, docname, "Upload");
  try {
    const res = await Axios.post(APIs.uploadfile, {
      // cmd: "uploadfile",
      doctype: doctype,
      docname: docname,
      isprivate: 0,
      filename: fileName,
      filedata: fileData,
      fieldname: fieldName,
      file: fileData,
      from_form: "1",
    });
    return res;
  } catch (error) {
    console.log("Error uploading file", error);
    return error.response;
  }
};

export const uploadProfile = async ({
  fileName,
  fileData,
  doctype,
  docname,
}) => {
  try {
    const res = await Axios.post(APIs.uploadprofile, {
      cmd: "uploadfile",
      doctype: doctype,
      docname: docname,
      isprivate: 0,
      from_form: 1,
      filename: fileName,
      filedata: fileData,
      fieldname: "user_image",
      file: fileData,
    });
    return res;
  } catch (error) {
    console.log("Error uploading file", error);
    return error.response;
  }
};

export const deleteRequestList = async (payload) => {
  try {
    const APIEndPoint = `${APIs.getDeleteRequest}`;
    const response = await Axios.post(`${APIEndPoint}?doctype=${payload}`);
    return response;
  } catch (error) {
    console.error("Error in getting the list", error);
    throw error;
  }
};

export const getNonJoinersDetails = async (id) => {
  try {
    const response = await Axios.get(`${APIs.nonJoinersReviews}/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Error in getExEmployee API:", error);
    throw error;
  }
};

export const saveReviewSearchLog = async (payload) => {
  try {
    const response = await Axios.post(
      APIs.saveReviewSearchLogsRequest,
      payload
    );

    return response;
  } catch (error) {
    console.error("Error in saving the review search log", error);
    throw error;
  }
};

export const deductPointsOnSearch = async (payload) => {
  try {
    const response = await Axios.post(APIs.deductpointsonsearch, payload);

    return response;
  } catch (error) {
    console.error("Error in saving the review search log", error);
    throw error;
  }
};

export const getRecentlySearchedUser = async (company_name) => {
  try {
    const response = await Axios.get(
      `${APIs.getrecentlysearchedusers}${company_name}`
    );

    return response;
  } catch (error) {
    console.error("Error in saving the review search log", error);
    throw error;
  }
};

export const approveDeleteRequest = async (payload) => {
  try {
    const response = await Axios.put(APIs.deleteRequest, payload)
    return response;
  } catch (error) {
    console.error("Error Approving the Request", error);
    throw error;
  }
}

export const getSubscriptionListForSuperAdmin = async () => {
  try {
    const response = await Axios.get(
      `${APIs.getSubscriptionList}?order_by=creation%20desc&fields=[%22*%22]'`
    );
    return response;
  } catch (error) {
    console.error("Error Approving the Request", error);
    throw error;
  }
}


// ------------------------------------------------------------------------------------
export const getCompanyRegAndGstFile = async (companyName) => {
  try {
    const response = await Axios.get(
      `${APIs.getCompanyFile}${companyName}`
    );
    return response;
  } catch (error) {
    console.error("Error On getting company file", error);
    throw error;
  }
}
export const getPoints = async (companyName) => {
  try {
    const response = await Axios.get(
      `${APIs.points}?company=${companyName}`
    );
    if (response.status === 200) {
      return response.data.message[0]
    } else {
      throw new Error("Error on getPoints API calling");
    }

  } catch (error) {
    console.error("Error On 'getPoints' file", error);
    throw error;
  }
}


export const getPointsHistoryAndGraphData = async (data) => {
  try {
    // `${APIs.PointsHistoryAndGraphData}?company=${company}`
    // ${APIs.PointsHistoryAndGraphData}?user_name=${username}&company=${company}&email=${email}
    const { company} = data
    
    const response = await Axios.get(
      `${APIs.PointsHistoryAndGraphData}?company=${company}`
    );
    if (response.status === 200) {
      return response.data.message
    } else {
      throw new Error("Error on getPointsHistoryAndGraphData API calling");
    }


  } catch (error) {
    console.error("Error On 'getPoints' file", error);
    throw error;
  }
}


export const SignUpResendOTP = async (email) => {
  try {
    const response = await Axios.post(
      APIs.SignUpResendOTP,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
     console.log("handResendOTPFunctionAPI",response)
    if (response.data.message.status === "success") {
      console.log("Success message from server: ", response.data.message);
      return response.data.message.status;
    } else {
      console.error("Unexpected response from server: ", response.data);
      throw new Error("Failed to send Signup OTP");
    }
  } catch (error) {
    console.error("Error in SignUpResendOTP API:", error);
    throw error;
  }
};


const AuthHeader = {
  addExEmployee,
  addNonJoiner,
  //ProfileData,
  getNewPassword,
  UserAdd,
  UserDetail,
  updateUserDetails,
  updateUserPassword,
  fetchTotalEarnedPoints,
  getNonJoinerEmployee,
  getExEmployee,
  SuperAdminUserList,
  updateExEmployee,
  updateNonJoiners,
  activateDeactivateUser,
  getUserCompany,
  getRecentlySearchedUser,
  approveDeleteRequest,
  updateCompanyDetails
};
export default AuthHeader;
