import Axios from "./commonHttp";
import APIs from "../api";

const getCompanyList = async (pageNo, recordsPerPage) => {
  return Axios({
    method: "get",
    url: "/api/resource/Company Profile",
    params: {
      fields: '["*"]',
      limit_start: pageNo,
      limit_page_length: recordsPerPage,
      order_by: "creation desc",
    },
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
};

const getCompanyUserList = async (pageNo, recordsPerPage) => {
  return Axios({
    method: "get",
    url: "/api/resource/User",
    params: {
      fields:
        '["creation","company","owner","email","username","phone","mobile_no"]',
      limit_start: pageNo,
      limit_page_length: recordsPerPage,
      order_by: "creation desc",
    },
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      console.log(response);
      return response.data.data;
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
};

export const getpendingCompanyforSuperadmin = async () => {
  try {
    const response = await Axios.get(APIs.getUsers, {
      params: {
        doctype: "Company Profile",
        filters: JSON.stringify([["workflow_state", "=", "pending"]]),
        fields: JSON.stringify(["*"]),
        order_by: "creation desc",
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    const { keys, values } = response.data.message;

    const users = values.map((userArray) => {
      let userObject = {};
      keys.forEach((key, index) => {
        userObject[key] = userArray[index];
      });
      return userObject;
    });

    return users;
  } catch (error) {
    console.error("Error in Pending Companies:", error);
    throw error;
  }
};

export const getApprovedCompanies = async () => {
  try {
    const response = await Axios.get(APIs.getUsers, {
      params: {
        doctype: "Company Profile",
        filters: JSON.stringify([["workflow_state", "=", "approved"]]),
        fields: JSON.stringify(["*"]),
        order_by: "creation desc",
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    const { keys, values } = response.data.message;
    const users = values.map((userArray) => {
      let userObject = {};
      keys.forEach((key, index) => {
        userObject[key] = userArray[index];
      });
      return userObject;
    });

    console.log(users, "Success in approved Applications for ");
    return users;
  } catch (error) {
    console.error("Error in Approved Companies:", error);
    throw error;
  }
};

export const fetchSubscriptionTransactionList = async () => {
  try {
    const response = await Axios.post(
      APIs.getSubscriptionTransactionList,
      {
        doctype: "Subscription Transaction",
        fields: ["*"],
        filters: [],
        order_by: "`tabSubscription Transaction`.`modified` desc",
        start: 0,
        page_length: 20,
        view: "List",
        group_by: "",
        with_comment_count: true,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { keys, values } = response.data.message;
    const transactions = values.map((transactionArray) => {
      let transactionObject = {};
      keys.forEach((key, index) => {
        transactionObject[key] = transactionArray[index];
      });
      return transactionObject;
    });

    console.log(transactions, "Success message Subscription Transaction List");
    return transactions;
  } catch (error) {
    console.error("Error in getting Subscription Transaction List:", error);
    throw error;
  }
};

export const getCompanyDetails = async (companyName) => {
  try {
 
    const response = await fetch(
      `${APIs.getCompanyDetails}?doctype=Company Profile&name=${encodeURIComponent(
        companyName
      )}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Failed to fetch company details: ${response.statusText}`
      );
    }

    const data = await response.json();
    // console.log("companyNamecompanyName",data)
    if (data && data.docs && data.docs.length > 0) {
      return data.docs[0]; // Return the first company profile
    } else {
      throw new Error("No company details found");
    }
  } catch (error) {
    console.error("Error fetching company details:", error);
    throw error;
  }
};


export const getUpdateSubscriptionPlan = async (payload) => {
  return await fetch(APIs.updateSubscriptionPlan, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((response) => response.json());
};



const SuperAdminService = {
  getCompanyList,
  getCompanyUserList,
  getpendingCompanyforSuperadmin,
};
export default SuperAdminService;
