import {
  getrecentlyAddedReviewsData,
  getSubscriptionList,
  getTypeOfReview,
  searchUsers,
} from "../Services/authHeader";

const APIs = {
  forgotPassword: "/api/method/watcherp.customizations.user.forgot_password",
  login: "/api/method/watcherp.api.login.signin",
  signUp: "/api/resource/User",
  getRegions: "/api/resource/Region",
  verifyOTP: "/api/method/watcherp.customizations.user.verify_otp",
  exEmployeeReviews: "/api/resource/Ex Employee Reviews",
  nonJoinersReviews: "/api/resource/Non Joiners Reviews",
  getTypeOfReview: "/api/method/frappe.desk.reportview.get",
  userProfile: "/api/resource/User",
  addUser: "/api/resource/User",
  getUser: "/api/method/frappe.client.get_list",
  getUsers: "/api/method/frappe.desk.reportview.get",
  deleteUser: "/api/resource/User",
  searchUsers: "/api/resource/User",
  searchEmployees: "api/method/watcherp.api.employee.employee",
  changePasswrd: "/api/method/watcherp.customizations.user.create_new_password",
  // uploadfile: "/api/method/upload_file",
  // searchEmployees:'api/method/frappe.desk.reportview.get?doctype=User&fields=[%22*%22]'
  activateDeactivateUser: "/api/resource/User",
  getUserComapany: "/api/resource/User",
  userSubscription:
    "/api/method/watcherp.watch_erpnext.doctype.subscription_configration.subscription_configration.subscription_details",
  addSubscriptionPlan:
    "/api/method/watcherp.watch_erpnext.doctype.subscription_configration.subscription_configration.add_subscription_plan",
  contactUs: "/api/resource/Contact Us",
  deleteRequest: "/api/resource/Deletion Request",
  // uploadprofile: "/api/method/upload_file",
  uploadfile: "/api/method/watcherp.api.file.custom_uploadfile",
  uploadprofile: "/api/method/watcherp.api.file.custom_uploadfile",
  getSubscriptionPlanList: "/api/resource/Subscription%20Transaction",
  getsubscriptionhistory:
    "/api/resource/Integration%20Request?order_by=creation%20desc&fields=[%22*%22]",
  getSubscriptionTransactionList: "/api/method/frappe.desk.reportview.get",
  getRecentSubscriptionTransactions:
    "/api/method/frappe.desk.reportview.get_count",
  //getRecentSubscriptionTransactions: "/api/resource/Subscription Transaction",
  getCompanyDetails: "/api/method/frappe.desk.form.load.getdoc",
  putCompanyDetails:"/api/resource/Company Profile",
  updateIssueStatus: "/api/method/frappe.model.workflow.apply_workflow",

  getDeletionRequest: "/api/method/frappe.desk.reportview.get",
  updateSubscriptionPlan:
    "/api/method/watcherp.watch_erpnext.doctype.subscription_configration.subscription_configration.update_subscription_plan",
  getDeleteRequest: "/api/method/watcherp.api.employee.get_deletion_request",
  saveReviewSearchLogsRequest: "/api/resource/Review Search Log",
  deductpointsonsearch:
    "/api/method/watcherp.override.api.points_debit_on_search",
  getrecentlysearchedusers:
    "/api/method/watcherp.watch_erpnext.doctype.review_search_log.review_search_log.get_filtered_review_search_logs?user_comapny=",
  getSubscriptionList: "/api/resource/Integration Request",

  // ------------------------------------------------------------------------
  getCompanyFile:'/api/resource/Company%20Profile/',
  points:'/api/method/watcherp.api.home.get_company_points',
  PointsHistoryAndGraphData:'/api/method/watcherp.api.home.get_dashboard_data',
  SignUpResendOTP:"/api/method/watcherp.customizations.user.generate_otp"

};
export default APIs;
