import React,{useState} from "react";
import NavbarDashboard from "../../Widgets/SuperAdminDashboardNavbar";
// import User from "../../Assets/Images/user.png";
import * as Yup from "yup";
import { useFormik } from "formik";
// import Graph from "./graph";
import AuthHeader from "../../Services/authHeader";
import { useNavigate } from "react-router-dom";
import User from '../../Assets/Images/user-icon.png'
import Image from '../../Assets/Images/close.png'
import Adminusericon from '../../Assets/Images/admin-user-icon.png'
// import Prizes from '../../Assets/Images/prize.png'
import {Link} from 'react-router-dom'
import { MdNavigateNext } from "react-icons/md";

const Approve = () => {
  const navigate = useNavigate();
  const [showbutton, setShowButton] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpen2, setIsOpen2] = React.useState(false);

  
  const addUser = Yup.object().shape({
    email: Yup.string().required("Please enter your email"),
    first_name: Yup.string().required("Enter your first name"),
    phone: Yup.string().required("Please enter your mobile number"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      phone: "",
    },
    validationSchema: addUser,
    onSubmit: (values) => {
      addReviewUser(values);
      navigate("/data");
      
    },
  });
  async function addReviewUser(values) {
    try {
      await AuthHeader.UserAdd(values.email, values.first_name, values.phone);
    } catch (error) {
      console.log("error", error);
    }
  }
  const handleClose = () => {
    setIsOpen(false);
    document.getElementById("dark-mode-on").classList.remove("dark-mode-on")
  };
  const handleClose2 = () => {
    setIsOpen2(false);
    document.getElementById("dark-mode-on").classList.remove("dark-mode-on")
  };
  const handleCloseButton = () => {
    setShowButton(true);
  };
 
  return (
    <>
     <div id="dark-mode-on"></div>

      <NavbarDashboard />

      <header class="text-gray-600 body-font overflow-auto">
        <div class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <div
            href="/"
            class="flex title-font font-medium items-center text-gray-900 mb-4 pl-64 md:mb-0 approve-padding"
          >
            <span class="ml-3.5 home-dashboard-margin text-xl">Search</span>
          </div>
          <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center pr-5">
          {/* <button class="bg-black  text-white font-2 py-2 px-4 rounded-full flex">
            <img src={Prizes} alt=""></img>
              1000 Points
            </button>
            &nbsp;
            &nbsp; */}
            <div class="flex items-center space-x-4  h-12 px-6 border border-gray-300 rounded-full pl-0">
              <img class="rounded-full user-img" src={User} alt="user" />
              <div class="font-medium dark:text-white">
               <button onClick={()=>{
                setIsOpen(true)
                document.getElementById("dark-mode-on").classList.add("dark-mode-on")
               }}>Add User +</button>
              </div>
            </div>
            &nbsp;
            &nbsp;
            <div class="dropdown">
              <button
                className="flex items-center super-admin-logout space-x-8 pr-4  h-13 border border-gray-300 rounded-full mr-0 py-1 "
                onClick={handleCloseButton}
              >
                <img
                  class="Adminusericon rounded-full"
                  src={Adminusericon}
                  alt=""
                />
                <h3 class="block admin-name-txt">
                Stephen Austin <span class="block admin-txt">Admin</span>
                </h3>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 14L8 10H16L12 14Z" fill="#647086" stroke="#647086" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              </button>
              {/* dropdown menu */}
              <div
                id="dropdown"
                className={`z-10 w-40 h-auto mt-4 bg-white absolute dropdown-logout rounded-full divide-y divide-gray-100 shadow ${
                  showbutton ? "block" : "hidden"
                }`}
              >
               
                    <Link to='/Logout'
                   
                      className="block py-1 px-2 text-left logout-link"
                    >
                      Logout
                    </Link>
                 
              </div>
            </div>
          </nav>
        </div>
       
       
      </header>
      <hr className="hrLine" />
      {/* Modal for button */}
      <div
        class=" w-auto  " 
      >
        <div class="mr-10 search-margin">
          <div class="flex justify-between mb-8 md:grid-cols-2 gap-4 search-flex">
            <div className="w-1/4">
              <input
                type="text"
                id="first_name"
                name="username"
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Company Name"
              />
            </div>
            <div className="w-1/4">
              <input
                type="text"
                id="last_name"
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Phone Number"
                name="phone"
              />
            </div>
            <div className="w-1/4">
              <input
                type="text"
                id="last_name"
                name="email"
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Email"
              />
            </div>
            <div class="pr-0">
              <button
                class="text-white search-btn bg-gray-800 border border-gray-200 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                type="button"
              >
                Search
              </button>
            </div>
            <div class="pr-0">
              <button
                class="filter-btn text-black bg-white border border-gray-200 hover:bg-white focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                type="button"
                onClick={()=>{
                  setIsOpen2(true)
                  document.getElementById("dark-mode-on").classList.add("dark-mode-on")
                 }}
              >
                <svg
                  className="w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  id="filter"
                >
                  <path d="M2 7h.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2H9.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2zm4-3a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm24 11h-.142a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h20.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-4 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm4 7H19.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h10.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-14 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"></path>
                </svg>
                &nbsp; More Filter
                <svg
                  class="w-4 h-4 ml-2"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="black">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="verflow-x-auto approve-table-div mr-10 shadow-md border border-gray-300 sm:rounded-lg">
          <table class="w-full approve-table text-sm text-left text-gray-500 dark:text-gray-400">
            <caption class="caption-top top-border p-1 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
              <div class="container1 mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <span class="ml-0 text-xl search-result">Results</span>
                <div class="md:ml-auto flex flex-wrap items-center text-base justify-center pr-0">
                  <button class="bg-gray-800 hover: text-white font-base py-2 px-4 rounded">Download</button>
                  <button class="bg-gray-800 ml-8 hover: text-white font-base py-2 px-4 rounded">Create New</button>
                  </div>
                  </div>
                  </caption>
                  <thead class="text-xs text-gray-700 uppercase search-table dark:bg-gray-700 dark:text-gray-400">
                    <tr><th scope="col" class="px-6 py-3">Company Name</th><th scope="col" class="px-6 py-3">Email</th><th scope="col" class="px-6 py-3">Created By</th><th scope="col" class="px-6 py-3">Created on</th><th scope="col" class="px-6 py-3">Phone</th><th scope="col" class="px-6 py-3">Submissions</th><th scope="col" class="px-6 py-3">Industry</th><th scope="col" class="px-6 py-3">Points</th><th scope="col" class="px-6 py-3">Status</th></tr></thead>
                    <tbody><tr>
                      <td  class="px-6 py-3">ABC Infotech</td>
                      <td  class="px-6 py-3">abc@xyz.com</td>
                      <td  class="px-6 py-3">Rajat Kumar</td>
                      <td  class="px-6 py-3">27/02/22</td>
                      <td  class="px-6 py-3">9876543210</td>
                      <td  class="px-6 py-3">200</td>
                      <td  class="px-6 py-3">Chemical</td>
                      <td  class="px-6 py-3">100000</td>
                      <td  class="px-6 py-3">Approved</td>
                      </tr></tbody></table>
                      
                      </div>
                      <div className="container1 mx-auto flex flex-wrap p-8 flex-col md:flex-row items-center pr-0 pb-0 mr-10  approve-next-div">
          <div class="md:ml-auto flex flex-wrap items-center text-base justify-center pr-0">
            <p className="mr-3">Next</p>
            <button
              // onClick={nextPage}
              class="bg-gray-800 hover: text-white font-base py-2 px-4 rounded"
            >
              <MdNavigateNext />
            </button>
          </div>
                      </div>
        </div>
      {/* Card for table */}

        {isOpen && (
          <div className="fixed top-0 right-0 h-full w-1/5 h-1446px p-4 bg-gray-50 popup-form popup-form-dashboard">
            <button
              className="absolute top-4 right-4 p-2 text-gray-600"
              onClick={handleClose}
            >
              <img src={Image} alt="abc"></img>
              
            </button>
            <form onSubmit={formik.handleSubmit}>
              <div className="box pl-8 pr-6">
                <h1 className=" mb-1  text-lg font-bold">Add User</h1>
                <p className="add-user-popup pb-6">Add New User</p>
              </div>
              <hr className="hrLine pb-6" />
              {/* Form fields */}
              <div class="mt-1 pl-8 pr-6">
              <input
                type={"email"}
                name="email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
              />
              <p style={{ color: "red" }} class="required-text">
                {formik.errors.email ? formik.errors.email : ""}
              </p>
              &nbsp;
              </div>
              <div class="mt-1 pl-8 pr-6">
              <input
                type={"text"}
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                placeholder="Name"
                className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
              />
              <p style={{ color: "red" }} class="required-text">
                {formik.errors.first_name ? formik.errors.first_name : ""}
              </p>
              &nbsp;
              </div>
              <div class="mt-1 mb-1 pl-8 pr-6">

              <input
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                placeholder="Phone"
                className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
              />
              <p style={{ color: "red" }} class="required-text">
                {formik.errors.phone ? formik.errors.phone : ""}
              </p>
              &nbsp;
</div>
         {/* <div class="absolute bottom-8 ">
         <hr className="hrLine" />

         </div> */}

              {/* Add more form fields as needed */}
              <div className="flex items-center justify-between pl-8 pr-6 rounded-b">
                <button
                  className="reset-btn absolute bottom-6 left-8 m-0 font-bold  text-sm px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleClose}
                >
                  Reset
                </button>
                
                <button
                  className="bg-black absolute bottom-6 right-6 m-0 text-white font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
    {isOpen2 && (
          <div className="fixed top-0 right-0 h-full w-1/5 h-1446px p-4 bg-gray-50 popup-form popup-form-dashboard">
            <button
              className="absolute top-4 right-4 p-2 text-gray-600"
              onClick={handleClose2}
            >
              <img src={Image} alt="abc"></img>
              
            </button>
            <form onSubmit={formik.handleSubmit}>
              <div className="box pl-8 pr-6">
                <h1 className=" mb-1  text-lg font-bold">Filters</h1>
                <p className="add-user-popup pb-6">Advance Search</p>
              </div>
              <hr className="hrLine pb-6" />
              {/* Form fields */}
              <div class="mt-1 pl-8 pr-6">
                <p class="searched-text">Search</p>
              <input
                type={"email"}
                name="email"
                placeholder="Created By"
                value={formik.values.email}
                onChange={formik.handleChange}
                className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
              />
              <p style={{ color: "red" }} class="required-text">
                {formik.errors.email ? formik.errors.email : ""}
              </p>
              &nbsp;
              </div>
              <div class="mt-1 pl-8 pr-6">
              <input
                type={"text"}
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                placeholder="Date"
                className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
              />
              <p style={{ color: "red" }} class="required-text">
                {formik.errors.first_name ? formik.errors.first_name : ""}
              </p>
              &nbsp;
              </div>
              <div class="mt-1 mb-1 pl-8 pr-6">

              <input
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                placeholder="Status"
                className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
              />
              <p style={{ color: "red" }} class="required-text">
                {formik.errors.phone ? formik.errors.phone : ""}
              </p>
              &nbsp;
</div>
         {/* <div class="absolute bottom-8 ">
         <hr className="hrLine" />

         </div> */}

              {/* Add more form fields as needed */}
              <div className="flex items-center justify-between pl-8 pr-6 rounded-b">
                {/* <button
                  className="reset-btn absolute bottom-6 left-8 m-0 font-bold  text-sm px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleClose}
                >
                  Reset
                </button> */}
                
                <button
                  className="bg-black absolute bottom-6 right-6 m-0 text-white font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
    </>
  );
};
export default Approve;
