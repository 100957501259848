import React, { useState, useEffect } from "react";
import { Col, Button, Layout, Row, Table, Popconfirm } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import Dashboardnavbar from "../../../Widgets/Dashboardnavbar";
import CommonNavbar from "../../../Widgets/CommonNavbar";
import { toast, ToastContainer } from "react-toastify";
import AddUser from "./AddUser";
import {
  getUsers,
  deleteUser,
  activateDeactivateUser,
  getUserCompany,
} from "../../../Services/authHeader";
import { binarySearchByEmail } from "../../../Services/BinarySearch";

const Users = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userCompany, SetUserCompany] = useState("");
  const [roleprofile, setroleprofile] = useState({})


  // Fetch users when component mounts
  const workflow_state = JSON.parse(sessionStorage.getItem("workflow_state"))

  useEffect(() => {
    fetchUsers();
    getCompany();
  }, []);

  useEffect(() => {
    if (userCompany) {
      fetchUsers();
    }
  }, [userCompany]);

  // Function to fetch users
  const fetchUsers = async () => {
    try {
      const users = await getUsers();
      // Filter users based on company
      const filteredUsers = users.filter(
        (user) => user.company === userCompany
      );
      setUserList(filteredUsers);
      // console.log("filtered users", filteredUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Function to get company details
  const getCompany = async () => {
    try {
      const data = JSON.parse(sessionStorage.getItem("usr"));
      const email = data.email;
      const res = await getUserCompany(email);
      if (res.status === 200) {
        // console.log("res", res.data.data.company);
        SetUserCompany(res.data.data.company);
        setroleprofile(res.data.data)
      } else {
        console.log("error!");
      }
    } catch (error) {
      console.log("Error Getting Company Name");
    }
  };

  const handleDelete = async (email) => {
    try {
      await deleteUser(email);
      toast.success("User deleted successfully");
      // Update the local user list state
      setUserList((prevList) =>
        prevList.filter((user) => user.email !== email)
      );
    } catch (error) {
      toast.error("Error deleting user");
      console.error("Error deleting user:", error);
    }
  };

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClose = () => {
    setIsOpen(false);
    const darkModeElement = document.getElementById("dark-mode-on");
    if (darkModeElement) {
      darkModeElement.classList.remove("dark-mode-on");
    }
    fetchUsers();
  };

  const handleAddUserSuccess = () => {
    fetchUsers();
  };

  const handleBlock = async (email, value) => {
    const payload = {
      data: {
        enabled: value,
      },
    };

    try {
      const res = await activateDeactivateUser(email, payload);
      if (res.status === 200) {
        if (value === 0) toast.success(`Disabled ${email}`);
        else toast.success(`Enabled ${email}`);
        setUserList((prevList) =>
          prevList.map((user) =>
            user.email === email ? { ...user, enabled: value } : user
          )
        );
      } else {
        toast.error("error!");
      }
    } catch (error) {
      console.error("Error updating Disable or Enable User:", error);
      toast.error("Failed to update Disable or Enable User.");
    }
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: "Company",
    //   dataIndex: "company",
    //   key: "company",
    // },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Profile Image",
      dataIndex: "user_image",
      key: "user_image",

      render: (text, record) => (
        <a href={record.user_image} target="_blank" rel="noopener noreferrer">
          {record.user_image === null ? "" :
            <img
              src={record.user_image}
              alt="Image"
              style={{ width: "60px", height: "40px", borderRadius: "50%" }}
            />
          }
        </a>
      ),

    },
    {
      title: "Status",
      dataIndex: "enabled",
      key: "enabled",
      render: (text) => (
        <span className={text === 1 ? "active-user" : "inactive-user"}>
          {text === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (text, record) => (
        <Popconfirm
          title="Are you sure to delete this user?"
          onConfirm={() => handleDelete(record.email)}
          okText="Yes"
          cancelText="No"
        >

          <Button type="link" danger className="delete-user-btn"
           disabled={!workflow_state ? true : (roleprofile?.role_profile_name==="Semi-admin" ? false : true) }
          >
            Delete
          </Button>
        </Popconfirm>
      ),
    },
    {
      title: " ",
      key: "blockAndUnblock",
      width: 100,
      render: (text, record) => (
        <>
          {record.enabled ? (
            <Button
              onClick={() => handleBlock(record.email, 0)}
              type="link"
              className="delete-user-btn block-user-btn"
              disabled={!workflow_state ? true : (roleprofile?.role_profile_name==="Semi-admin" ? false : true) }

            >
              Disable
            </Button>
          ) : (
            <Button
              type="link"
              onClick={() => handleBlock(record.email, 1)}
              className="delete-user-btn block-user-btn"
              disabled={!workflow_state ? true : (roleprofile?.role_profile_name==="Semi-admin" ? false : true) }
            >
              Enable
            </Button>
          )}
        </>
      ),
    },
  ];

  document.body.classList.remove(
    "dashboard-page",
    "profile-page",
    "billing-page",
    "search-page",
    "review-page",
    "ex-employee"
  );
  document.body.classList.add("users-page");

  const rowClassName = (record) => {
    return record.enabled === 0 ? "inactive-row" : "";
  };

  // console.log("userListuserList",userList)
  return (
    <Layout className="h-screen">
      <ToastContainer />
      <div id="dark-mode-on"></div>
      <Sider width={256} className="site-layout-background">
        <Dashboardnavbar />
      </Sider>
      <Layout className="panel-right">
        <CommonNavbar />
        <Content>
          <div className="site-layout-background ">
            <Row className="tabs-addReviewBtn addUserBtn">
              <Col>
                <Button type="default" onClick={() => setIsOpen(true)} disabled={!workflow_state}>
                  Add User
                </Button>
              </Col>
            </Row>
            {isOpen && (
              <AddUser
                handleClose={handleClose}
                onSuccess={handleAddUserSuccess} // Pass success callback
                userCompany={userCompany}
              />
            )}
            <div className="user-list-table">
              <h2>Users Listing</h2>

              {/* <Table
                columns={columns}
                dataSource={userList}
                rowKey="email"
                rowClassName={rowClassName}
              /> */}

              <Table
                columns={columns}
                dataSource={roleprofile?.role_profile_name === "Semi-admin" ? userList : binarySearchByEmail(userList,roleprofile.email)}
                rowKey="email"
                rowClassName={rowClassName}
                pagination={roleprofile?.role_profile_name === "Semi-admin" ? { pageSize: 10 } : false}
              />


            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Users;
