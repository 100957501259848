import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sider from "antd/es/layout/Sider";
import { Layout, Table, Button } from "antd";
import { Content } from "antd/es/layout/layout";
import SuperAdminNav from "../../../Widgets/SuperAdminNavbar";
import SuperAdminSideNavbar from "../../../Widgets/SuperAdminDashboardNavbar";
import { getApprovedCompanies } from "../../../Services/superadminService";
import CompanyDetails from "./company-details";
import FilterIcon from "../../../../src/Assets/Images/filter-icon.svg";
import DownArrow from "../../../../src/Assets/Images/down-arrow.svg";

const CompanyApprovedList = () => {
  const [company, setCompany] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [getSearchUser, setGetSearchUser] = useState({
    company: "",
    phone: "",
    email: "",
  });

  useEffect(() => {
    fetchApprovedCompanies();
  }, []);

  const fetchApprovedCompanies = async () => {
    try {
      const res = await getApprovedCompanies();
      setCompany(res);
      setFilteredCompanies(res);
      console.log(res, "Successfully fetched companies");
    } catch (error) {
      toast.error("Error fetching companies");
      console.error("Error fetching companies:", error);
    }
  };

  const handleSearchUser = (e) => {
    const { name, value } = e.target;
    setGetSearchUser({ ...getSearchUser, [name]: value });
    filterCompanies({ ...getSearchUser, [name]: value });
  };

  const filterCompanies = (searchValues) => {
    const { company, phone, email } = searchValues;

    const filtered = company.filter((comp) => {
      return (
        comp.company.toLowerCase().includes(company.toLowerCase()) &&
        comp.buisness_phone.includes(phone) &&
        comp.buisness_email.toLowerCase().includes(email.toLowerCase())
      );
    });
    setFilteredCompanies(filtered);
  };

  const handleViewCompany = (record) => {
    setSelectedCompany(record);
    setShowForm(true);
  };

  const handleBackToTable = () => {
    setSelectedCompany(null);
    setShowForm(false);
  };

  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };

  const columns = [
    { title: "Company Name", dataIndex: "company", key: "company" },
    {
      title: "Email",
      dataIndex: "buisness_email",
      key: "buisness_email",
    },
    {
      title: "Created By",
      dataIndex: "owner",
      key: "owner",
    },
    {
      title: "Creation On",
      dataIndex: "creation",
      key: "creation",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    { title: "Phone", dataIndex: "buisness_phone", key: "buisness_phone" },
    {
      title: "Submissions",
      dataIndex: "no_of_employees",
      key: "no_of_employees",
    },
    { title: "Industry", dataIndex: "industry", key: "industry" },
    { title: "Status", dataIndex: "workflow_state", key: "workflow_state" },

    {
      title: "View",
      key: "View",
      render: (text, record) => (
        <a href="#!" onClick={() => handleViewCompany(record)}>
          View
        </a>
      ),
    },
  ];

  document.body.classList.add("approve-page");
  document.body.classList.remove(
    "dashboard-page",
    "profile-page",
    "issue-page",
    "search-page",
    "users-page",
    "reports-page",
    "companyreportsave-page",
    "review-page",
    "deletion-page",
    "billing-page"
  );

  return (
    <>
      <div id="dark-mode-on"></div>
      <Layout className="h-full">
        <Sider width={256} className="site-layout-background">
          <SuperAdminSideNavbar />
        </Sider>
        <Layout className="panel-right">
          <SuperAdminNav />
          <Content className="dashboard-layout">
            <div className="w-auto" style={{ paddingTop: "2.5rem" }}>
              <div className="search-right">
                <div className="search-fields">
                  <div className="flex justify-between mb-8 search-table-section">
                    <div className="form-fields">
                      <input
                        type="text"
                        id="company_name"
                        name="company"
                        placeholder="Search Company Name"
                        value={getSearchUser.company}
                        onChange={handleSearchUser}
                      />
                    </div>
                    <div className="form-fields">
                      <input
                        type="text"
                        id="phone"
                        placeholder="Search Phone Number"
                        name="phone"
                        value={getSearchUser.phone}
                        onChange={handleSearchUser}
                      />
                    </div>
                    <div className="form-fields">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Search Email"
                        value={getSearchUser.email}
                        onChange={handleSearchUser}
                      />
                    </div>
                    <div className="pr-0">
                      <button
                        className={`flex border p-2 rounded-md more-filter-btn ${
                          isExpanded ? "expanded" : ""
                        }`}
                        type="button"
                        onClick={handleButtonClick}
                      >
                        <img
                          src={FilterIcon}
                          alt=""
                          style={{
                            filter: isExpanded
                              ? "invert(1) brightness(1000%)"
                              : "none",
                          }}
                        />
                        More Filter
                        <img
                          src={DownArrow}
                          alt=""
                          style={{
                            filter: isExpanded
                              ? "invert(1) brightness(1000%)"
                              : "none",
                          }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {showForm ? (
                <CompanyDetails
                  selectedCompany={selectedCompany}
                  handleBackToTable={handleBackToTable}
                />
              ) : (
                <>
                  <div className="result-table-head">
                    <h2>Approved Companies</h2>
                    <div className="right-head">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn btn-primary dark-btn"
                      >
                        Create New
                      </Button>
                    </div>
                  </div>
                  <Table
                    dataSource={filteredCompanies}
                    columns={columns}
                    rowKey="name"
                  />
                </>
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default CompanyApprovedList;
