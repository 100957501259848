import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, message, Tabs, Table } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import {
  getExEmployee,
  getNonJoinerEmployee,
} from "../../../Services/authHeader";

const CompanyDetails = ({ selectedCompany, handleBackToTable }) => {
  const [nonJoiners, setNonJoiners] = useState([]);
  const [exEmployees, setExEmployees] = useState([]);
  const [activeTab, setActiveTab] = useState("exEmployees");

  async function fetchNonJoiners() {
    try {
      const data = await getNonJoinerEmployee();
      const filteredData = data.filter(
        (user) => user.company_id === selectedCompany.company_name
      );
      setNonJoiners(filteredData);
    } catch (error) {
      console.log("Error:", error);
    }
  }

  async function fetchExEmployees() {
    try {
      const data = await getExEmployee();
      const filteredData = data.filter(
        (user) => user.company_id === selectedCompany.company_name
      );
      console.log(filteredData, "fhgljjjjjjjjjjjjjjjjjpppppppppppp");
      setExEmployees(filteredData);
    } catch (error) {
      console.log("Error:", error);
    }
  }

  useEffect(() => {
    if (selectedCompany) {
      fetchExEmployees();
    }
  }, [selectedCompany]);

  const handleFormSubmit = async (values) => {
    console.log("Form submitted with values:", values);
    // Handle form submission logic here
    message.success("Company details updated successfully", 3);
    handleBackToTable();
  };

  const columns = [
    { title: "Name", dataIndex: "first_name", key: "first_name" },
    { title: "Creation Date", dataIndex: "date", key: "date" },
    { title: "Company Name", dataIndex: "company_name", key: "company_name" },
    { title: "Submitted by", dataIndex: "submitted_by", key: "submitted_by" },
    { title: "Joined on", dataIndex: "joined_on", key: "joined_on" },
    { title: "Relieved on", dataIndex: "released_on", key: "released_on" },
    { title: "Designation", dataIndex: "designation", key: "designation" },
    { title: "Feedback", dataIndex: "date", key: "date" },
    { title: "Status", dataIndex: "workflow_state", key: "workflow_state" },
  ];

  const formatDateToDDMMYYYY = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === "nonJoiners") {
      fetchNonJoiners();
    } else if (key === "exEmployees") {
      fetchExEmployees();
    }
  };

  const filteredData = activeTab === "nonJoiners" ? nonJoiners : exEmployees;

  filteredData.forEach((user) => {
    user.date = formatDateToDDMMYYYY(user.creation); // Assuming `creation` is the date field
  });

  return (
    <>
      <div className="form-container">
        <Form
          layout="vertical"
          initialValues={selectedCompany || {}}
          onFinish={handleFormSubmit}
        >
          <div className="form-head">
            <h2>
              {selectedCompany
                ? `Company - ${selectedCompany.name}`
                : "Create Company"}
            </h2>
            <div
              className="right-btns"
              style={{ display: "flex", gap: "1rem" }}
            >
              <Button type="primary" htmlType="submit">
                Update
              </Button>
              <Button type="default" onClick={handleBackToTable}>
                Back
              </Button>
            </div>
          </div>
          <div className="formbody">
            <Form.Item
              name="buisness_email"
              label="Business Email"
              rules={[
                { required: true, message: "Please enter the business email" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="buisness_phone"
              label="Business Phone"
              rules={[
                { required: true, message: "Please enter the business phone" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please enter the name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="owner"
              label="Created By"
              rules={[
                { required: true, message: "Please enter the owner's name" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="company"
              label="Company"
              rules={[{ required: true, message: "Please enter the Company" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="region"
              label="Region"
              rules={[{ required: true, message: "Please enter the region" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="industry"
              label="Type"
              rules={[
                {
                  required: true,
                  message: "Please enter the number of industry",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="creation"
              label="Creation Date"
              rules={[
                { required: true, message: "Please enter the creation date" },
              ]}
            >
              <Input /> 
            </Form.Item>
            <Form.Item
              name="no_of_employees"
              label="Number of Employees"
              rules={[
                {
                  required: true,
                  message: "Please enter the number of employees",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="workflow_state"
              label="Status"
              rules={[{ required: true, message: "Please select the status" }]}
            >
              <Select>
                <Select.Option value="Pending">Pending</Select.Option>
                <Select.Option value="Approved">Approved</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="details"
              label="Details"
              rules={[
                {
                  required: true,
                  message: "Please enter the number of details",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
        </Form>
      </div>
      <div className="reviews-list-table">
        <div className="toggle-buttons">
          <Tabs defaultActiveKey="exEmployees" onChange={handleTabChange}>
            <TabPane tab="Ex-Employees" key="exEmployees" />
            <TabPane tab="Non-Joiners" key="nonJoiners" />
          </Tabs>
        </div>
        <div className="dashboard-blocks">
          <div className="dashboard-header">
            <h2>Reviews List</h2>
          </div>
          <Table
            dataSource={filteredData.slice(0, 7)}
            columns={columns}
            pagination={false}
          />
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
