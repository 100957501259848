import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginLeft from "./LoginLeft";
import LoginRight from "./LoginRight";
import { forgotPassword } from "../../Services/authHeader";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formValidation = Yup.object().shape({
    email: Yup.string().email().required("Please enter your email"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: formValidation,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await forgotPassword(values.email);

        console.log('API Response:', response); // Log the response for debugging

        // Check if the response contains a success message
        if (response.message === 'OTP has been sent to your email account.') {
          // Display success message
          toast.success(response.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });

          // Redirect to ConfirmOTP page after a brief delay
          setTimeout(() => {
            navigate(`/ConfirmOTP?email=${values.email}`);
          }, 1000);
        } else {
          // If the response doesn't contain the success message, handle as an error
          toast.error("Failed to send OTP. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        // Handle request error
        const errorMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          "Failed to send OTP. Please try again.";
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <section className="flex m-auto container-fluid w-full h-screen forgot-password-section forgot-password-screen">
      <ToastContainer />
      <LoginLeft>
        <h1>Forgot Password?</h1>
        <form onSubmit={formik.handleSubmit} className="space-y-4 md:space-y-6">
          <div>
            <TextField
              label="Enter Email"
              variant="outlined"
              type="text"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              id="email"
              required
            />
            {formik.errors.email && (
              <p style={{ color: "red" }}>{formik.errors.email}</p>
            )}
          </div>
          <button type="submit" disabled={loading} className="auth-btn">
            <span>Send OTP</span>
            {loading && (
              <span className="spinner-border spinner-border-sm">.....</span>
            )}
          </button>
        </form>
      </LoginLeft>
      <LoginRight />
    </section>
  );
};

export default ForgetPassword;

