import React from "react";
import Navbar from "../../Widgets/navbar";
import BannerImg from "../../Assets/Images/banner-img.webp";
import Form from "../../Assets/Images/form.webp";
import Image1 from "../../Assets/Images/image1.webp";
import Image3 from "../../Assets/Images/image3.webp";
import Image4 from "../../Assets/Images/image4.webp";
import Image5 from "../../Assets/Images/image5.webp";
import Image6 from "../../Assets/Images/image6.webp";
import Globe from "../../Assets/Images/Globe.webp";
import Star from "../../Assets/Images/star.webp";
import Upload from "../../Assets/Images/upload.webp";
import Arrow from "../../Assets/Images/arrow.webp";
import ImageSecImage from "../../Assets/Images/imgbg.webp";
import Client1 from "../../Assets/Images/client1.webp";
import "../../App.css";
import "./styles.css";
import Box from "../../Widgets/box";
import Footer from "../../Widgets/footer";
const Home = () => {
  return (
    <>
      <Navbar />
      <section className="text-gray-600 body-font banner-section">
        <div className="container mx-auto flex px-12 py-12 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center banner-left">
            <h1 className="title-font">Let's Get The Real Reviews Right Now</h1>
            <p className="mb-8 mt-4 leading-relaxed banner-text">
              The portal's goal is to provide reviews of former employees so
              that the business/HR community as a whole can make the best
              selection possible in an affordable way.
            </p>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 ">
            <img src={BannerImg} className="background2" alt="Flowbite Logo" />
          </div>
        </div>
      </section>
      <section className="bg-slate-300 flex px-24 py-12 justify-center boxs-section">
        <div className="flex box-row">
          <div className=" md:w-1/2 lg:pr-12">
            <div>
              <h3 className="title-font">What People Say About Us</h3>
              <p className="mb-8 leading-relaxed para-text">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable.
              </p>
            </div>
            <div className="flex justify-between box1">
              <div className="mt-12 box-one ">
                <Box />
              </div>
              <div className="-mt-4 box-two">
                <Box />
              </div>
            </div>
          </div>
          <div className=" md:w-1/2 lg:pr-12 flex justify-between box2">
            <div>
              <div className="mt-12 box-three">
                <Box />
              </div>
              <div className="mt-12 box-four">
                <Box />
              </div>
            </div>{" "}
            <div>
              <div className="mt-28 box-five">
                <Box />
              </div>
              <div className="mt-12 box-six">
                <Box />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex px-12 py-0 form-section justify-center  px-24 py-12">
          <div className=" md:w-1/2 ">
            <h2 className="form-font mb-4 mt-20">
              Locate a hiring employer from among the countless available.
            </h2>
            <p>
              It will publish evaluations of its current employees, former
              employees, or non-joiner hopefuls. It may frequently solely have
              bad reviews.
            </p>
            <br />
            <p>
              It will publish evaluations of its current employees, former
              employees, or non-joiner hopefuls. It may frequently solely have
              bad reviews.
            </p>
            <button className="Post-btn mt-8">Post A Review</button>
          </div>
          <div className=" md:w-1/2">
            <div className="">
              <img src={Form} className="Form" alt="Form" />
            </div>
          </div>
        </div>
      </section>
      <section className="Update-section  px-24 pb-12 mb-8">
        <h3 className="text-center mb-16">Latest Updates</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 ">
          <div className="flex items-center justify-between update-height">
            <img src={Image1} className="image1" alt="image1" />
          </div>
          <div className="grid content-center	bg-slate-100 p-8 update-height">
            <h6 className="-mt-4">Lorem ipsum dolor sit amet, consectetur</h6>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.{" "}
            </p>
            <div className="flex items-center mt-4">
              <img
                src={Client1}
                className="client1 rounded-full"
                alt="Client1"
              />

              <div className="ml-3">
                <p className="text-sm font-semibold">Sagar Saroha</p>
                <p className="text-xs text-gray-500">Sagar Ltd.</p>
              </div>
            </div>{" "}
          </div>
          <div className="group bg-slate-100 update-height">
            <img
              src={Image3}
              alt="image3"
              width={400}
              height={225}
              className="image3"
            />
            <div className="p-4 bg-background image-text">
              <div className="flex-col items-center justify-between">
                <h6 className="font-semibold line-clamp-2">
                  Lorem ipsum dolor sit amet
                </h6>
                <div className="flex items-center gap-1 text-muted-foreground">
                  <span>
                    It is a long established fact that a reader will be
                    distracted b
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="group bg-slate-100 update-height">
            <img
              src={Image4}
              alt="image4"
              width={400}
              height={225}
              className="image4"
            />
            <div className="p-4 bg-background image-text">
              <div className="flex-col items-center justify-between">
                <h6 className="font-semibold line-clamp-2">
                  Lorem ipsum dolor sit amet
                </h6>
                <div className="flex items-center gap-1 text-muted-foreground">
                  <span>
                    It is a long established fact that a reader will be
                    distracted b
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="group bg-slate-100 update-height">
            <img
              src={Image5}
              alt="image5"
              width={400}
              height={225}
              className="image5"
            />
            <div className="p-4 bg-background image-text">
              <div className="flex-col items-center justify-between">
                <h6 className="font-semibold line-clamp-2">
                  Lorem ipsum dolor sit amet
                </h6>
                <div className="flex items-center gap-1 text-muted-foreground">
                  <span>
                    It is a long established fact that a reader will be
                    distracted b
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="group bg-slate-100 update-height">
            <img
              src={Image6}
              alt="image6"
              width={400}
              height={225}
              className="image6"
            />
            <div className="p-4 bg-background image-text">
              <div className="flex-col items-center justify-between">
                <h6 className="font-semibold line-clamp-2">
                  Lorem ipsum dolor sit amet
                </h6>
                <div className="flex items-center gap-1 text-muted-foreground">
                  <span>
                    It is a long established fact that a reader will be
                    distracted b
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ImageSection flex justify-center items-center  mx-24 my-12">
{/* 
<div>
  <h5 className="mb-16">
  Let the company know the truth
    </h5>
    <button className="mb-12">
    Sign Up For Free
      </button>
      <img
              src={Upload}
              alt="upload"
              className="upload"
            /> 
<u className="text-white mt-12 mb-20">Upload the reviews</u>

  </div> */}
  <img
              src={ImageSecImage}
              alt="upload"
              className="upload"
            /> 
        </section>

<section className="globesection">
<div className="mx-auto flex px-12 py-12 md:flex-row flex-col items-center globeimage">
<div className=" md:w-1/2 lg:pr-8 flex justify-between">
<img src={Globe} className="Globe" alt="Globe" />

</div>
<div className=" md:w-1/2 lg:pr-8 flex-col justify-between">
<h3>
Trusted by thousands
of happy Companies
  </h3>
  <p className="mt-8 mb-4">It will publish evaluations of its current employees, former employees, or non-joiner hopefuls. It may frequently solely have bad reviews.</p>
  <ul className="flex items-center">
    <li className="font-semibold text-xl">Sudhi Nain </li> 
      <li className="pl-2 text-base">UI Designer </li>
    </ul>
    <img src={Star} className="star" alt="star" />
    <p className="mt-4 mb-2">
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
      </p>
      <img src={Arrow} className="arrow" alt="arrow" />
</div>
</div>
  </section>

      <Footer />
    </>
  );
};

export default Home;
