import React, { useEffect, useState } from "react";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import { fetchRegions, signUp } from "../../Services/authHeader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginLeft from "./LoginLeft";
import LoginRight from "./LoginRight";

const SignUp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectState, setSelectState] = useState(null);
  const [getState, setGetState] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const signUpValidation = Yup.object().shape({
    email: Yup.string()
      .required("Please enter your email")
      .email("Please enter a valid email address")
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        "Please enter a valid email address"
      ),
    phone: Yup.string()
      .required("Please enter your phone number")
      .matches(/^[0-9]+$/, "Phone number must be digits only")
      .min(10, "Phone number must be exactly 10 digits")
      .max(10, "Phone number must be exactly 10 digits"),
    first_name: Yup.string().required("Please enter your name"),
    designation: Yup.string().required("Please enter your designation"),
    company_name: Yup.string().required("Please enter your company name"),
    region: Yup.string().required("Please select your region"),
    new_password: Yup.string().required("Please enter your password"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      first_name: "",
      designation: "",
      region: "",
      new_password: "",
      company_name: "",
      admin: 1,
      user_image_file: "",
      call_from: "Signup",
    },
    validationSchema: signUpValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      console.log(values, "Values response ");
      values.region = selectState ? selectState.label : "";
      setLoading(true);
      await userSignUp(values);
    },
  });

  const stripHtmlTags = (str) => {
    if (str) {
      return str.replace(/<\/?[^>]+(>|$)/g, "");
    }
    return str;
  };

  async function userSignUp(values) {
    try {
      await signUp(values);
      toast.success("Sign up successful! Redirecting to confirm OTP...", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      setTimeout(() => {
        navigate("/signup/confirm-otp", {
          state: {
            email: values.email,
            password: values.new_password,
          },
        });
      }, 1000);
    } catch (error) {
      console.log(error);
      setLoading(false);
      let errorMessage = "";

      if (
        error.response &&
        error.response.data &&
        error.response.data._server_messages
      ) {
        const serverMessages = JSON.parse(error.response.data._server_messages);
        console.log(serverMessages, "Server messages from server");

        const filteredMessages = serverMessages.filter((msg) => {
          const parsedMsg = JSON.parse(msg);
          const message = stripHtmlTags(parsedMsg.message || "");

          // Exclude specific messages you don't want to show
          if (
            message.includes("Newly created user") &&
            message.includes("has no roles enabled")
          ) {
            return false;
          }

          return true;
        });
        console.log(filteredMessages);

        errorMessage = filteredMessages
          .map((msg) => {
            const parsedMsg = JSON.parse(msg);
            const message = stripHtmlTags(parsedMsg.message || "");
            return message;
          })
          .join(" ");
      } else {
        errorMessage = error.message || error.title || error.toString();
      }
      console.log(errorMessage);

      if (
        error.response.data.message ===
        "First name and password cannot be the same."
      ) {
        errorMessage = "First name and password cannot be the same.";
      }
      if (errorMessage) {
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const options = getState.map((value) => ({
    label: value.region_name,
    value: value.region_name,
  }));

  const selectStateHandler = (selectedOption) => {
    setSelectState(selectedOption);
    formik.setFieldValue("region", selectedOption ? selectedOption.label : "");
  };

  useEffect(() => {
    const fetchStateData = async () => {
      try {
        const regions = await fetchRegions();
        setGetState(regions);
      } catch (error) {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(`Failed to fetch regions: ${errorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };

    fetchStateData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await formik.validateForm();
    formik.handleSubmit();
  };

  return (
    <section className="flex m-auto container-fluid w-full h-screen forgot-password-section signup-screen-section">
      <ToastContainer />
      <LoginLeft>
        <h1>Sign Up</h1>

        <form
          onSubmit={handleSubmit}
          noValidate
          className="space-y-4 md:space-y-6"
        >
          <div>
            <TextField
              label="Business Email"
              variant="outlined"
              type="email"
              name="email"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="off"
              required
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>
          <div>
            <TextField
              label="Business Phone"
              variant="outlined"
              type="text"
              name="phone"
              id="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              inputProps={{ maxLength: 10 }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </div>

          <div>
            <TextField
              label="Full Name"
              variant="outlined"
              type="text"
              name="first_name"
              id="first_name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
          </div>

          <div>
            <TextField
              label="Designation"
              variant="outlined"
              type="text"
              name="designation"
              id="designation"
              value={formik.values.designation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              error={
                formik.touched.designation && Boolean(formik.errors.designation)
              }
              helperText={
                formik.touched.designation && formik.errors.designation
              }
            />
          </div>
          <div>
            <TextField
              label="Company"
              variant="outlined"
              type="text"
              name="company_name"
              id="company_name"
              value={formik.values.company_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              error={
                formik.touched.company_name &&
                Boolean(formik.errors.company_name)
              }
              helperText={
                formik.touched.company_name && formik.errors.company_name
              }
            />
          </div>
          <div>
            <Select
              options={options}
              name="region"
              value={selectState}
              placeholder="Region *"
              onChange={selectStateHandler}
              onBlur={formik.handleBlur}
              className={
                formik.touched.region && formik.errors.region
                  ? "select-error"
                  : ""
              }
            />
            {formik.touched.region && formik.errors.region && (
              <div className="error">{formik.errors.region}</div>
            )}
          </div>

          <div>
            <TextField
              label="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              name="new_password" // Ensure this matches your initialValues key
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="new_password"
              value={formik.values.new_password}
              autoComplete="Password"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      className="password-eye"
                    >
                      {showPassword ? <AiFillEye /> : <AiOutlineEyeInvisible />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={
                formik.touched.new_password &&
                Boolean(formik.errors.new_password)
              }
              helperText={
                formik.touched.new_password && formik.errors.new_password
              }
            />
          </div>

          <button type="submit" disabled={loading} className="auth-btn">
            Sign Up
            {loading && (
              <span className="spinner-border spinner-border-sm">.....</span>
            )}
          </button>
          <p className="flex justify-center text-center auth-bottom-text">
            Already having an account?
            <Link to="/Login" className="signup-link">
              &nbsp;Log In
            </Link>
          </p>
        </form>
      </LoginLeft>
      <LoginRight />
    </section>
  );
};

export default SignUp;
