import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/Images/Logo.png";
const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    const ToggleButton = { isActive, toggleMenu };
    setIsActive(!isActive);
  };
  return (
    <>
      <header>
        <nav className="bg-white border-gray-200 px-4 lg:px-6 py-12 ">
          <div className="flex flex-wrap w-full justify-between items-center mx-auto container">
            <Link href="/" className="flex items-center">
              <img
                src={Logo}
                className="logo-main"
                alt="Logo"
              /> 
              <div
                className={`toggle-button ${isActive ? "active" : ""}`}
                onClick={toggleMenu}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
            </Link>
            <div className="flex items-center lg:order-2">
              <Link
                to="/Login"
                className="nav-login-btn"
              >
                Log in
              </Link>

              <button
                data-collapse-toggle="mobile-menu-2"
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 toggle-bg"
                aria-controls="mobile-menu-2"
                aria-expanded="false"
                onClick={toggleMenu}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <svg
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div
              className={`${
                isActive ? "block" : "hidden"
              } justify-between items-center w-full lg:flex lg:w-auto lg:order-1`}
              id="mobile-menu-2"
            >
              <ul className="top-nav">
                <li>
                  <Link to="/" aria-current="page">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/AboutUs">About Us</Link>
                </li>
                <li>
                  <Link to="/Carrer">Career</Link>
                </li>
                <li>
                  <Link to="/Contact">Contact Us</Link>
                </li>
                <li className="lg:hidden">
                  <Link to="/Login">Log in</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
