import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Logout = () => {
    const navigate=useNavigate()
    const handleLogout=()=>{
        sessionStorage.clear()
        navigate('/Login')
    }
    useEffect(()=>{
        handleLogout()
    })
  return (
    <div>logout</div>
  )
}

export default Logout