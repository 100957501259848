import React, { useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spin } from "antd";
import {
  approveDeleteRequest,
  deleteReviewRequest,
  getTypeOfReview,
  getUserCompany,
  submitExEmployeeReview,
  updateExEmployee,
  uploadFile,
  uploadProfile,
} from "../../../Services/authHeader";
import { Checkbox, Form, notification, Select } from "antd";
import { Option } from "antd/es/mentions";

const styles = {
  input: {
    opacity: "100%",
    position: "absolute",
  },
};

const AddExEmployee = ({ employee, onClose, onFormSubmit, isSuperadmin }) => {

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState({ fieldName: "", status: false });
  const [profileUploadloading, setprofileUploadloading] = useState(false);
  const [fileNames, setFileNames] = useState({
    file1: "",
    file2: "",
    file3: "",
    file4: "",
    file5: "",
  });
  const [isReleasedOnDisabled, setReleasedOnDisabled] = useState(true);
  const [isCustomReleasedOnDisabled, setCustomReleasedOnDisabled] =
    useState(true);
  const inputFileRef = useRef();
  const inputDocRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
  const imgRef = useRef();
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(true);
  const [hideEditButton, setHideEditButton] = useState(true);
  const getReviewID = sessionStorage.getItem("reviewID");
  const [isReview, setReview] = useState(false);
  const [editReview, setEditReview] = useState(false);
  const [selectID, setSelectID] = useState(
    employee?.type_of_review || undefined
  );
  const [emailError, setEmailError] = useState("");
  const [typeOfReviews, setTypeOfReviews] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(!employee);

  const [adharcard, setadharcard] = useState("")
  const [voterid, setvoterid] = useState("")
  const [pancard, setpancard] = useState("")
  const [passport, setpassport] = useState("")
  const [drivinglicense, setdrivinglicense] = useState("")
  const [imageURL, setImageURL] = useState("");
  const [roleprofile,setroleprofile]=useState({})
  
  const workflow_state=JSON.parse(sessionStorage.getItem("workflow_state"))
  const user = JSON.parse(sessionStorage.getItem("usr"));

  const fetchCompanyDetails = async (email) => {
    try {
    
      const res = await getUserCompany(email);
      if (res.status === 200) {
        setroleprofile(res.data.data)
      
      } else {
        console.log("Error fetching company name!");
      }
    } catch (error) {
      console.log("Error fetching company details:", error);
    }
  };

  useEffect(() => {
    if (user?.email) {
      fetchCompanyDetails(user?.email);
    }
  }, [user?.email]);

  const addExForm = Yup.object().shape({
    first_name: Yup.string().required("Please enter the first name"),
    last_name: Yup.string().required("Please enter the last name"),
    date_of_birth: Yup.string().required("Please enter DOB"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Please enter a valid phone number")
      .required("Please enter the phone number"),
    email: Yup.string().email("Invalid email"),
    review_details: Yup.string().required("Please add the review"),
    designation: Yup.string().required("Please enter the designation"),
    joined_on: Yup.string().required("Please enter the joining date"),
    released_on: Yup.string().required("Please enter the released date"),
    highest_education: Yup.string().required(
      "Please enter the highest education"
    ),

    schoolcollegeuniversity: Yup.string().required(
      "Please enter the school/college/institution"
    ),
    boarduniversity: Yup.string().required("Please enter the board/university"),
    city: Yup.string().required("Please enter the city"),
    state: Yup.string().required("Please enter the state"),
    year: Yup.string().max(4).required("Please enter the year"),
  });

  const handleJoinedOnChange = (e) => {
    const joinedDate = e.target.value;
    validateFormik.handleChange(e);

    if (joinedDate) {
      setReleasedOnDisabled(false);
    } else {
      setReleasedOnDisabled(true);
      validateFormik.setFieldValue("released_on", "");
    }
  };
  const handleCustomJoinedOnChange = (e) => {
    const customJoinedDate = e.target.value;
    validateFormik.handleChange(e);

    if (customJoinedDate) {
      setCustomReleasedOnDisabled(false);
    } else {
      setCustomReleasedOnDisabled(true);
      validateFormik.setFieldValue(
        "custom__last_known_company_released_on",
        ""
      ); // Clear Released On input
    }
  };

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const data = await getTypeOfReview();
        setTypeOfReviews(data.message.values);
      } catch (error) {
        console.error("Failed to fetch reviews:", error);
      }
    };

    fetchReviews();
  }, []);

  const validateFormik = useFormik({
    initialValues: {
      first_name: employee?.first_name || "",
      aadhar_card: employee?.aadhar_card || "",
      last_name: employee?.last_name || "",
      pan_card: employee?.pan_card || "",
      date_of_birth: employee?.date_of_birth || "",
      other_id_proof_number: employee?.other_id_proof_number || "",
      enter_id_type: employee?.enter_id_type || "",
      phone: employee?.phone || "",
      email: employee?.email || "",
      type_of_review: employee?.type_of_review || "",
      designation: employee?.designation || "",
      review_details: employee?.review_details || "",
      company_name: employee?.company_name || "",
      location: employee?.location || "",
      joined_on: employee?.joined_on || "",
      released_on: employee?.released_on || "",
      custom_previous_designation: employee?.custom_previous_designation || "",
      highest_education: employee?.highest_education || "",
      schoolcollegeuniversity: employee?.schoolcollegeuniversity || "",
      boarduniversity: employee?.boarduniversity || "",
      city: employee?.city || "",
      state: employee?.state || "",
      year: employee?.year || undefined,
      custom__last_known_company_joined_on:
        employee?.custom__last_known_company_joined_on || "",
      custom__last_known_company_released_on:
        employee?.custom__last_known_company_released_on || "",
    },
    validationSchema: addExForm,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      await handleSubmission(values);
    },
  });

  const handleSubmission = async (values) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      await submitExEmployeeReview(values);
      handleRemoveForm();
      toast.success("Review submitted successfully!");
      onClose();
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to submit review. Please try again.");
    } finally {
      setIsSubmitting(false); // Reset submitting flag
    }
  };

  const onFileChange = (event, fileKey) => {
    // const fileName = event.target.files[0]?.name || "";

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (readerEvt) => {
      let binaryString = readerEvt.target.result;
      const fileData = btoa(binaryString);
      CustomUploadFile({ fileName: file.name, fileData, fieldName: fileKey });
    };
    reader.readAsBinaryString(file);

    if (fileKey === "aadhar_card") {
      setLoading({ fieldName: "aadhar_card", status: true });
      setadharcard(file.name)
    } else if (fileKey === "voter_id") {
      setLoading({ fieldName: "voter_id", status: true });
      setvoterid(file.name)
    } else if (fileKey === "driving_license") {
      setLoading({ fieldName: "driving_license", status: true });
      setdrivinglicense(file.name)
    } else if (fileKey === "pan_card") {
      setLoading({ fieldName: "pan_card", status: true });
      setpancard(file.name)
    } else if (fileKey === "passport") {
      setLoading({ fieldName: "passport", status: true });
      setpassport(file.name)
    }

  };

  const CustomUploadFile = async ({ fileName, fileData, fieldName, fileKey }) => {

    const res = await uploadFile({
      fileName,
      fileData,
      doctype: "Ex Employee Reviews",
      docname: employee?.name,
      fieldName: fieldName
    });
    if (res.status === 200) {
      setFileNames((prevState) => ({
        ...prevState,
        [fileKey]: fileName,
      }));
      // setFlag(!flag);

      // setImageURL(
      //   `${res.data.message.file_url}`
      // );
    }
    setLoading({ fieldName: "", status: false });
  }

  const onFileChangeCapture = (e,filekey) => {
    setLoading({ fieldName: "user_image", status: true });
    const file = e.target.files[0];
    setFile(e.target.files);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (theFile) {
      var image = new Image();
      image.src = theFile.target.result;
      imgRef.current.src = image.src;

      let binaryString = theFile.target.result;
      const fileData = btoa(binaryString);
      CustomUploadProfilePicFile({ fileName: file.name, fileData, fieldName: filekey });
    };
    
  };


  const CustomUploadProfilePicFile = async ({ fileName, fileData, fieldName, fileKey }) => {

    const res = await uploadFile({
      fileName,
      fileData,
      doctype: "Ex Employee Reviews",
      docname: employee?.name,
      fieldName: fieldName
    });
    if (res.status === 200) {
      setFileNames((prevState) => ({
        ...prevState,
        [fileKey]: fileName,
      }));
 
    }
    setLoading({ fieldName: "", status: false });
  }

  const triggerFile = (elementRef) => {
    elementRef.current.click();
  };


  const handleRemove = () => {
    validateFormik.resetForm();
    setHideEditButton(true);
    setFile(null);
    setFileNames({
      file1: "",
      file2: "",
      file3: "",
      file4: "",
      file5: "",
    });
    setSelectID(undefined);
    setEmailError("");
  };

  const handleRemoveForm = () => {
    resetForm();
  };

  const handleClose = () => {
    onClose();
  };

  const resetForm = () => {
    validateFormik.resetForm();
    setIsEditing(false);
    setHideEditButton(true);
    setFile(null);
    setFileNames({
      file1: "",
      file2: "",
      file3: "",
      file4: "",
      file5: "",
    });
    setSelectID(undefined);
    setEmailError("");
  };

  const handleSave = async () => {
    const errors = await validateFormik.validateForm();

    if (Object.keys(errors).length > 0) {
      toast.error("Please correct the validation errors before saving.");
      return;
    }

    const payload = {
      data: {
        name: employee.name,
        first_name: validateFormik.values.first_name,
        aadhar_card: validateFormik.values.aadhar_card || "",
        last_name: validateFormik.values.last_name || "",
        pan_card: validateFormik.values.pan_card || "",
        date_of_birth: validateFormik.values.date_of_birth || "",
        other_id_proof_number:
          validateFormik.values.other_id_proof_number || "",
        enter_id_type: validateFormik.values.enter_id_type || "",
        phone: validateFormik.values.phone || "",
        email: validateFormik.values.email || "",
        designation: validateFormik.values.designation || "",
        review_details: validateFormik.values.review_details || "",
        company_name: validateFormik.values.company_name || "",
        location: validateFormik.values.location || "",
        joined_on: validateFormik.values.joined_on || "",
        released_on: validateFormik.values.released_on || "",
        custom_previous_designation:
          validateFormik.values.custom_previous_designation || "",
        highest_education: validateFormik.values.highest_education || "",
        schoolcollegeuniversity:
          validateFormik.values.schoolcollegeuniversity || "",
        boarduniversity: validateFormik.values.boarduniversity || "",
        city: validateFormik.values.city || "",
        state: validateFormik.values.state || "",
        year: validateFormik.values.year || undefined,
        doctype: "Ex Employee Reviews",
        type_of_review: selectID,
        custom__last_known_company_joined_on:
          validateFormik.values.custom__last_known_company_joined_on || "",
        custom_last_known_company_released_on:
          validateFormik.values.custom_last_known_company_released_on || "",
      },
    };

    try {
      const res = await updateExEmployee(payload, employee.name);
      if (res.status === 200) {
        toast.success("Employee data updated successfully!");
        onFormSubmit();
      } else {
        toast.error("Employee data update error!");
        onFormSubmit();
      }
    } catch (error) {
      console.error("Error updating employee:", error);
      toast.error("Failed to update employee data.");
    }
  };

  const handleEditToggleButton = () => {
    setHideEditButton(!hideEditButton);
    setEditReview(!editReview);
    setIsEditing(!isEditing);
  };

  const selectedIdHandler = (value) => {
    setSelectID(value);
  };

  // Helper function to generate year options
  const generateYearOptions = (startYear, endYear) => {
    let years = [];
    for (let year = endYear; year >= startYear; year--) {
      years.push({ value: year.toString(), label: year.toString() });
    }
    return years;
  };

  const yearOptions = generateYearOptions(1990, new Date().getFullYear());

  document.body.classList.add("ex-employee");

  const handleEmailChange = (e) => {
    validateFormik.handleChange(e);
    const email = e.target.value;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setEmailError("Invalid email");
    } else {
      setEmailError("");
    }
  };

  const handleDeleteRequest = async () => {
    const user = JSON.parse(sessionStorage.getItem("usr"));
    const requestCreator = user.email;

    const payload = {
      data: {
        reference_doctype: "Ex Employee Reviews",
        reference_name: employee.name,
        status: "Requested",
        request_creator: requestCreator,
        request_type: "Delete",
        details: "",
      },
    };

    try {
      const res = await deleteReviewRequest(payload);
      if (res.status === 200) {
        notification.success({
          message: "Review delete request Created",
        });
        handleClose();
      } else {
        notification.error({
          message: "Failed to create review delete request",
        });
      }
    } catch (error) {
      console.error("Error deleting review:", error);
      notification.error({
        message: "Error deleting review",
      });
    }
  };

  const ApproveDeleteRequest = async () => {
    const payload = {
      data: {
        name: employee.name,
        status: "Approved",
        // request_type: "Delete",
      },
    };
    try {
      const res = await approveDeleteRequest(payload);
      if (res.status === 200) {
        notification.success({
          message: "Review delete request approved",
        });
        handleClose();
      }
    } catch (error) {
      console.error("Error approving delete request:", error);
    }
  };

   
  // console.log("employeeemployee",roleprofile)
  return (
    <>
      <div className="add-review-form">
        <ToastContainer />
        <div className="">
          <div className="ex-employee-form">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                validateFormik.handleSubmit();
              }}
              action="#"
            >
              <div className="mt-2 sm:rounded-lg">
                {!employee && (
                  <h2 className="reviewheading">Ex-Employee Review</h2>
                )}
                {employee && (
                  <div className="review-id flex justify-between">
                    <span className="text-xl">
                      <p className="pb-4 font-bold">
                        Review ID-{employee.name}
                      </p>
                    </span>
                    {/* disabled={!workflow_state && !isEditing && roleprofile?.role_profile_name==="Semi-admin" ? false : true } */}
                    {hideEditButton && !isSuperadmin && roleprofile?.role_profile_name==="Semi-admin" && workflow_state  ? (
                      <div>
                        <button
                          type="button"
                          className="edited-btn ml-4"
                          onClick={() => {
                            handleDeleteRequest();
                          }}
                          disabled={!workflow_state}
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          className="edited-btn ml-4"
                          onClick={() => {
                            handleEditToggleButton();
                          }}
                         disabled={!workflow_state}
                        >
                          Edit
                        </button>
                      </div>
                    ) : (
                      <>
                        {editReview && (
                          <div className="flex">
                            <button
                              type="button"
                              className="cancelled-btn"
                              onClick={() => {
                                onClose();
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="saved-btn ml-4"
                              onClick={() => {
                                handleSave();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}

                <section class="w-full">
                  <div class="pr-0 mx-auto first-section">
                    <div class="lg:w-full mx-auto add-review-list">
                      <div class="photo-update mb-2">
                        <h3>Basic</h3>

                        <div
                          className="Product-thumbnail"
                          onClick={() => triggerFile(inputFileRef)}
                        >
                       <Spin spinning={loading.status && loading.fieldName==="user_image" ? true : false}>
                          <img
                            src="../img-placeholder.svg"
                            className="w-100"
                            ref={imgRef}
                            alt="Profile"
                          />
                        </Spin>

                        </div>

                        <input
                          type="file"
                          ref={inputFileRef}
                          accept="image/*"
                          style={styles.input}
                          disabled={!isEditing}
                          onChangeCapture={(e)=>onFileChangeCapture(e,"user_image")}

                        />
                      </div>
                      {/* Basic  */}
                      <div class="flex flex-wrap gap-4 field-item">
                        {/* first Name */}
                        <div class="field-container">
                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            value={validateFormik.values.first_name}
                            disabled={!isEditing}
                            onChange={validateFormik.handleChange}
                            placeholder="First Name"
                          />
                          <label htmlFor="first_name" className="placeholders">
                            First Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <p style={{ color: "red" }}>
                            {validateFormik.errors.first_name
                              ? validateFormik.errors.first_name
                              : ""}
                          </p>
                        </div>

                        {/* Add  Aadhar Document */}
                        <div class="field-container">
                          <div className="upload-container">
                            <input
                              type="text"
                              value={adharcard || employee?.aadhar_card}
                              readOnly
                              placeholder="Add Document (e.g. ID proof, Experience letter, Resume)"
                              className="text-input"
                            />
                            <label
                              htmlFor="fileInput1"
                              className="upload-button"
                            >
                              {loading?.status && loading.fieldName === "aadhar_card" ? "Uploading..." : "Upload"}
                            </label>

                            <input
                              type="file"
                              id="fileInput1"
                              disabled={!isEditing}
                              onChange={(e) => onFileChange(e, "aadhar_card")}
                              className="file-input"
                              ref={inputDocRefs[0]}
                            />
                          </div>
                        </div>

                        {/* Last Name */}
                        <div class="field-container">
                          <input
                            type="text"
                            name="last_name"
                            value={validateFormik.values.last_name}
                            // disabled={isReview ? true :false}
                            disabled={!isEditing}
                            onChange={validateFormik.handleChange}
                            placeholder="Last Name"
                          />
                          <label htmlFor="last_name" className="placeholders">
                            Last Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <p style={{ color: "red" }}>
                            {validateFormik.errors.last_name
                              ? validateFormik.errors.last_name
                              : ""}
                          </p>
                        </div>

                        {/* Add Pan Card Documet */}
                        <div class="field-container">
                          <div className="upload-container">
                            <input
                              type="text"
                              value={pancard || employee?.pan_card}
                              readOnly
                              placeholder="Add Document (e.g. ID proof, Experience letter, Resume)"
                              className="text-input"
                            />
                            <label
                              htmlFor="fileInput2"
                              className="upload-button"
                            >
                              {loading?.status && loading.fieldName === "pan_card" ? "Uploading..." : "Upload"}
                            </label>
                            <input
                              type="file"
                              id="fileInput2"
                              disabled={!isEditing}
                              onChange={(e) => onFileChange(e, "pan_card")}
                              className="file-input"
                              ref={inputDocRefs[1]}
                            />
                          </div>
                        </div>

                        {/* Add Dob */}
                        <div class="field-container">
                          <input
                            type="text"
                            name="date_of_birth"
                            value={validateFormik.values.date_of_birth}
                            // disabled={isReview ? true :false}
                            disabled={!isEditing}
                            onChange={validateFormik.handleChange}
                            placeholder="DOB"
                            onFocus={(e) => (e.target.type = "date")}
                            onBlur={(e) => (e.target.type = "text")}
                          />
                          <label
                            htmlFor="date_of_birth"
                            className="placeholders"
                          >
                            Date of Birth{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <p style={{ color: "red" }}>
                            {validateFormik.errors.date_of_birth
                              ? validateFormik.errors.date_of_birth
                              : ""}
                          </p>
                        </div>


                        {/* Voter ID */}
                        <div class="field-container">
                          <div className="upload-container">
                            <input
                              type="text"
                              name="file3"
                              value={voterid || employee?.voter_id}
                              readOnly
                              placeholder="Add Document (e.g. ID proof, Experience letter, Resume)"
                              className="text-input"
                            />
                            <label
                              htmlFor="fileInput3"
                              className="upload-button"
                            >
                              {loading?.status && loading.fieldName === "voter_id" ? "Uploading..." : "Upload"}
                            </label>
                            <input
                              type="file"
                              id="fileInput3"
                              disabled={!isEditing}
                              onChange={(e) => onFileChange(e, "voter_id")}
                              className="file-input"
                              ref={inputDocRefs[2]}
                            />
                          </div>
                        </div>

                        <div class="field-container">
                          <input
                            type="text"
                            name="designation"
                            value={validateFormik.values.designation}
                            disabled={!isEditing}
                            onChange={validateFormik.handleChange}
                            placeholder="Designation"
                          />
                          <label htmlFor="designation" className="placeholders">
                            Designation <span style={{ color: "red" }}>*</span>
                          </label>

                          <p style={{ color: "red" }}>
                            {validateFormik.errors.designation
                              ? validateFormik.errors.designation
                              : ""}
                          </p>
                        </div>

                        <div class="field-container">
                          <div className="upload-container">
                            <input
                              type="text"
                              name="file4"
                              value={drivinglicense || employee?.driving_license}
                              readOnly
                              placeholder="Add Document (e.g. ID proof, Experience letter, Resume)"
                              className="text-input"
                            />
                            <label
                              htmlFor="fileInput4"
                              className="upload-button"
                            >
                              {loading?.status && loading.fieldName === "driving_license" ? "Uploading..." : "Upload"}
                            </label>
                            <input
                              type="file"
                              id="fileInput4"
                              disabled={!isEditing}
                              onChange={(e) => onFileChange(e, "driving_license")}
                              className="file-input"
                              ref={inputDocRefs[3]}
                            />
                          </div>
                        </div>
                        <div class="flex field-container gap-4">
                          <div className="w-1/2 relative">
                            <input
                              type="text"
                              id="joined_on"
                              name="joined_on"
                              value={validateFormik.values.joined_on}
                              // disabled={isReview ? true :false}
                              disabled={!isEditing}
                              onChange={handleJoinedOnChange}
                              placeholder="Joined On"
                              onFocus={(e) => {
                                e.target.type = "date";
                                e.target.max = new Date()
                                  .toISOString()
                                  .split("T")[0];
                              }}
                              onBlur={(e) => (e.target.type = "text")}
                            />
                            <label htmlFor="joined_on" className="placeholders">
                              Joined On <span style={{ color: "red" }}>*</span>
                            </label>
                            <p style={{ color: "red" }}>
                              {validateFormik.errors.joined_on
                                ? validateFormik.errors.joined_on
                                : ""}
                            </p>
                          </div>
                          <div className="w-1/2 relative">
                            <input
                              type="text"
                              id="released_on"
                              name="released_on"
                              value={validateFormik.values.released_on}
                              // disabled={isReview ? true :false}
                              disabled={!isEditing || isReleasedOnDisabled}
                              onChange={validateFormik.handleChange}
                              placeholder="Released On"
                              onFocus={(e) => {
                                e.target.type = "date";
                                const joinedDate =
                                  validateFormik.values.joined_on;
                                if (joinedDate) {
                                  e.target.min = joinedDate; // Set min date to Joined On date
                                }
                              }}
                              onBlur={(e) => (e.target.type = "text")}
                            />
                            <label
                              htmlFor="released_on"
                              className="placeholders"
                            >
                              Released On
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <p style={{ color: "red" }}>
                              {validateFormik.errors.released_on
                                ? validateFormik.errors.released_on
                                : ""}
                            </p>
                          </div>
                        </div>

                        <div class="field-container">
                          <div className="upload-container">
                            <input
                              type="text"
                              value={passport || employee?.passport}
                              readOnly
                              placeholder="Add Document (e.g. ID proof, Experience letter, Resume)"
                              className="text-input"
                            />
                            <label
                              htmlFor="fileInput5"
                              className="upload-button"
                            >
                              {loading?.status && loading.fieldName === "passport" ? "Uploading..." : "Upload"}
                            </label>
                            <input
                              type="file"
                              id="fileInput5"
                              disabled={!isEditing}
                              onChange={(e) => onFileChange(e, "passport")}
                              className="file-input"
                              ref={inputDocRefs[4]}
                            />
                          </div>
                        </div>

                        <div class="flex flex-col field-container gap-4">
                          {/* <ul>
                            {typeOfReviews.map((review, index) => (
                              <li key={index}>{review[0]}</li>
                            ))}
                          </ul> */}
                          <Select
                            id="type_of_review"
                            name="type_of_review"
                            value={selectID}
                            placeholder="Type of review"
                            onChange={(value) => {
                              validateFormik.setFieldValue(
                                "type_of_review",
                                value
                              );
                              selectedIdHandler(value);
                            }}
                            disabled={!isEditing}
                          >
                            {typeOfReviews.map((review, index) => (
                              <Select.Option key={index} value={review[0]}>
                                {review[0]}
                              </Select.Option>
                            ))}
                          </Select>
                          <div className="relative">
                            <input
                              type="text"
                              id="phone"
                              name="phone"
                              value={validateFormik.values.phone}
                              // disabled={isReview ? true :false}
                              disabled={!isEditing}
                              onChange={validateFormik.handleChange}
                              placeholder="Phone"
                              inputMode="numeric"
                              pattern="\d*"
                              maxLength="10"
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                            />
                            <label htmlFor="phone" className="placeholders">
                              Phone <span style={{ color: "red" }}>*</span>
                            </label>
                            <p style={{ color: "red" }}>
                              {validateFormik.errors.phone
                                ? validateFormik.errors.phone
                                : ""}
                            </p>
                          </div>
                          <div className="relative">
                            <input
                              type="text"
                              id="email"
                              name="email"
                              value={employee?.email || validateFormik.values.email}
                              // disabled={isReview ? true :false}
                              disabled={!isEditing}
                              // onChange={validateFormik.handleChange}
                              onChange={handleEmailChange}
                              placeholder="Email"
                            />
                            <label htmlFor="email" className="placeholders">
                              Email
                            </label>
                            <p style={{ color: "red" }}>
                              {validateFormik.errors.email
                                ? validateFormik.errors.email
                                : emailError}
                            </p>
                          </div>
                        </div>
                        <div class="field-container">
                          <div class="relative h-full">
                            <textarea
                              type="text"
                              id="review_details"
                              name="review_details"
                              rows="5"
                              value={validateFormik.values.review_details}
                              // disabled={isReview ? true :false}
                              disabled={!isEditing}
                              onChange={validateFormik.handleChange}
                              placeholder="Review Details"
                            />
                            <label
                              htmlFor="review_details"
                              className="placeholders"
                            >
                              Review Details{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <p style={{ color: "red" }}>
                              {validateFormik.errors.review_details
                                ? validateFormik.errors.review_details
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* Last Known Company */}
              <div className="last-company ">
                <h3>Last known company</h3>
              </div>
              <div className="verflow-x-auto sm:rounded-lg ">
                <section class="text-gray-600 body-font relative first-section">
                  <div class="flex flex-wrap gap-4 field-item last-section-box">
                    <div class="field-container">
                      <input
                        type="text"
                        id="company_name"
                        name="company_name"
                        value={employee?.company_name || validateFormik.values.company_name}
                        // disabled={isReview ? true :false}
                        disabled={!isEditing}
                        onChange={validateFormik.handleChange}
                        placeholder="Company Name"
                      />
                      <label htmlFor="company_name" className="placeholders">
                        Company Name
                      </label>
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.company_name
                          ? validateFormik.errors.company_name
                          : ""}
                      </p>
                    </div>
                    <div class="field-container">
                      <input
                        type="text"
                        name="location"
                        value={validateFormik.values.location}
                        // disabled={isReview ? true :false}
                        disabled={!isEditing}
                        onChange={employee?.location || validateFormik.handleChange}
                        placeholder="Location"
                      />
                      <label htmlFor="location" className="placeholders">
                        Location
                      </label>
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.location
                          ? validateFormik.errors.location
                          : ""}
                      </p>
                    </div>

                    <div className="field-container">
                      <input
                        type="text"
                        name="custom_previous_designation"
                        value={
                          validateFormik.values.custom_previous_designation
                        }
                        disabled={!isEditing}
                        onChange={employee?.custom_previous_designation || validateFormik.handleChange}
                        placeholder="Previous Designation"
                      />
                      <label
                        htmlFor="custom_previous_designation"
                        className="placeholders"
                      >
                        Previous Designation
                      </label>
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.custom_previous_designation
                          ? validateFormik.errors.custom_previous_designation
                          : ""}
                      </p>
                    </div>
                    <div class="flex field-container gap-4 field-item">
                      <div className="relative w-1/2">
                        <input
                          type="text"
                          id="custom__last_known_company_joined_on"
                          name="custom__last_known_company_joined_on"
                          value={employee?.custom__last_known_company_joined_on ||
                            validateFormik.values
                              .custom__last_known_company_joined_on
                          }
                          // disabled={isReview ? true :false}
                          disabled={!isEditing}
                          onChange={handleCustomJoinedOnChange}
                          placeholder="Joined On"
                          onFocus={(e) => {
                            e.target.type = "date";
                            e.target.max = new Date()
                              .toISOString()
                              .split("T")[0];
                          }}
                          onBlur={(e) => (e.target.type = "text")}
                        />
                        <label htmlFor="joined_on" className="placeholders">
                          Joined On
                        </label>
                        <p style={{ color: "red" }}>
                          {validateFormik.errors
                            .custom__last_known_company_joined_on
                            ? validateFormik.errors
                              .custom__last_known_company_joined_on
                            : ""}
                        </p>
                      </div>
                      <div className="relative w-1/2">
                        <input
                          type="text"
                          id="custom__last_known_company_released_on"
                          name="custom__last_known_company_released_on"
                          value={employee?.custom__last_known_company_released_on ||
                            validateFormik.values
                              .custom__last_known_company_released_on
                          }
                          // disabled={isReview ? true :false}
                          disabled={!isEditing || isCustomReleasedOnDisabled}
                          onChange={validateFormik.handleChange}
                          placeholder="Released On"
                          onFocus={(e) => {
                            e.target.type = "date";
                            const customJoinedDate =
                              validateFormik.values
                                .custom__last_known_company_joined_on;
                            if (customJoinedDate) {
                              e.target.min = customJoinedDate;
                            }
                          }}
                          onBlur={(e) => (e.target.type = "text")}
                        />
                        <label
                          htmlFor="custom__last_known_company_released_on"
                          className="placeholders"
                        >
                          Released On
                        </label>
                        <p style={{ color: "red" }}>
                          {validateFormik.errors
                            .custom__last_known_company_released_on
                            ? validateFormik.errors
                              .custom__last_known_company_released_on
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* Education field */}
              <div className="education">
                <h3>Education</h3>
              </div>

              <div className="verflow-x-auto sm:rounded-lg">
                <section class="text-gray-600 body-font">
                  <div class="flex flex-wrap gap-4 field-item first-section education-section">
                    <div class="field-container">
                      <input
                        type="text"
                        id="highest_education"
                        name="highest_education"
                        value={validateFormik.values.highest_education}
                        // disabled={isReview ? true :false}
                        disabled={!isEditing}
                        onChange={validateFormik.handleChange}
                        placeholder="Highest Education"
                      />
                      <label
                        htmlFor="highest_education"
                        className="placeholders"
                      >
                        Highest Education
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.highest_education
                          ? validateFormik.errors.highest_education
                          : ""}
                      </p>
                    </div>
                    <div class="field-container">
                      <input
                        type="text"
                        id="schoolcollegeuniversity"
                        name="schoolcollegeuniversity"
                        value={validateFormik.values.schoolcollegeuniversity}
                        // disabled={isReview ? true :false}
                        disabled={!isEditing}
                        onChange={validateFormik.handleChange}
                        placeholder="School/College/Institution"
                      />
                      <label
                        htmlFor="schoolcollegeuniversity"
                        className="placeholders"
                      >
                        School/College/Institution{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.schoolcollegeuniversity
                          ? validateFormik.errors.schoolcollegeuniversity
                          : ""}
                      </p>
                    </div>
                    <div class="field-container">
                      <input
                        type="text"
                        id="boarduniversity"
                        name="boarduniversity"
                        value={validateFormik.values.boarduniversity}
                        // disabled={isReview ? true :false}
                        disabled={!isEditing}
                        onChange={validateFormik.handleChange}
                        placeholder="Board/University"
                      />
                      <label htmlFor="boarduniversity" className="placeholders">
                        Board/University <span style={{ color: "red" }}>*</span>
                      </label>
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.boarduniversity
                          ? validateFormik.errors.boarduniversity
                          : ""}
                      </p>
                    </div>

                    <div class="flex field-container gap-4">
                      <div className="relative w-1/2 ">
                        <input
                          type="text"
                          id="city"
                          name="city"
                          value={validateFormik.values.city}
                          onChange={validateFormik.handleChange}
                          // disabled={isReview ? true :false}
                          disabled={!isEditing}
                          placeholder="City"
                        />
                        <label htmlFor="city" className="placeholders">
                          City <span style={{ color: "red" }}>*</span>
                        </label>
                        <p style={{ color: "red" }}>
                          {validateFormik.errors.city
                            ? validateFormik.errors.city
                            : ""}
                        </p>
                      </div>
                      <div className="relative w-1/2">
                        <input
                          type="text"
                          id="state"
                          name="state"
                          value={validateFormik.values.state}
                          onChange={validateFormik.handleChange}
                          // disabled={isReview ? true :false}
                          disabled={!isEditing}
                          placeholder="State"
                        />
                        <label htmlFor="state" className="placeholders">
                          State <span style={{ color: "red" }}>*</span>
                        </label>
                        <p style={{ color: "red" }}>
                          {validateFormik.errors.state
                            ? validateFormik.errors.state
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="field-container">
                      <Select
                        id="year"
                        name="year"
                        value={validateFormik.values.year}
                        onChange={(value) =>
                          validateFormik.setFieldValue("year", value)
                        }
                        disabled={!isEditing}
                        placeholder={<>Year <span style={{ color: "red" }}>*</span></>}
                        style={{ width: "100%" }}
                        required
                      >
                        <label htmlFor="year" className="placeholders">
                          Year <span style={{ color: "red" }}>*</span>
                        </label>
                        {yearOptions.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.year
                          ? validateFormik.errors.year
                          : ""}
                      </p>
                    </div>
                  </div>
                </section>
              </div>

              {/*footer*/}

              {!employee || isSuperadmin ? (
                <>
                  <Checkbox>Disable</Checkbox>
                  <div className="flex items-center justify-end px-9 pb-8 review-bottom-btns">
                    {isSuperadmin ? (
                      // If the user is Superadmin, show Delete and Cancel buttons
                      <>

                        <button
                          type="button"
                          className="edited-btn ml-4"
                          onClick={() => {
                            ApproveDeleteRequest();
                          }}
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          className="cancelled-btn ml-4"
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      // If not Superadmin and employee is falsy, show Reset and Submit buttons
                      <>
                        <button
                          type="button"
                          className="reset-button"
                          onClick={() => {
                            handleRemove();
                          }}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          disabled={loading}
                          className="dark-mode submit-btn"
                        >
                          <span>Submit</span>
                          {loading && (
                            <span className="spinner-border spinner-border-sm">
                              .....
                            </span>
                          )}
                        </button>
                      </>
                    )}
                  </div>
                </>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddExEmployee;
