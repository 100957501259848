import React, { useEffect, useState } from 'react'

const Dummy = () => {
  const[getData,setGetData]=useState([])
  const getAtroData=async()=>{
    const url="https://admin.bypasslines.com/api/wp_merchant_list"
    try{
      const getuserData=await fetch(url)
      const fetchData=await getuserData.json()
      setGetData(fetchData)
    }catch(error){
      console.log('user',getData)
    }
  }
  useEffect(()=>{
    getAtroData()
  },[])
  return (
    <div>dummy</div>
  )
}

export default Dummy