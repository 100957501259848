import React, { useEffect, useState } from "react";
import NavbarDashboard from "../../../Widgets/SuperAdminDashboardNavbar";
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthHeader, { getSubscriptionListForSuperAdmin } from "../../../Services/authHeader";
import { useNavigate } from "react-router-dom";
import User from "../../../Assets/Images/user-icon.png";
import Image from "../../../Assets/Images/close.png";
import Adminusericon from "../../../Assets/Images/admin-user-icon.png";
import Prizes from "../../../Assets/Images/prize.png";
import { Link } from "react-router-dom";
import { MdNavigateNext } from "react-icons/md";
import { Layout, Table } from "antd";
import Sider from "antd/es/layout/Sider";
import SuperAdminSideNavbar from "../../../Widgets/SuperAdminDashboardNavbar";
import SuperAdminNav from "../../../Widgets/SuperAdminNavbar";
import { Content } from "antd/es/layout/layout";
import { fetchSubscriptionTransactionList } from "../../../Services/superadminService";

const Approve = () => {
  const navigate = useNavigate();
  const [showbutton, setShowButton] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpen2, setIsOpen2] = React.useState(false);
  const [recentSubscriptions, setRecentSubscriptions] = useState([]);

  const addUser = Yup.object().shape({
    email: Yup.string().required("Please enter your email"),
    first_name: Yup.string().required("Enter your first name"),
    phone: Yup.string().required("Please enter your mobile number"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      phone: "",
    },
    validationSchema: addUser,
    onSubmit: (values) => {
      addReviewUser(values);
      navigate("/data");
    },
  });
  async function addReviewUser(values) {
    try {
      await AuthHeader.UserAdd(values.email, values.first_name, values.phone);
    } catch (error) {
      console.log("error", error);
    }
  }
  const handleClose = () => {
    setIsOpen(false);
    document.getElementById("dark-mode-on").classList.remove("dark-mode-on");
  };
  const handleClose2 = () => {
    setIsOpen2(false);
    document.getElementById("dark-mode-on").classList.remove("dark-mode-on");
  };
  const handleCloseButton = () => {
    setShowButton(true);
  };
  document.body.classList.add("reports-page");
  document.body.classList.remove(
    "dashboard-page",
    "profile-page",
    "billing-page",
    "search-page",
    "users-page",
    "companyreportsave-page",
    "review-page",
    "approve-page",
    "issue-page",
    "deletion-page"
  );

  const formatDateToDDMMYYYY = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const data = await getSubscriptionListForSuperAdmin();
        console.log(data,"ppppppp")
        // const formattedSubscriptions = data.map((subscription) => ({
        //   ...subscription,
        //   creation: formatDateToDDMMYYYY(subscription.creation), // Format date
        // }));
        // setRecentSubscriptions(formattedSubscriptions);
      } catch (error) {
        console.error("Error fetching subscription transaction data:", error);
      }
    };

    fetchSubscriptions();
  }, []);

  const subscriptionColumns = [
    { title: "Company", dataIndex: "company", key: "company" },
    { title: "ID", dataIndex: "name", key: "name" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Date", dataIndex: "creation", key: "creation" },
  ];

  return (
    <>
      <div id="dark-mode-on"></div>
      <Layout className="h-full">
        <Sider width={256} className="site-layout-background">
          <SuperAdminSideNavbar />
        </Sider>
        <Layout className="panel-right">
          <SuperAdminNav />
          <Content className="dashboard-layout">
            {/* Modal for button */}
            {/* <div class=" w-auto  ">
              <div class="mr-10 search-margin">
                <div class="flex justify-between mb-8 md:grid-cols-2 gap-4 search-flex">
                  <div className="w-1/6">
                    <input
                      type="text"
                      id="first_name"
                      name="start_date"
                      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Start Date"
                    />
                  </div>
                  <div className="w-1/6">
                    <input
                      type="text"
                      id="end_date"
                      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="End Date"
                      name="End Date"
                    />
                  </div>
                  <div className="w-1/6">
                    <input
                      type="text"
                      id="Designation"
                      name="Designation"
                      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Designation"
                    />
                  </div>
                  <div className="w-1/6">
                    <input
                      type="text"
                      id="Industry"
                      name="Industry"
                      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Industry"
                    />
                  </div>
                  <div class="pr-0">
                    <button
                      class="text-white search-btn bg-gray-800 border border-gray-200 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      type="button"
                    >
                      Search
                    </button>
                  </div>
                  <div class="pr-0">
                    <button
                      class="more-filter-btn  text-black bg-white border border-gray-200 hover:bg-white focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      type="button"
                      onClick={() => {
                        setIsOpen2(true);
                        document
                          .getElementById("dark-mode-on")
                          .classList.add("dark-mode-on");
                      }}
                    >
                      <svg
                        className="w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        id="filter"
                      >
                        <path d="M2 7h.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2H9.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2zm4-3a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm24 11h-.142a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h20.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-4 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm4 7H19.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h10.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-14 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"></path>
                      </svg>
                      &nbsp; More Filter
                      <svg
                        class="w-4 h-4 ml-2"
                        aria-hidden="true"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="black">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          ></path>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="verflow-x-auto approve-table-div mr-10 shadow-md border border-gray-300 sm:rounded-lg">
                <table class="w-full approve-table text-sm text-left text-gray-500 dark:text-gray-400">
                  <caption class="caption-top top-border p-1 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                    <div class="container1 mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                      <span class="ml-0 text-xl search-result">
                        Designation Wise Reviews report
                      </span>
                      <div class="md:ml-auto flex flex-wrap items-center text-base justify-center pr-0">
                        <button class="bg-gray-800 hover: text-white font-base py-2 px-4 rounded">
                          Download
                        </button>
                      </div>
                    </div>
                  </caption>
                  <thead class="text-xs text-gray-700 uppercase search-table dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                        Designation
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Industry
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Total Reviews
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="px-6 py-3">Intern</td>
                      <td class="px-6 py-3">Chemical</td>
                      <td class="px-6 py-3">1000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="container1 mx-auto flex flex-wrap p-8 flex-col md:flex-row items-center pr-0 pb-0 mr-10 approve-next-div">
                <div class="md:ml-auto flex flex-wrap items-center text-base justify-center pr-0">
                  <p className="mr-3">Next</p>
                  <button
                    // onClick={nextPage}
                    class="bg-gray-800 hover: text-white font-base py-2 px-4 rounded"
                  >
                    <MdNavigateNext />
                  </button>
                </div>
              </div>
            </div> */}

            {isOpen && (
              <div className="fixed top-0 right-0 h-full w-1/5 h-1446px p-4 bg-gray-50 popup-form popup-form-dashboard">
                <button
                  className="absolute top-4 right-4 p-2 text-gray-600"
                  onClick={handleClose}
                >
                  <img src={Image} alt="abc"></img>
                </button>
                <form onSubmit={formik.handleSubmit}>
                  <div className="box pl-8 pr-6">
                    <h1 className=" mb-1  text-lg font-bold">Add User</h1>
                    <p className="add-user-popup pb-6">Add New User</p>
                  </div>
                  <hr className="hrLine pb-6" />
                  {/* Form fields */}
                  <div class="mt-1 pl-8 pr-6">
                    <input
                      type={"email"}
                      name="email"
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
                    />
                    <p style={{ color: "red" }} class="required-text">
                      {formik.errors.email ? formik.errors.email : ""}
                    </p>
                    &nbsp;
                  </div>
                  <div class="mt-1 pl-8 pr-6">
                    <input
                      type={"text"}
                      name="first_name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      placeholder="Name"
                      className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
                    />
                    <p style={{ color: "red" }} class="required-text">
                      {formik.errors.first_name ? formik.errors.first_name : ""}
                    </p>
                    &nbsp;
                  </div>
                  <div class="mt-1 mb-1 pl-8 pr-6">
                    <input
                      type="text"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      placeholder="Phone"
                      className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
                    />
                    <p style={{ color: "red" }} class="required-text">
                      {formik.errors.phone ? formik.errors.phone : ""}
                    </p>
                    &nbsp;
                  </div>
                  {/* <div class="absolute bottom-8 ">
         <hr className="hrLine" />

         </div> */}

                  {/* Add more form fields as needed */}
                  <div className="flex items-center justify-between pl-8 pr-6 rounded-b">
                    <button
                      className="reset-btn absolute bottom-6 left-8 m-0 font-bold  text-sm px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleClose}
                    >
                      Reset
                    </button>

                    <button
                      className="bg-black absolute bottom-6 right-6 m-0 text-white font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            )}
            {isOpen2 && (
              <div className="fixed top-0 right-0 h-full w-1/5 h-1446px p-4 bg-gray-50 popup-form popup-form-dashboard">
                <button
                  className="absolute top-4 right-4 p-2 text-gray-600"
                  onClick={handleClose2}
                >
                  <img src={Image} alt="abc"></img>
                </button>
                <form onSubmit={formik.handleSubmit}>
                  <div className="box pl-8 pr-6">
                    <h1 className=" mb-1  text-lg font-bold">Filters</h1>
                    <p className="add-user-popup pb-6">Advance Search</p>
                  </div>
                  <hr className="hrLine pb-6" />
                  {/* Form fields */}
                  <div class="mt-1 pl-8 pr-6">
                    <p class="searched-text">Search</p>
                    <input
                      type={"email"}
                      name="email"
                      placeholder="Created By"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
                    />
                    <p style={{ color: "red" }} class="required-text">
                      {formik.errors.email ? formik.errors.email : ""}
                    </p>
                    &nbsp;
                  </div>
                  <div class="mt-1 pl-8 pr-6">
                    <input
                      type={"text"}
                      name="first_name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      placeholder="Date"
                      className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
                    />
                    <p style={{ color: "red" }} class="required-text">
                      {formik.errors.first_name ? formik.errors.first_name : ""}
                    </p>
                    &nbsp;
                  </div>
                  <div class="mt-1 mb-1 pl-8 pr-6">
                    <input
                      type="text"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      placeholder="Status"
                      className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
                    />
                    <p style={{ color: "red" }} class="required-text">
                      {formik.errors.phone ? formik.errors.phone : ""}
                    </p>
                    &nbsp;
                  </div>
                  {/* <div class="absolute bottom-8 ">
         <hr className="hrLine" />

         </div> */}

                  {/* Add more form fields as needed */}
                  <div className="flex items-center justify-between pl-8 pr-6 rounded-b">
                    {/* <button
                  className="reset-btn absolute bottom-6 left-8 m-0 font-bold  text-sm px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleClose}
                >
                  Reset
                </button> */}

                    <button
                      className="bg-black absolute bottom-6 right-6 m-0 text-white font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            )}
            <div className="dashboard-row">
              <div className="dashboard-blocks">
                <div className="dashboard-header">
                  <h2>Subscriptions</h2>
                </div>
                <Table
                  dataSource={recentSubscriptions}
                  columns={subscriptionColumns}
                  pagination={{
                    defaultPageSize: 7,
                    pageSizeOptions: ["7", "14", "21"],
                    showSizeChanger: true,
                  }}
                />
                {/* <Link to="/SuperAdmin/Billing" className="view-all">
                  View All
                </Link> */}
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default Approve;
