import React from "react";
import Navbar from "../../Widgets/navbar";
const aboutUs = () => {
  return (
    <>
      <Navbar />
      <div className="container-fluid m-auto about-us-page">
        <h1 className="banner-style">About Us</h1>
        <div className=" container m-auto about-us-box">
          <section className="mb-8">
            <p>
              Second Judge is our effort to bring transparency, ethical
              practices, integrity and helping organizations taking right hiring
              decisions and avoid possible losses.
            </p>
            <p>
              We understand the importance of human relationships and the impact
              they have on businesses and individuals alike. With the rising
              instances of fraud and deception, it has become increasingly
              important for businesses to prioritize thorough employee
              screenings to safeguard their organization’s reputation and
              financial interests. Second Judge is a collective effort of all
              the users who sign up to help each other in making the right
              hiring decision.
            </p>
            <p>
              In the world of changing technologies, our decisions are based on
              data that’s presented to us. The recruitment process of entry
              level to a senior level position is mostly based on the data
              presented to us and the data which has been collected. Some of the
              facts are either hidden or manipulated by the incumbents to get
              hired.
            </p>
            <p>
              Second Judge is a platform where employers who could have hired or
              who had hired an employee can come online and leave reviews about
              such candidates who have caused a business loss to them. The
              discrete review process gives employers confidence to post reviews
              without hesitations and the unique point based systems helps them
              in searching potential candidates who they want to hire. In case
              if they don’t find the candidate details on our portal then they
              can go for Back Ground Verification services of the candidates.
            </p>
            <p className="font-semibold text-lg mt-3">
              Second Judge is owned and operated by Zeros and Ones Consulting
              Services.
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default aboutUs;
