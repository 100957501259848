import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import * as Yup from "yup";
import { useFormik } from "formik";
import Axios from "../../Services/commonHttp";
import LoginRight from "./LoginRight";
import LoginLeft from "./LoginLeft";
import { ToastContainer } from "react-toastify";

const NewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [alertMssg, setAlertMssg] = useState(undefined);
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validateNewPassword = Yup.object().shape({ 
    new_password: Yup.string().required("Please enter new password"),
    confirm_password: Yup.string()
      .required("Please enter your confirm password")
      .oneOf([Yup.ref("new_password")], "The passwords do not match"),
  });

  const formik = useFormik({
    initialValues: { 
      new_password: "",
      confirm_password: "",
    },
    validationSchema: validateNewPassword,
    onSubmit: (values) => {
      createPassword(values);
    },
  });

  async function createPassword(values) {
    try {
      await Axios({
        method: "POST",
        url: "/api/method/watcherp.customizations.user.create_new_password",
        data: { 
          confirm_password: values.new_password,
          new_password: values.new_password,
          email: email,
        },
      })
        .then((response) => {
          setAlertMssg({ type: "Success" });
          if (response.status === 200) {
            navigate(`/Login`);
          }
        })
        .catch((error) => {
          navigate("/Newpassword");
          setAlertMssg({ type: "Error" });
          console.log("error", error);
        });
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <section className="flex m-auto container-fluid w-full h-screen forgot-password-section">
      <ToastContainer />
      <LoginLeft>
        <h1>Create New Password?</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="space-y-4 md:space-y-6"
          action="#"
          noValidate
        >
          <div>
            <TextField
              label="New Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              name="new_password"
              value={formik.values.new_password}
              onChange={formik.handleChange}
              id="new_password"
              placeholder="New Password"
              required=""
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword ? <AiFillEye /> : <AiOutlineEyeInvisible />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <p style={{ color: "red" }}>
              {formik.errors.new_password ? formik.errors.new_password : ""}
            </p>
          </div>
          <div>
            <TextField
              label="Confirm Password"
              variant="outlined"
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              id="confirm_password"
              placeholder="Confirm Password"
              required=""
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowConfirmPassword}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showConfirmPassword ? (
                        <AiFillEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <p style={{ color: "red" }}>
              {formik.errors.confirm_password
                ? formik.errors.confirm_password
                : ""}
            </p>
          </div>
          <button type="submit" className="auth-btn">
            Update Password
          </button>
        </form>
      </LoginLeft>

      <LoginRight />
    </section>
  );
};

export default NewPassword;
