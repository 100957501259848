import React, { useEffect, useState } from "react";
import AuthHeader, { contactUs, fetchRegions } from "../../Services/authHeader";
import { useNavigate } from "react-router-dom";
import { TextField } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import LoginLeft from "../AdminAuth/LoginLeft";
import LoginRight from "../AdminAuth/LoginRight";
import { toast, ToastContainer } from "react-toastify";
import { Input } from 'antd';
const { TextArea } = Input;

const Contact = () => {
  const navigate = useNavigate();
  const [selectState, setSelectState] = useState(null);
  const [getState, setGetState] = useState([]);

  const contactUsValidation = Yup.object().shape({
    email: Yup.string()
      .required("Please enter your email")
      .email("Please enter a valid email address")
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        "Please enter a valid email address"
      ),
    phone: Yup.string()
      .required("Please enter your phone number")
      .matches(/^[0-9]+$/, "Phone number must be digits only")
      .min(10, "Phone number must be exactly 10 digits")
      .max(10, "Phone number must be exactly 10 digits"),
    first_name: Yup.string().required("Please enter your full name"),
    region: Yup.string().required("Please enter your region"),
    textarea: Yup.string().required("Please enter your details"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      first_name: "",
      region: "",
      textarea: "",
    },
    validationSchema: contactUsValidation,
    onSubmit: (values) => {
      values.region = selectState ? selectState.label : "";
      submitContactUsForm(values);
    },
  });

  async function submitContactUsForm(values) {
    try {
      await contactUs(
        values.first_name,
        values.email,
        values.phone,
        values.region,
        values.textarea
      );
      toast.success("Contact form submitted successfully.", {
        autoClose: 3000, // Show the message for 3 seconds
      });
      // Clear the form fields
      formik.resetForm();
      setSelectState(null);
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to submit contact form. Please try again.");
    }
  }

  const options = getState.map((value) => ({
    label: value.region_name,
    value: value.region_name,
  }));

  const selectStateHandler = (selectedOption) => {
    setSelectState(selectedOption);
    formik.setFieldValue("region", selectedOption ? selectedOption.label : "");
  };

  useEffect(() => {
    const fetchStateData = async () => {
      try {
        const regions = await fetchRegions();
        setGetState(regions);
      } catch (error) {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(`Failed to fetch regions: ${errorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };

    fetchStateData();
  }, []);

  return (
    <>
      <section className="flex m-auto container-fluid w-full h-screen login-section">
        <LoginLeft>
          <div className="md:mx-6 contact-div">
            <h1>Contact Us</h1>
            <form
              onSubmit={formik.handleSubmit}
              className="space-y-4 md:space-y-6"
              noValidate
            >
              <div>
                <label htmlFor="first_name"></label>
                <TextField
                  label="Full Name"
                  variant="outlined"
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  error={
                    formik.touched.first_name &&
                    Boolean(formik.errors.first_name)
                  }
                  helperText={
                    formik.touched.first_name && formik.errors.first_name
                  }
                />
              </div>
              <div>
                <label htmlFor="email"></label>
                <TextField
                  label="Business Email"
                  variant="outlined"
                  type="email"
                  name="email"
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              <div>
                <label htmlFor="phone"></label>
                <TextField
                  label="Business Phone"
                  variant="outlined"
                  type="text"
                  name="phone"
                  id="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  inputProps={{ maxLength: 10 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </div>
              <div>
                <label htmlFor="region"></label>
                <Select
                  options={options}
                  name="region"
                  value={selectState}
                  placeholder="Region *"
                  onChange={selectStateHandler}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.region && formik.errors.region
                      ? "select-error"
                      : ""
                  }
                />
                {formik.touched.region && formik.errors.region && (
                  <div className="error">{formik.errors.region}</div>
                )}
              </div>
              <div>
                <label htmlFor="textarea"></label>
                <TextArea
                  name="textarea"
                  placeholder="Details"
                  id="textarea"
                  value={formik.values.textarea}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <div className="error">
                  {formik.touched.textarea && formik.errors.textarea
                    ? formik.errors.textarea
                    : ""}
                </div>
              </div>
              <button type="submit" className="auth-btn">
                Submit
              </button>
            </form>
          </div>
        </LoginLeft>
        <LoginRight />
        <ToastContainer />
      </section>
    </>
  );
};

export default Contact;
