import React from "react";

import FormImg from "../../Assets/Images/login-right-img.png";
function LoginRight() {
  return (
    <div className="flex items-center justify-end image-side">
      <p>
        “Jeavio provides consistent product development and customer support is
        really good throughout. They are always improving and developing with
        us.”
      </p>
      <p className="name-text">
        Jack Poal <br />
        <span>Senior Support Engineer</span>
      </p>
      <img src={FormImg} className="second-judge-banner" alt="Flowbite Logo" />
    </div>
  );
}

export default LoginRight;
