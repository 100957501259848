import React, { useState } from "react";
import NavbarDashboard from "../../Widgets/SuperAdminDashboardNavbar";
// import User from "../../Assets/Images/user.png";
import * as Yup from "yup";
import { useFormik } from "formik";
// import Graph from "./graph";
import AuthHeader from "../../Services/authHeader";
import { useNavigate } from "react-router-dom";
import User from "../../Assets/Images/user-icon.png";
import Image from "../../Assets/Images/close.png";
import Adminusericon from "../../Assets/Images/admin-user-icon.png";
// import Prizes from '../../Assets/Images/prize.png'
import Select from "react-select";

const Approve = () => {
  const navigate = useNavigate();
  const [showbutton, setShowButton] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpen2, setIsOpen2] = React.useState(false);
  const [tabType, setTabType] = useState("exEmployee");
  const [selectID, setSelectID] = useState("");

  const addUser = Yup.object().shape({
    email: Yup.string().required("Please enter your email"),
    first_name: Yup.string().required("Enter your first name"),
    phone: Yup.string().required("Please enter your mobile number"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      phone: "",
    },
    validationSchema: addUser,
    onSubmit: (values) => {
      addReviewUser(values);
      navigate("/data");
    },
  });
  async function addReviewUser(values) {
    try {
      await AuthHeader.UserAdd(values.email, values.first_name, values.phone);
    } catch (error) {
      console.log("error", error);
    }
  }
  const handleClose = () => {
    setIsOpen(false);
    document.getElementById("dark-mode-on").classList.remove("dark-mode-on");
  };
  const handleClose2 = () => {
    setIsOpen2(false);
    document.getElementById("dark-mode-on").classList.remove("dark-mode-on");
  };
  const handleCloseButton = () => {
    setShowButton(true);
  };
  const getSelectedID = [
    { label: "Aadhar Card", value: 355 },
    { label: "PAN Card", value: 54 },
    { label: "Voter Id ", value: 43 },
    { label: "Passport Id ", value: 43 },
  ];
  const slectedIdHanlder = (value) => {
    setSelectID(value);
  };

  return (
    <>
      <div id="dark-mode-on"></div>

      <h2 className="submission-id">Submission Id</h2>

      {/* Modal for button */}
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-7">
        <section class="text-gray-600 body-font relative mx-auto">
          <div class="mr-10">
            <div class="lg:w-full md:w-2/3 mx-auto">
              <div class="flex flex-wrap -m-2">
                <div class="p-2 pb-4 pt-4 w-1/2">
                  <div class="relative">
                    <input
                      type="text"
                      name="First Name"
                      placeholder="First Name"
                      class="w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div class="p-2 w-1/2 pt-4 pb-4">
                  <div class="relative">
                    <div class="flex">
                      <input
                        type="text"
                        name="Enter Voter ID No"
                        placeholder="Enter Voter ID No"
                        class="w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      />
                      <button class="upload-btn">Upload</button>
                    </div>
                  </div>
                </div>
                <div class="p-2 pb-4 pt-4 w-1/2">
                  <div class="relative">
                    <input
                      type="text"
                      name="Last Name"
                      placeholder="Last Name"
                      class="w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div class="p-2 w-1/2 pt-4 pb-4">
                  <div class="relative">
                    <div class="flex">
                      <input
                        type="text"
                        name="Enter D.L No"
                        placeholder="Enter D.L No"
                        class="w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      />
                      <button class="upload-btn">Upload</button>
                    </div>
                  </div>
                </div>
                <div class="p-2 pb-4 pt-4 w-1/2">
                  <div class="relative">
                    <input
                      type="text"
                      name="date_of_birth"
                      placeholder="DOB"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      class="w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div class="p-2 pb-4 pt-4 w-1/2">
                  <div class="relative">
                    <input
                      type="text"
                      name="Designation"
                      placeholder="Designation"
                      class="w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>

                <div class="p-2 pb-4 pt-4 w-1/2">
                  <div class="relative">
                    <input
                      type="text"
                      name="Region"
                      placeholder="Region"
                      class="w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>

                <div class="flex w-1/2">
                  <div className="relative pr-2 pl-2 pt-4 pb-4 w-1/2">
                    <input
                      type="text"
                      id="email"
                      name="joined_on"
                      placeholder="Joined On"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      class="w-full bg-white bg-opacity-50 mr-5 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                  <div className="relative w-1/2 pt-4 pb-4 pr-2">
                    <input
                      type="text"
                      id="email"
                      name="released_on"
                      placeholder="Released On"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      class="w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div class="p-2 w-1/2 pt-4 pb-4">
                  <div class="relative">
                    <div class="flex">
                      <input
                        type="text"
                        name="Add_Document"
                        placeholder="Document"
                        class="w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      />
                      {/* <button class="upload-btn">Upload</button> */}
                    </div>
                  </div>
                </div>
                <div class="p-2 pb-4 pt-4 w-1/2 ">
                  <div class="relative">
                    <Select
                      options={getSelectedID}
                      value={selectID}
                      placeholder="Type of review"
                      onChange={slectedIdHanlder}
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                    />
                  </div>
                </div>

                <div class="p-2 pb-4 pt-4 w-1/2 ">
                  <div class="relative">
                    <div className="flex">
                      <input
                        type="text"
                        name="Add_Document"
                        placeholder="Document"
                        class="w-full bg-white mt-0 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      />
                      {/* <button class="upload-btn height-42">Upload</button> */}
                    </div>
                    <div className="flex">
                      <input
                        type="text"
                        name="Add_Document"
                        placeholder="Document"
                        class="w-full bg-white mt-6 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      />
                      {/* <button class="upload-btn mt-6 height-42">Upload</button> */}
                    </div>
                  </div>
                </div>
                <div class="p-2 pb-4 pt-4 w-1/2">
                  <div class="relative h-full">
                    <textarea
                      type="text"
                      id="email"
                      name="Details"
                      rows="3"
                      placeholder="Review Details"
                      class="w-full bg-white ex-employee-textarea bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2">
              <div className="p-6 rounded-b pl-0">
                <input
                  class="styled-checkbox"
                  id="styled-checkbox-2"
                  type="checkbox"
                  value="value2"
                />
                <label for="styled-checkbox-2">Disable</label>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex items-center justify-end p-6 rounded-b mr-3">
                <button
                  className="reset-btn cancel-btn font-bold  text-sm px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  Cancel
                </button>

                <button
                  className="bg-black save-btn ml-4 text-white font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </section>
      </table>

      {/* Card for table */}

      {isOpen && (
        <div className="fixed top-0 right-0 h-full w-1/5 h-1446px p-4 bg-gray-50 popup-form popup-form-dashboard">
          <button
            className="absolute top-4 right-4 p-2 text-gray-600"
            onClick={handleClose}
          >
            <img src={Image} alt="abc"></img>
          </button>
          <form onSubmit={formik.handleSubmit}>
            <div className="box pl-8 pr-6">
              <h1 className=" mb-1  text-lg font-bold">Add User</h1>
              <p className="add-user-popup pb-6">Add New User</p>
            </div>
            <hr className="hrLine pb-6" />
            {/* Form fields */}
            <div class="mt-1 pl-8 pr-6">
              <input
                type={"email"}
                name="email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
              />
              <p style={{ color: "red" }} class="required-text">
                {formik.errors.email ? formik.errors.email : ""}
              </p>
              &nbsp;
            </div>
            <div class="mt-1 pl-8 pr-6">
              <input
                type={"text"}
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                placeholder="Name"
                className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
              />
              <p style={{ color: "red" }} class="required-text">
                {formik.errors.first_name ? formik.errors.first_name : ""}
              </p>
              &nbsp;
            </div>
            <div class="mt-1 mb-1 pl-8 pr-6">
              <input
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                placeholder="Phone"
                className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
              />
              <p style={{ color: "red" }} class="required-text">
                {formik.errors.phone ? formik.errors.phone : ""}
              </p>
              &nbsp;
            </div>
            {/* <div class="absolute bottom-8 ">
         <hr className="hrLine" />

         </div> */}

            {/* Add more form fields as needed */}
            <div className="flex items-center justify-between pl-8 pr-6 rounded-b">
              <button
                className="reset-btn absolute bottom-6 left-8 m-0 font-bold  text-sm px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleClose}
              >
                Reset
              </button>

              <button
                className="bg-black absolute bottom-6 right-6 m-0 text-white font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
export default Approve;
