import React, { useEffect, useState } from "react";
import Login from "../../Assets/Images/Login.jpg";
import Logo from "../../Assets/Images/Logo.png";
import AuthHeader from "../../Services/authHeader";
import { useNavigate,Link } from "react-router-dom";
import Axios from '../../Services/commonHttp'

import {
  TextField,
  InputAdornment,
  IconButton,
  
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";

import Select from 'react-select'

const SignUp = () => {
  const navigate = useNavigate();
  const [selectState, setSelectState] = useState("");
  const [getState, setGetState] = useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const signUpValidation = Yup.object().shape({
    email: Yup.string().required("Please Enter Your Email"),
    phone: Yup.string().required("Please Enter Your Phone"),
    first_name: Yup.string().required("Please Enter Your Name"),
    designation: Yup.string().required("Please Enter Your Designation"),

    new_password: Yup.string().required("Please Enter Your Password"),

  
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      first_name: "",
      designation: "",
      new_password: "",
     },
    validationSchema: signUpValidation,
    validateOnBlur:false,
    validateOnChange:false,
    onSubmit: (values) => {
      values.region = selectState.label;
      userSignUp(values);
    },
  });
  async function userSignUp(values) {
    try {
      await AuthHeader.SignUp(
        values.email,
        values.phone,
        values.first_name,
        values.designation,
        values.region,
        values.new_password
      );
      navigate("/HomeDashboard");
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleShowPassword = () => {
    setShowPassword(true);
  };
  var handleMouseDownPassword = function (event) {
    event.preventDefault();
  };
  
  const options = getState.map((values) => {
    return {
      label: values.name,
      value: values.name,
    };
  });

  const selectStateHandler = (values) => {
    console.log('value',values)
    setSelectState(values);
  };
  const getListOfState = async () => {
    try {
      await Axios({
        method: "get",
        url: "/api/resource/Region",
        params: {
          fields: '["*"]',
        },
      }).then((response) => {
        return setGetState(response.data.data);
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getListOfState();
  }, []);
  return (
    <>
    <div class="w-100%">
            <div class="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
              <div class="g-0 lg:flex lg:flex-wrap">
                {/* <!-- Left column container--> */}
                <div class="px-4 md:px-0 form-side">
                <div className="bg-white px-4 lg:px-6 py-12 pb-0 ">
                      <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                      <a href="/" class="flex items-center"> <img
                          src={Logo}
                          className="mr-3 h-6 sm:h-9"
                          alt="Flowbite Logo"
                        />
                        </a>
                      </div>
                    </div>
                  <div class="md:mx-6 md:p-12">
                
                    {/* <!--Logo--> */}
                    
              
                    <div className="w-300 bg-white rounded-lg  dark:border md:mt-0 sm:max-w-auto xl:p-1 w-100   dark:bg-white dark:border-white">
                      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl mt-10 mb-4 font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                Sign Up
              </h1>
              <form onSubmit={formik.handleSubmit}>
                  <div>
                    <label
                      for="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                    >
                      {/* Email or Phone */}
                    </label>
                    <TextField
                      label="Business Email"
                      variant="outlined"
                      type="email"
                      name="email"
                      id="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      className="bg-white border border-gray-900 text-gray-700 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-700 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                    />
                    <p style={{ color: "red" }}>
                      {formik.errors.email ? formik.errors.email : ""}
                    </p>
                  </div>
                  <div>
                    <label
                      for="Business Phone"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                    ></label>
                    <TextField
                      label="Business Phone"
                      variant="outlined"
                      type={"text"}
                      name="phone"
                      id="email"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      className="bg- border border-gray-900 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-700 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                    />
                    <p style={{ color: "red" }}>
                      {formik.errors.phone ? formik.errors.phone : ""}
                    </p>
                  </div>

                  <div>
                    <label
                      for="first_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                    ></label>
                    <TextField
                      label="Full Name"
                      variant="outlined"
                      type={"text"}
                      name="first_name"
                      id="email"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      className="bg- border border-gray-900 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-700 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                    />
                    <p style={{ color: "red" }}>
                      {formik.errors.first_name ? formik.errors.first_name : ""}
                    </p>
                  </div>
                  <div>
                    <label
                      for="Designation"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                    ></label>
                    <TextField
                      label="Designation"
                      variant="outlined"
                      type={"text"}
                      name="designation"
                      id="email"
                      value={formik.values.designation}
                      onChange={formik.handleChange}
                      className="bg- border border-gray-900 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-700 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                    />
                    <p style={{ color: "red" }}>
                      {formik.errors.designation
                        ? formik.errors.designation
                        : ""}
                    </p>
                  </div>
                 
                  <div>
                    <label
                      for="Region"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                    ></label>
                    <Select
                      options={options}
                      value={selectState}
                      placeholder="Region"
                      onChange={selectStateHandler}
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                    />
                  </div>
                  
                  <div>
                    <label
                      for="password"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                    >
                      {/* Password */}
                    </label>
                    <TextField
                      label="Password"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      name="new_password"
                      onChange={formik.handleChange}
                      id="password"
                      value={formik.values.new_password}
                      className="bg-gray-50 border border-gray-900 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-800 dark:placeholder-gray-400 dark:text-black-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              background-color="black"
                              aria-label="toggle password visibility"
                              onClick={handleShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              class="password-eye"
                            >
                              {showPassword ? (
                                <AiFillEye />
                              ) : (
                                <AiOutlineEyeInvisible />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <p style={{ color: "red" }}>
                      {formik.errors.new_password ? formik.errors.new_password : ""}
                    </p>
                  </div>

                  <button
                    class="block gradient-btn w-full px-4 py-2 my-7 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-gray-800 border border-transparent rounded-lg active:bg-gray-900 hover:bg-gray-700 focus:outline-none focus:shadow-outline-blue"
                    href="#"
                  >
                    Sign Up
                  </button>
                  <p className="text-md flex justify-center font-dark text-gray-800 dark:text-gray-900 ">
                Already having an account?{" "}
                              <Link
                                to="/Login"
                                className="font-lg text-primary-800 hover: dark:text-primary-800"
                              >
                                 &nbsp;Log In
                              </Link>
                            </p>
                </form>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Right column container with background and description--> */}
                <div class="h-32 md:h-auto image-side">
              <img class="object-cover w-full h-full" src={Login} alt="img" />
                  </div>
              </div>
            </div>
          </div>
    </>
  );
};

export default SignUp;
