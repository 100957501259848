import ProcessOrder from "../../Widgets/phonePeOrder";
import Diamond from "../../Assets/Images/diamond.png";
import { useEffect, useState } from "react";
import AuthHeader from "../../Services/authHeader";

const Card = (props) => {
  const [getUser, setGetUser] = useState();
  const [getPhoneNumber, setPhoneNumber] = useState();
  
  useEffect(() => {
    const storedData = sessionStorage.getItem("usr");
    if (storedData) {
      setGetUser(JSON.parse(storedData));
    }
  }, []);

  const fetchProfileData = async () => {
    try {
      const data = await AuthHeader.ProfileData(getUser.email);
      setPhoneNumber(data.mobile_no);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    if (getUser) {
      fetchProfileData();
    }
  }, [getUser]);
  return (
    <>
      <div
        class="bg-blue-50 p-3 max-w-sm rounded shadow-md border border-gray-100 overflow-hidden billing-card "
        style={{ maxHeight: "24rem" }}
      >
        <img
          class="billing-card-icon mx-auto "
          style={{ width: "43px" }}
          Search
          src={Diamond}
          alt="Search logo"
        ></img>
        <p
          className="text-center font-1xl
                billing-page-heading
               mt-3"
        >
          {props.data.subscription_plan_type}
        </p>
        <div class="px-6 py-2 card-text">
          <div class="flex mb-2">
            <div className="flex mx-auto items-end ">
              <p class="w-medium font-bold mr-1 mb-1">₹</p>
              <p class="text-4xl  font-bold billing-price">
                {props.data.amount}
              </p>
            </div>
          </div>
          <div class="px-6 pt-1 pb-2">
            <span class="w-full items-center text-center inline-block bg-blue-200 rounded-lg px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 billing-points-btn">
              For { props.data.points} {props.data.subscription_plan_type === "Search" ? 'Month' : 'Points'} 
            </span>
          </div>
          <p class="text-gray-700 text-base text-center para">
            Stay on top of the news <br />
            Personalised recommendations Ad free experience Topics of interested
            by you
          </p>
        </div>
        <div class="px-6  pb-1">
          <button
            class="bg-gray-800 w-full py-2 items-center text-center inline-block rounded-lg px-3 py-1 text-white text-sm font-semibold text-gray-700 mr-2 mb-2 billing-buy-btn"
            onClick={() =>
              ProcessOrder(
                props.data.amount,
                getPhoneNumber,
                props.data.subscription_plan_type,
                props.data.points
              )
            }
          >
            Buy
          </button>
        </div>
      </div>
    </>
  );
};

export default Card;
