export const binarySearchByEmail=(arr, searchEmail)=>{
    arr.sort((a, b) => a.email.localeCompare(b.email));
    let low = 0;
    let high = arr.length - 1;

    while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        const midEmail = arr[mid].email;

        if (midEmail === searchEmail) {
            return [arr[mid]]; 
        } else if (midEmail < searchEmail) {
            low = mid + 1;
        } else {
            high = mid - 1;
        }
    }
    return null;  
}