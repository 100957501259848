import React, { useState } from 'react';
import User from '../Assets/Images/user-icon.png'
import Close from '../Assets/Images/close.png'

const RightNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        className="flex items-center space-x-4  h-11 border border-gray-300 rounded-full mr-1 "
        onClick={handleToggle}
      >
        <img src={User} alt='abc' className='bg-gray-300 rounded-full h-11'></img>
        <p>Add User +</p><p></p>
      </button>
      {isOpen && (
        <div className="fixed top-0 right-0 h-screen w-1/5 h-1446px p-4 bg-white popup-form ">
          <button
            className="absolute top-4 right-4 p-2 text-gray-600"
            onClick={handleClose}
          >
            <img src={Close} alt="abc"></img>
          </button>
          <form onSubmit={handleSubmit}>
            <div className='box'>
            <h1 className=" mb-1  text-lg font-bold">Add User</h1>
            <p className='mb-4 '>Add New User</p>
            </div>
            {/* Form fields */}
            <input
              type="text"
              placeholder="Name"
              className="block w-full px-4 py-2 mt-5 mb-4 bg-white border border-gray-300 rounded"
            />
            <input
              type="text"
              placeholder="Phone"
              className="block w-full px-4 py-2 mb-4 bg-white border border-gray-300 rounded"
            />
            <input
              type="email"
              placeholder="Email"
              className="block w-full px-4 py-2 mb-4 bg-white border border-gray-300 rounded"
            />

            {/* Add more form fields as needed */}
            <div className="adduser-btn">
              <hr className='mb-3 submit-btn-line'></hr>
                <button
                  type="submit"
                  className=" adduser-submit-btn float-right w-medium px-4 py-2 text-white rounded"
              >
                  Submit
                </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default RightNavbar;
