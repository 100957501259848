import React, { useState, useRef } from "react";
import { uploadFile } from "../Services/authHeader";

function UploadButton({
  fileNames,
  setFileNames,
  fieldName,
  flag,
  setFlag,
  fileTypes,
  label,
  doctype,
  docname,
  disabled,
  GetFilePath
}) {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);

  const handleChange = (file) => {
   
    if (!loading && !disabled) {
      setLoading(true);
      if (file) {
        setFileName(file.name);
        const reader = new FileReader();
        reader.onload = (readerEvt) => {
          handleReaderLoaded(readerEvt, file.name);
        };
        reader.readAsBinaryString(file);
      }
    }
  };

  const handleReaderLoaded = (readerEvt, fileName) => {
    let binaryString = readerEvt.target.result;
    const fileData = btoa(binaryString);
    handleUploadFile({ fileName, fileData });
  };

  const handleUploadFile = async ({ fileName, fileData }) => {
    const res = await uploadFile({
      fileName,
      fileData,
      doctype,
      docname,
      fieldName,
    });
    if (res.status === 200) {
      const data = { ...fileNames };
      data[fieldName] = res.data.message.file_url; // Set the specific field
      setFileNames(data);
      setFlag(!flag);
    }
    setLoading(false);
  };

  const triggerFileInputClick = (event) => {
    event.preventDefault(); // Prevents default action
    if (!disabled && !loading) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="pb-4 input-width">
      <div className="relative flex field-container">
        <input
          type="text"
          id={`file-url-${fieldName}`}
          name={`file-url-${fieldName}`}
          placeholder={label}
          value={fileNames[fieldName] || "" || GetFilePath}
          className="w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          readOnly
          disabled={disabled}
        />
        <label
          htmlFor={`file-url-${fieldName}`}
          className="placeholders"
        >
          {label}
        </label>
        <input
          type="file"
          id={`file-${fieldName}`}
          name={`file-${fieldName}`}
          accept={fileTypes.join(",")}
          onChange={(e) => handleChange(e.target.files[0])}
          className="hidden"
          ref={fileInputRef}
        />
        <label
          htmlFor={`file-${fieldName}`}
          className={`upload-btn flex items-center justify-center ml-2 ${
            loading || disabled ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={triggerFileInputClick}
        >
          {loading ? "Uploading..." : "Upload"}
        </label>
      </div>
    </div>
  );
}

export default UploadButton;
