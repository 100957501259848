import React, { useEffect, useState } from "react";
import { Layout, Table, Select, DatePicker } from "antd";
import Dashboardnavbar from "../../../Widgets/Dashboardnavbar";
import CommonNavbar from "../../../Widgets/CommonNavbar";
import { toast, ToastContainer } from "react-toastify";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import FilterIcon from "../../../../src/Assets/Images/filter-icon.svg";
import DownArrow from "../../../../src/Assets/Images/down-arrow.svg";
import {
  deductPointsOnSearch,
  getSelectedUser,
  saveReviewSearchLog,
  searchUsers,
} from "../../../Services/authHeader";
import moment from "moment";
import ReportUser from "./ReportUser";
import axios from "axios";
import { useLocation } from "react-router-dom";

const { Option } = Select;

const Search = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [filterUser, setFilterUser] = useState([]); // Initially empty
  const [selectedApi, setSelectedApi] = useState("");
  const [allUserData, setAllUserData] = useState([]); // Initially empty
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [getSearchUser, setGetSearchUser] = useState({
    name: "",
    phone: "",
    email: "",
    creation: "",
    company: "",
    designation: "",
  });
  const [doctype, setDoctype] = useState("");
  const location=useLocation()
  const RecentSearchData = location.state?.recentSearchData;

  // useEffect(()=>{
  //   setFilterUser(RecentSearchData)
  // },[location.state?.recentSearchData])
  // console.log("RecentSearchData",RecentSearchData===undefined ,"location",location)

  const workflow_state = JSON.parse(sessionStorage.getItem("workflow_state"))


  const handleClose = () => {
    setIsOpen(false);
    const darkModeElement = document.getElementById("dark-mode-on");
    if (darkModeElement) {
      darkModeElement.classList.remove("dark-mode-on");
    }
  };

  const handleSearchUser = (e) => {
    const { name, value } = e.target;
    setGetSearchUser({ ...getSearchUser, [name]: value });
  };

  const handleDateChange = (date, dateString) => {
    setGetSearchUser({ ...getSearchUser, creation: dateString });
  };

  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };

  const apiOptions = [
    { value: "Ex Employee Reviews", label: "Ex Employee Reviews list" },
    { value: "Non Joiners Reviews", label: "Non Joiners Reviews list" },
  ];

  const handleApiChange = (value) => {
    setFilterUser([]);
    setSelectedApi(value);
  };

  const filterBySpecificKeys = (arr, keys) => {
    if (!Array.isArray(arr)) {
      console.error("Expected an array but received:", arr);
      return [];
    }

    return arr.filter((obj) => {
      return (
        obj["doctype"] !== "User" &&
        keys.every((key) => obj[key] !== null && obj[key] !== undefined)
      );
    });
  };

  const getUserData = (selectedApi) => {
    console.log("Fetching data for selected API:", selectedApi);
    searchUsers({ doctype: selectedApi })
      .then((data) => {
        const requiredKeys = ["company", "name", "phone"];
        const result = filterBySpecificKeys(data, requiredKeys);
        setAllUserData(result);
        setFilterUser(result);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const searchAndSetResult = async () => {
    // Convert creation date to DD-MM-YYYY format
    const formattedCreationDate = getSearchUser.creation
      ? moment(getSearchUser.creation).format("DD-MM-YYYY")
      : "";
    const filters = {
      doctype: selectedApi,
      name: getSearchUser.username,
      phone: getSearchUser.phone,
      email: getSearchUser.email,
      company: getSearchUser.company,
      creation: formattedCreationDate,
      designation: getSearchUser.designation,
    };

    try {
      if (!filters.name && !filters.phone && !filters.email && !filters.company && !filters.creation && !filters.designation) {
        toast.info("Enter name to Search");
        return
      }
      const dataSearched = await searchUsers(filters)
      if (dataSearched.length === 0) {
        toast.info("No Match Found!");
        setFilterUser("")
        return
      }
      setFilterUser(dataSearched);

    } catch (error) {
      console.log("Something wrong in searchAndSetResult function")
    }


    // searchUsers(filters)
    //   .then((data) => {
    //     console.log("Filtered Data:", data);
    //     setFilterUser(data);
    //     if (data.length === 0) {
    //       toast.info("No Match Found!");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error in searching Users List:", error);
    //   });
  };

  const clearFilters = () => {
    setGetSearchUser({
      name: "",
      phone: "",
      email: "",
      creation: "",
      company: "",
      designation: "",
    });
    setFilterUser([]);
  };

  document.body.classList.remove(
    "billing-page",
    "dashboard-page",
    "profile-page",
    "users-page",
    "review-page"
  );
  document.body.classList.add("search-page");

  // const handleViewClick = async (record) => {
  //   try {
  //     const userDetails = await getSelectedUser(
  //       selectedApi || record.doctype,
  //       record.name
  //     );
  //     setSelectedEmployee(userDetails);
  //     setDoctype(record.doctype);
  //     console.log("Selected user doctype:", record.doctype);
  //   } catch (error) {
  //     console.error("Error fetching user details:", error);
  //   }
  // };

  // const handleViewClick = async () => {
  //   const payload = {
  //     docstatus: 0,
  //     doctype: "Review Search Log",
  //     name: "new-review-search-log-vfbgviefmg",
  //     __islocal: 1,
  //     __unsaved: 1,
  //     owner: "Administrator",
  //     ex_employee_review: "E-ER-0091"
  //   };

  //   try {
  //     const response = await saveReviewSearchLog(payload);
  //     console.log('Saved Review Search Log:', response);
  //   } catch (error) {
  //     console.error('Failed to save Review Search Log:', error);
  //   }
  // };


  const handleViewClick = async (record) => {
    try {
      const userDetails = await getSelectedUser(
        selectedApi || record.doctype,
        record.name
      );

      setSelectedEmployee(userDetails);
      setDoctype(record.doctype);
      console.log("Selected user doctype record:", record)
      console.log("Selected user doctype:", record.doctype);
      console.log("Selected user doctype userDetails:", userDetails);

      let payload = {
        user_name: userDetails.first_name + " " + userDetails.last_name,
        user_company: userDetails.company_name,
      };

      if (record.doctype === "Ex Employee Reviews") {
        payload.ex_employee_review = userDetails.name;

      } else {
        payload.non_joiners_reviews = userDetails.name;

      }

      const response = await saveReviewSearchLog(payload);

      const payload2 = {
        reference_doctype: record.doctype,
        reference_docname: userDetails.name,
        url: "",
      };
      const response2 = await deductPointsOnSearch(payload2);

      console.log("API Response:", response);
      toast.success("Review search log saved successfully!");
      toast.success("Points Deducted Successfully");
    } catch (error) {
      console.error("Error fetching user details or saving log:", error);
      toast.error(
        "Failed to save review search log or failed to deduct points."
      );
    }
  };

  const handleBackClick = () => {
    setFilterUser("")
    setSelectedEmployee(null);
  };

  const columns = [
    { title: "Name", dataIndex: "username", key: "username" },
    { title: "Mobile", dataIndex: "phone", key: "phone" },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Creation Date",
      dataIndex: "creation",
      key: "creation",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    { title: "Designation", dataIndex: "designation", key: "designation" },
    { title: "Company", dataIndex: "company", key: "company" },
    {
      title: "View Details",
      key: "View",
      render: (_, record) => (
        <button onClick={() => handleViewClick(record)} className="view-link" >
          View
        </button>
      ),
    },
  ];

  const displayValue = (value) => value || "N/A";

  return (
    <Layout className="h-screen">
      <ToastContainer />
      <Sider width={256} className="site-layout-background">
        <Dashboardnavbar />
      </Sider>
      <Layout className="panel-right">
        <CommonNavbar />
        <Content>
          <div
            className="w-auto search-section-table"
            style={{ paddingTop: "2.5rem" }}
          >
            <div className="search-right">
              {!selectedEmployee && (
                <div className="search-fields">
                  <div className="flex justify-between mb-8 search-table-section">
                    <div className="form-fields">
                      <input
                        type="text"
                        id="first_name"
                        name="username"
                        placeholder="Search Name"
                        value={getSearchUser.username}
                        onChange={handleSearchUser}
                      />
                    </div>
                    <div className="form-fields">
                      <input
                        type="text"
                        id="phone"
                        placeholder="Search Phone Number"
                        name="phone"
                        value={getSearchUser.phone}
                        onChange={handleSearchUser}
                      />
                    </div>
                    <div className="form-fields">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Search Email"
                        value={getSearchUser.email}
                        onChange={handleSearchUser}
                      />
                    </div>
                    <div className="pr-0">
                      <button
                        className={`flex border p-2 rounded-md more-filter-btn ${isExpanded ? "expanded" : ""
                          }`}
                        type="button"
                        onClick={handleButtonClick}
                        disabled={!workflow_state}
                      >
                        <img
                          src={FilterIcon}
                          alt=""
                          style={{
                            filter: isExpanded
                              ? "invert(1) brightness(1000%)"
                              : "none",
                          }}
                        
                        />
                        More Filter
                        <img
                          src={DownArrow}
                          alt=""
                          style={{
                            filter: isExpanded
                              ? "invert(1) brightness(1000%)"
                              : "none",
                          }}
                        />
                      </button>
                    </div>
                  </div>
                  {isExpanded && (
                    <>
                      <div className="expand-box">
                        <div className="flex justify-between mb-8 search-table-section">
                          {/* <div className="form-fields">
                            <Select
                              className="search-select"
                              onChange={handleApiChange}
                              value={selectedApi || undefined}
                              placeholder="Select"
                            >
                              {apiOptions.map((option) => (
                                <Option key={option.value} value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                            </Select>
                          </div> */}
                          <div className="form-fields">
                            <input
                              type="text"
                              id="company"
                              name="company"
                              placeholder="Search Company"
                              value={getSearchUser.company}
                              onChange={handleSearchUser}
                            />
                          </div>
                          <div className="form-fields">
                            <DatePicker
                              id="creation"
                              name="creation"
                              placeholder="Search Creation Date"
                              value={
                                getSearchUser.creation
                                  ? moment(getSearchUser.creation, "YYYY-MM-DD")
                                  : null
                              }
                              onChange={handleDateChange}
                            />
                          </div>
                          <div className="form-fields">
                            <input
                              type="text"
                              id="designation"
                              name="designation"
                              placeholder="Search Designation"
                              value={getSearchUser.designation}
                              onChange={handleSearchUser}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* <div className="form-fields search-btn-cont">
                    <button className="btn-primary" onClick={searchAndSetResult}>
                      Search
                    </button>
                    <button className="btn-secondary" onClick={clearFilters}>
                      Clear
                    </button>
                  </div>
                </div> */}
                  <div className="search-btn-cont">
                    <button onClick={clearFilters} className="clear-btn">
                      Clear
                    </button>
                    <button onClick={searchAndSetResult}>Search</button>
                  </div>
                </div>
              )}
              {/* {console.log(selectedEmployee)} */}
              {/* {console.log(filterUser)} */}
              {!selectedEmployee && (
                <div className="user-list-table">
                  <div className="result-table-head">
                    <h2>Results</h2>
                    <div className="right-head">
                      Results: <span>{filterUser.length}</span>
                    </div>
                  </div>
                  {workflow_state &&
                    <Table
                      dataSource={
                        filterUser && filterUser.length > 0
                          ? filterUser
                          : allUserData
                      }
                      pagination={{
                        defaultPageSize: 10,
                        pageSizeOptions: ["10", "20", "30", "50"],
                        showSizeChanger: true,
                      }}
                      columns={columns}
                    />
                  }

                </div>
              )}
              {selectedEmployee && (
                <div className="employee-details">
                  <div className="form-head">
                    <h2>Review ID - {selectedEmployee.name}</h2>
                    <div className="right-btns">
                      <button onClick={handleBackClick} className="back-button">
                        Back
                      </button>
                      <button
                        onClick={() => setIsOpen(true)}
                        className="back-button dark-color"
                      >
                        Report
                      </button>
                    </div>
                  </div>

                  <div className="form-container">
                    <section className="search-res-basic-info">
                      <h2>Basic</h2>
                      <div className="form-body">
                        {/* <div className="form-data">
                          <p>{displayValue(selectedEmployee.user_image)}</p>
                        </div> */}
                        <div className="form-data">
                          <label>First Name</label>
                          <p>{displayValue(selectedEmployee.first_name)}</p>
                        </div>
                        <div className="form-data">
                          <label>Document</label>
                          <p>
                            {displayValue(
                              selectedEmployee.follow_liked_documents
                            )}
                          </p>
                        </div>
                        <div className="form-data">
                          <label>Last Name</label>
                          <p>{displayValue(selectedEmployee.last_name)}</p>
                        </div>
                        <div className="form-data">
                          <label>Document</label>
                          <p>
                            {displayValue(
                              selectedEmployee.follow_assigned_documents
                            )}
                          </p>
                        </div>
                        <div className="form-data">
                          <label>Date Of Birth</label>
                          <p>{displayValue(selectedEmployee.date_of_birth)}</p>
                        </div>
                        <div className="form-data">
                          <label>Document</label>
                          <p>
                            {displayValue(
                              selectedEmployee.follow_assigned_documents
                            )}
                          </p>
                        </div>
                        <div className="form-data">
                          <label>Designation</label>
                          <p>{displayValue(selectedEmployee.designation)}</p>
                        </div>
                        <div className="form-data">
                          <label>Document</label>
                          <p>
                            {displayValue(selectedEmployee.email_signature)}
                          </p>
                        </div>
                        <div className="form-data form-row-data">
                          <div className="form-group-data">
                            <label>Joined On </label>
                            <p>{displayValue(selectedEmployee.joined_on)}</p>
                          </div>
                          <div className="form-group-data">
                            <label>Released On </label>
                            <p>{displayValue(selectedEmployee.released_on)}</p>
                          </div>
                        </div>
                        <div className="form-data">
                          <label>Review Details </label>
                          <p>{displayValue(selectedEmployee.review_details)}</p>
                        </div>
                        <div className="form-data">
                          <label>Type of Review </label>
                          <p>{displayValue(selectedEmployee.type_of_review)}</p>
                        </div>
                        <div className="form-data">&nbsp;</div>
                        <div className="form-data">
                          <label>Phone</label>
                          <p>{displayValue(selectedEmployee.phone)}</p>
                        </div>
                        <div className="form-data">
                          <label>Email</label>
                          <p>{displayValue(selectedEmployee.email)}</p>
                        </div>
                      </div>
                    </section>
                    <section className="search-res-prev-company">
                      <h2>Last known company</h2>
                      <div className="form-body">
                        <div className="form-data">
                          <label>Company</label>
                          <p>{displayValue(selectedEmployee.company)}</p>
                        </div>
                        <div className="form-data">
                          <label>Location</label>
                          <p>{displayValue(selectedEmployee.location)}</p>
                        </div>
                        <div className="form-data">
                          <label>Previous Designation</label>
                          <p>
                            {displayValue(
                              selectedEmployee.custom_previous_designation
                            )}
                          </p>
                        </div>
                        <div className="form-data form-row-data">
                          <div className="form-group-data">
                            <label>Joined On </label>
                            <p>
                              {displayValue(
                                selectedEmployee.custom_last_known_company_joined_on
                              )}
                            </p>
                          </div>
                          <div className="form-group-data">
                            <label>Released On </label>
                            <p>
                              {displayValue(
                                selectedEmployee.custom_last_known_company_released_on
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="search-res-education">
                      <h2>Education</h2>
                      <div className="form-body">
                        <div className="form-data">
                          <label>Highest Education</label>
                          <p>
                            {displayValue(selectedEmployee.highest_education)}
                          </p>
                        </div>
                        <div className="form-data">
                          <label>School/College/University </label>
                          <p>
                            {displayValue(
                              selectedEmployee.schoolcollegeuniversity
                            )}
                          </p>
                        </div>
                        <div className="form-data">
                          <label>boarduniversity </label>
                          <p>
                            {displayValue(selectedEmployee.boarduniversity)}
                          </p>
                        </div>
                        <div className="form-data form-row-data">
                          <div className="form-group-data">
                            <label>City </label>
                            <p>{displayValue(selectedEmployee.city)}</p>
                          </div>
                          <div className="form-group-data">
                            <label>State</label>
                            <p>{displayValue(selectedEmployee.state)}</p>
                          </div>
                        </div>
                        <div className="form-data">
                          <label>Year</label>
                          <p>{displayValue(selectedEmployee.year)}</p>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              )}
            </div>
          </div>

          {isOpen && (
            <ReportUser
              isOpen={isOpen}
              handleClose={handleClose}
              employee={selectedEmployee}
              doctype={doctype}
            />
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Search;
