import React,{useState} from "react";
import NavbarDashboard from "../../Widgets/SuperAdminDashboardNavbar";
// import User from "../../Assets/Images/user.png";
import * as Yup from "yup";
import { useFormik } from "formik";
// import Graph from "./graph";
import AuthHeader from "../../Services/authHeader";
import { useNavigate } from "react-router-dom";
import User from '../../Assets/Images/user-icon.png'
import Image from '../../Assets/Images/close.png'
import Adminusericon from '../../Assets/Images/admin-user-icon.png'
import Prizes from '../../Assets/Images/prize.png'
import {Link} from 'react-router-dom'
import { MdNavigateNext } from "react-icons/md";
import Companyreviews from "./delete-submit-exemployee";
import Companyreportreview from "./delete-submit-exemployee";

import Userlist from "./delete-submit-nonjoiner";
const DeleteSubmit = () => {
  const navigate = useNavigate();
  const [showbutton, setShowButton] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpen2, setIsOpen2] = React.useState(false);
  const [tabType, setTabType] = useState("exEmployee");

  
  const addUser = Yup.object().shape({
    email: Yup.string().required("Please enter your email"),
    first_name: Yup.string().required("Enter your first name"),
    phone: Yup.string().required("Please enter your mobile number"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      phone: "",
    },
    validationSchema: addUser,
    onSubmit: (values) => {
      addReviewUser(values);
      navigate("/data");
      
    },
  });
  async function addReviewUser(values) {
    try {
      await AuthHeader.UserAdd(values.email, values.first_name, values.phone);
    } catch (error) {
      console.log("error", error);
    }
  }
  const handleClose = () => {
    setIsOpen(false);
    document.getElementById("dark-mode-on").classList.remove("dark-mode-on")
  };
  const handleClose2 = () => {
    setIsOpen2(false);
    document.getElementById("dark-mode-on").classList.remove("dark-mode-on")
  };
  const handleCloseButton = () => {
    setShowButton(true);
  };
 
  return (
    <>
     <div id="dark-mode-on"></div>

      <NavbarDashboard />

      <header class="text-gray-600 body-font overflow-auto">
        <div class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <div
            href="/"
            class="flex title-font font-medium items-center text-gray-900 mb-4 pl-64 md:mb-0 approve-padding"
          >
            <span class="ml-3.5 home-dashboard-margin text-xl">Deletion Requests</span>
          </div>
          <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center pr-5">
          <button class="bg-black  text-white font-2 py-2 px-4 rounded-full flex prize-delete">
            <img src={Prizes} alt="" ></img>
              1000 Points
            </button>
            &nbsp;
            &nbsp;
            <div class="flex items-center space-x-4  h-12 px-6 border border-gray-300 rounded-full pl-0">
              <img class="rounded-full user-img" src={User} alt="user" />
              <div class="font-medium dark:text-white">
               <button onClick={()=>{
                setIsOpen(true)
                document.getElementById("dark-mode-on").classList.add("dark-mode-on")
               }}>Add User +</button>
              </div>
            </div>
            &nbsp;
            &nbsp;
            <div class="dropdown">
              <button
                className="flex items-center super-admin-logout space-x-8 pr-4  h-13 border border-gray-300 rounded-full mr-0 py-1 "
                onClick={handleCloseButton}
              >
                <img
                  class="Adminusericon rounded-full"
                  src={Adminusericon}
                  alt=""
                />
                <h3 class="block admin-name-txt">
                Stephen Austin <span class="block admin-txt">Admin</span>
                </h3>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 14L8 10H16L12 14Z" fill="#647086" stroke="#647086" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              </button>
              {/* dropdown menu */}
              <div
                id="dropdown"
                className={`z-10 w-40 h-auto mt-4 bg-white absolute dropdown-logout rounded-full divide-y divide-gray-100 shadow ${
                  showbutton ? "block" : "hidden"
                }`}
              >
               
                    <Link to='/Logout'
                   
                      className="block py-1 px-2 text-left logout-link"
                    >
                      Logout
                    </Link>
                 
              </div>
            </div>
          </nav>
        </div>
       
       
      </header>
      <hr className="hrLine" />
      {/* Modal for button */}
      <div
        class=" w-auto  " 
      >

     <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-3">
                  <section class="text-gray-600 body-font relative mx-auto">
                
                
                    <div class=" mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center pl-0 pb-10 pt-7">
        <div class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
          <button
            class="dark-color hover: text-white font-normal py-3 px-4 rounded"
            id="review-button"
            type="button"
            onClick={() => {
              setTabType("exEmployee");
              document.getElementById("review-button").classList.add("dark-color")
              document.getElementById("user-button").classList.remove("dark-color")
              document.getElementById("review-button").classList.remove("light-color")
              document.getElementById("user-button").classList.add("light-color")
            }}
          >
          Ex-Employee
          </button>

          <button
            class="light-color hover: text-balck font-normal py-3 px-4 rounded"
            type="button"
            id="user-button"
            onClick={() => {
              setTabType("AddNonJoiner");
              document.getElementById("review-button").classList.remove("dark-color")
              document.getElementById("user-button").classList.add("dark-color")
              document.getElementById("user-button").classList.remove("light-color")
              document.getElementById("review-button").classList.add("light-color")
            }}
          >
          Non-Joiners
          </button>

          <a href="/superAdmin/DeleteSubmit" className="bg-black text-center absolute right-9 save-btn ml-4 text-white font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  >
                    Delete
                  </a>
        </div>
      </div>
      {/* card */}
      {tabType === "exEmployee" ? <Companyreviews /> : <Userlist />}
                  </section>
                </table>
        </div>
      {/* Card for table */}

        {isOpen && (
          <div className="fixed top-0 right-0 h-full w-1/5 h-1446px p-4 bg-gray-50 popup-form popup-form-dashboard">
            <button
              className="absolute top-4 right-4 p-2 text-gray-600"
              onClick={handleClose}
            >
              <img src={Image} alt="abc"></img>
              
            </button>
            <form onSubmit={formik.handleSubmit}>
              <div className="box pl-8 pr-6">
                <h1 className=" mb-1  text-lg font-bold">Add User</h1>
                <p className="add-user-popup pb-6">Add New User</p>
              </div>
              <hr className="hrLine pb-6" />
              {/* Form fields */}
              <div class="mt-1 pl-8 pr-6">
              <input
                type={"email"}
                name="email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
              />
              <p style={{ color: "red" }} class="required-text">
                {formik.errors.email ? formik.errors.email : ""}
              </p>
              &nbsp;
              </div>
              <div class="mt-1 pl-8 pr-6">
              <input
                type={"text"}
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                placeholder="Name"
                className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
              />
              <p style={{ color: "red" }} class="required-text">
                {formik.errors.first_name ? formik.errors.first_name : ""}
              </p>
              &nbsp;
              </div>
              <div class="mt-1 mb-1 pl-8 pr-6">

              <input
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                placeholder="Phone"
                className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
              />
              <p style={{ color: "red" }} class="required-text">
                {formik.errors.phone ? formik.errors.phone : ""}
              </p>
              &nbsp;
</div>
         {/* <div class="absolute bottom-8 ">
         <hr className="hrLine" />

         </div> */}

              {/* Add more form fields as needed */}
              <div className="flex items-center justify-between pl-8 pr-6 rounded-b">
                <button
                  className="reset-btn absolute bottom-6 left-8 m-0 font-bold  text-sm px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleClose}
                >
                  Reset
                </button>
                
                <button
                  className="bg-black absolute bottom-6 right-6 m-0 text-white font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}

    </>
  );
};
export default DeleteSubmit;
