import React, { useState } from "react";
import Dashboardnavbar from "../../../Widgets/Dashboardnavbar";
import ExEmployess from "./exEmployess";
import NonJoiners from "./nonJoiners";
import AddExEmployeeForm from "./addExEmployeeForm";
import AddNonJoinerForm from "./addNonJoinersForm";
import User from "../../../Assets/Images/user-icon.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import AuthHeader from "../../../Services/authHeader";
// import image from '../../Assets/Images/close.png'
import Image from "../../../Assets/Images/close.png";
// import { useNavigate } from "react-router-dom";
import Prizes from "../../../Assets/Images/prize.png";

const Data = () => {
  // const navigate = useNavigate();
  const [tabType, setTabType] = useState("exEmployee");
  const [formType, setFormType] = useState(null);
  // const [resetButton,setResetButton]=useState(false)
  // add User
  const [isOpen, setIsOpen] = React.useState(false);
  const [showbutton, setShowButton] = useState(false);

  const addUser = Yup.object().shape({
    email: Yup.string().required("Please Enter Your Email"),
    first_name: Yup.string().required("Enter Your First Name"),
    phone: Yup.string().required("Please Enter Your Mobile Number"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      phone: "",
    },
    validationSchema: addUser,
    onSubmit: (values) => {
      addReviewUser(values);
      <ExEmployess />;
    },
  });
  async function addReviewUser(values) {
    try {
      await AuthHeader.UserAdd(values.email, values.first_name, values.phone);
    } catch (error) {
      console.log("error", error);
    }
  }
  const handleResetButton = (value) => {
    if (value === true) {
      setTabType("exEmployee");
      setFormType(null);
    }
    var element2 = document.getElementById("form-join");
    element2.classList.remove("hide-join");
  };
  const handleClose = () => {
    setIsOpen(false);
    document.getElementById("dark-mode-on").classList.remove("dark-mode-on");
  };
  const editableForm = () => {
    if (tabType === "exEmployee" || tabType === "AddNonJoiner") {
      setFormType(tabType);
    }
    var element = document.getElementById("form-join");
    element.classList.add("hide-join");
  };
  document.body.classList.remove("dashboard-page");
  document.body.classList.remove("profile-page");
  document.body.classList.add("review-page");
  document.body.classList.remove("billing-page");
  document.body.classList.remove("search-page");
  return (
    <>
      <div id="dark-mode-on"></div>
      <div>
        <Dashboardnavbar />
        <header class="text-gray-600 body-font overflow-auto">
          <div class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
            <div
              href="/"
              class="flex title-font font-medium items-center text-gray-900 mb-4 pl-64 md:mb-0"
            >
              <span class="ml-3 text-xl"> </span>
            </div>
            <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center pr-44">
              <button class="bg-black  text-white font-2 py-2 px-4 rounded-full flex">
                <img src={Prizes} alt=""></img>
                1000 Points
              </button>
              &nbsp;
              <div class="flex items-center space-x-4  h-12 px-6 border border-gray-300 ml-4 rounded-full pl-0">
                <img class="rounded-full user-img" src={User} alt="user" />
                <div class="font-medium dark:text-white">
                  <button
                    onClick={() => {
                      setIsOpen(true);
                      document
                        .getElementById("dark-mode-on")
                        .classList.add("dark-mode-on");
                    }}
                  >
                    Add User +
                  </button>
                </div>
              </div>
              {/* <div class="flex items-center space-x-4  h-12 px-6 border border-gray-300 rounded-full ml-4">
                <img
                  class="w-10 h-10 rounded-full"
                  src="https://images.pexels.com/photos/5212324/pexels-photo-5212324.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                  alt=""
                /> */}
              <div class="dropdown -mt-16 ">
                {/* <button
                className="flex items-center space-x-8 pr-4  h-13 border border-gray-300 rounded-full mr-4 py-1 "
                onClick={handleCloseButton}
              > */}
                {/* <img
                  class="w-10 h-10 rounded-full"
                  src="https://images.pexels.com/photos/5212324/pexels-photo-5212324.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                  alt=""
                /> */}
                {/* {getUserName || getLoginUserName}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                > */}
                {/* <path
                    d="M12 14L8 10H16L12 14Z"
                    fill="#647086"
                    stroke="#647086"
                    stroke-width="1.4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  /> */}
                {/* </svg> */}
                {/* </button> */}
                {/* dropdown menu */}
                <div
                  id="dropdown "
                  className={`z-10 w-40 ml-4 h-auto mt-4 rounded-full bg-white absolute dropdown-logout rounded-full divide-y divide-gray-100 shadow ${
                    showbutton ? "block" : ""
                  }`}
                >
                  <Link
                    to="/Logout"
                    className="block py-1 px-2 text-center logout-link"
                  >
                    Log Out
                  </Link>
                </div>
              </div>
              {/* </div> */}
            </nav>
          </div>
          {/* Add user */}
          {isOpen && (
            <div className="fixed top-0 right-0 h-full w-1/5 h-1446px p-4 bg-gray-50 popup-form popup-form-dashboard">
              <button
                className="absolute top-4 right-4 p-2 text-gray-600"
                onClick={handleClose}
              >
                <img src={Image} alt="abc"></img>
              </button>
              <form onSubmit={formik.handleSubmit}>
                <div className="box pl-8 pr-6">
                  <h1 className=" mb-1  text-lg font-bold">Add User</h1>
                  <p className="add-user-popup pb-6">Add New User</p>
                </div>
                <hr className="hrLine pb-6" />
                {/* Form fields */}
                <div class="mt-1 pl-8 pr-6">
                  <input
                    type={"email"}
                    name="email"
                    placeholder="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
                  />
                  <p style={{ color: "red" }} class="required-text">
                    {formik.errors.email ? formik.errors.email : ""}
                  </p>
                  &nbsp;
                </div>
                <div class="mt-1 pl-8 pr-6">
                  <input
                    type={"text"}
                    name="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    placeholder="Name"
                    className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
                  />
                  <p style={{ color: "red" }} class="required-text">
                    {formik.errors.first_name ? formik.errors.first_name : ""}
                  </p>
                  &nbsp;
                </div>
                <div class="mt-1 mb-1 pl-8 pr-6">
                  <input
                    type="text"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    placeholder="Phone"
                    className="block w-full px-4 py-2 bg-white border border-gray-300 rounded"
                  />
                  <p style={{ color: "red" }} class="required-text">
                    {formik.errors.phone ? formik.errors.phone : ""}
                  </p>
                  &nbsp;
                </div>
                {/* <div class="absolute bottom-8 ">
         <hr className="hrLine" />

         </div> */}

                {/* Add more form fields as needed */}
                <div className="flex items-center justify-between pl-8 pr-6 rounded-b">
                  <button
                    className="reset-btn absolute bottom-6 left-8 m-0 font-bold  text-sm px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleClose}
                  >
                    Reset
                  </button>

                  <button
                    className="bg-black absolute bottom-6 right-6 m-0 text-white font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </header>

        <hr className="hrLine" />
        <div class=" mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <div class="flex title-font font-medium items-center text-gray-900 mb-4 non-ex-employee md:mb-0">
            <button
              class="bg-gray-800 hover: text-white font-normal py-2 px-4 rounded"
              type="button"
              onClick={() => {
                setTabType("exEmployee");
              }}
            >
              Ex-Employee
            </button>

            <button
              class="bg-gray-300 hover: text-balck font-normal py-2 px-4 rounded"
              type="button"
              onClick={() => {
                setTabType("AddNonJoiner");
              }}
            >
              Non-Joiners
            </button>
          </div>

          <div class="md:ml-auto flex flex-wrap items-center text-base justify-center pr-5">
            <button
              class="bg-white border border-slate-950  hover: text-balck font-medium py-2 px-4 add-review-btn rounded"
              type="submit"
              onClick={() => {
                editableForm();
              }}
            >
              Add Review
            </button>
          </div>
        </div>
        {formType === "exEmployee" && (
          <AddExEmployeeForm reset={handleResetButton} />
        )}
        {/* {formType === "exEmployee" && <div>Shbhaajjs</div> } */}
        {formType === "AddNonJoiner" && (
          <AddNonJoinerForm reset={handleResetButton} />
        )}
        {/* card */}
        {tabType === "exEmployee" ? <ExEmployess /> : <NonJoiners />}
      </div>
    </>
  );
};
export default Data;
