import React from "react";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";

const DonutChart = ({ data }) => {
  const COLORS = ["#0976DA", "#EFF4F8"];

  return (
    <PieChart width={350} height={350}> 
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        innerRadius={60}
        outerRadius={150}
        fill="#8884d8"
        paddingAngle={2}
        dataKey="value"
        cornerRadius={10}  
      >
        {data.map((entry, index) => (
          <Cell
            key={`inner-cell-${index}`}
            fill={COLORS[index % COLORS.length]}
          />
        ))}
      </Pie>
      <Legend />
      <Tooltip />
    </PieChart>
  );
};

export default DonutChart;
