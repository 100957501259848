import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { notification } from "antd";
import Image from "../../../Assets/Images/close.png";
import { deleteReviewRequest } from "../../../Services/authHeader";
import { toast } from "react-toastify";

const ReportUser = ({ handleClose, employee, doctype }) => {
  const validationSchema = Yup.object().shape({
    details: Yup.string().required("Enter Details"),
  });

  const formik = useFormik({
    initialValues: {
      details: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      await handleDeleteRequest(values);
      formik.resetForm();
      handleClose();
    },
  });

  console.log(doctype,"ppppp")

  const handleDeleteRequest = async (values) => {
    const user = JSON.parse(sessionStorage.getItem("usr"));
    const requestCreator = user.email;

    const payload = {
      data: {
        reference_doctype: doctype,
        reference_name: employee.name,
        status: "Requested",
        request_creator: requestCreator,
        request_type: "Report",
        details: values.details,
      },
    };

    try {
      const res = await deleteReviewRequest(payload);
      if (res.status === 200) {
        toast.success("The Review is reported");
        handleClose();
      } else {
        toast.error("Failed to report the review");
      }
    } catch (error) {
      console.error("Error deleting review:", error);
      toast.error("Error deleting review");
    }
  };

  return (
    <div className="h-full w-1/4 h-1446px popup-form popup-form-dashboard add-user-popup">
      <button
        className="absolute top-4 right-4 p-2 text-gray-600"
        onClick={handleClose}
      >
        <img src={Image} alt="close" />
      </button>
      <form onSubmit={formik.handleSubmit}>
        <div className="add-user-header">
          <h1>Report</h1>
          <p>Report the View of {employee.name}</p>
        </div>
        <hr className="hrLine" />
        <div className="add-user-body">
          <div className="form-field">
            <textarea
              name="details"
              value={formik.values.details}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Details"
              autoComplete="off"
              id="details"
              className="textarea-popup"
            />
            {formik.touched.details && formik.errors.details && (
              <p className="required-text">{formik.errors.details}</p>
            )}
          </div>
        </div>
        <div className="add-user-footer">
          <button
            type="button"
            className="reset-btn"
            onClick={() => formik.resetForm()}
          >
            Reset
          </button>
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ReportUser;
