import React from 'react'
import Navbar from '../../Widgets/navbar'
const privacypolicy = () => {
  const containerStyle = {
    maxWidth: '1100px',
    margin: '0 auto',
    padding: '20px',
    borderRadius: "12px",
  };

  const headingStyle = {
    color: '#333',
  };

  const sectionStyle = {
    marginBottom: '30px',
    border: '1px solid #ddd',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
  };

  const paragraphStyle = {
    lineHeight: '1.6',
  };
  return (
    <>
    <Navbar/>
      <>
      <div style={containerStyle}>
      <h1 style={headingStyle} className='mb-4 text-3xl font-bold'>Privacy Policy</h1>
      <h2 className='mb-2 text-2xl'>Effective Date: 01/09/2023</h2>
      <section style={sectionStyle}>
        <h2></h2>
        <p style={paragraphStyle}>At SecondJudge.com, we value your privacy and are committed to protecting
         your personal information. This Privacy Policy outlines how we collect, use, disclose, 
         and secure your information when you visit our website and use our services. By accessing
          or using our website, you acknowledge and agree to the practices described in this policy.</p>
      </section>

      <section style={sectionStyle}>
      <h2 className='font-bold mb-2'>1. Information we collect:</h2>
      <h3 className='font-bold mb-2'>1.1 Personal Information:</h3>
        <p style={paragraphStyle}>When you visit our website or submit a review, we may collect 
        personal information including your name, email address, and any other information you voluntarily 
        provide while using our services. This includes any personal information contained in the documents you upload for your review.
        </p>
        <h3 className='font-bold mb-2'>1.2 Non-Personal Information:</h3>
        <p>We may also collect non-personal information such as your IP address, browser type, operating 
            system, referring URLs, and other technical information about your device. This information is 
            used to analyze trends, administer the site, track user movements, and gather demographic information for aggregate use.
        </p>
      </section>

      <section style={sectionStyle}>
      <h2 className='font-bold mb-2'>2. Use of Information:
    </h2>
      <h3 className='font-bold mb-2'>2.1 Personal Information:</h3>
        <p style={paragraphStyle}>We may use your personal information, including the information contained 
        in the documents you upload, to facilitate your use of our services, respond to your inquiries or requests,
         provide customer support, and improve our website and services. We may also use your information to send you
          promotional materials or newsletters if you have subscribed to receive them. You may opt-out of receiving these communications at any time.
        </p>
        <h3 className='font-bold mb-2'>2.2 Non-Personal Information:</h3>
        <p>Non-personal information is used for statistical purposes and to enhance your experience on our website. 
            We may also use this information for targeted advertising and to analyze user trends, preferences, 
            and behavior to improve our services.
        </p>
      </section>

      <section style={sectionStyle}>
      <h2 className='font-bold mb-2'>3. Disclosure of Information:</h2>
      <h3 className='font-bold mb-2'>3.1 Service Providers:</h3>
        <p style={paragraphStyle}>We may share your personal information, including the information 
        contained in the documents you upload, with trusted third-party service providers who assist us in 
        operating our website or providing our services. These service providers are bound by confidentiality 
        agreements and are only permitted to use your information for the specified purposes.
        </p>
        <h3 className='font-bold mb-2'>3.2 Legal Requirements:</h3>
        <p>We may disclose your information if required to do so by law or in response to a valid legal request,
             such as a court order or government investigation. We may also disclose your information to protect 
             our rights, property, or safety, or the rights, property, or safety of others.

        </p>
      </section>
      <section style={sectionStyle}>
      <h2 className='font-bold'>4. Security of Uploaded Documents:</h2>
        <p style={paragraphStyle}>We take reasonable measures to protect the uploaded documents from 
        unauthorized access, disclosure, alteration, or destruction. We have implemented strict security 
        protocols and use industry-standard encryption technologies to safeguard your information. However, 
        no security system is completely foolproof, and we cannot guarantee the absolute security of the documents
         you upload. You upload documents at your own risk.
        </p>
        
      </section>
      <section style={sectionStyle}>
      <h2 className='font-bold'>5. Cookies and Tracking Technologies:</h2>
        <p style={paragraphStyle}>When you visit our website or submit a review, we may collect 
        personal information including your name, email address, and any other information you voluntarily 
        provide while using our services. This includes any personal information contained in the documents you upload for your review.
        </p>
      </section>
      <section style={sectionStyle}>
      <h2 className='font-bold'>6. Data Retention:</h2>
        <p style={paragraphStyle}>We retain your personal information, including the documents you upload, for as long 
        as necessary to provide our services and to comply with legal obligations. If you would like us to delete your 
        personal information or any uploaded documents, please contact us at [Admin@secondjudge.com  ].
        </p>
      </section>
      <section style={sectionStyle}>
      <h2 className='font-bold'>7. Children's Privacy:</h2>
        <p style={paragraphStyle}>Our website is not intended for use by children under the age of 13. 
        We do not knowingly collect personal information from children. If you believe we have inadvertently
         collected personal information from a child, please contact us immediately, and we will take appropriate
          steps to remove the information from our records.
        </p>
      </section>
      <section style={sectionStyle}>
      <h2 className='font-bold'>8. Third-Party Links:</h2>
        <p style={paragraphStyle}>Our website may contain links to third-party websites or services. We are not responsible 
        for the content, privacy policies, or practices of these third-party websites. We encourage you to review the privacy 
        policies of those websites before providing any personal information.
        </p>
      </section>
      <section style={sectionStyle}>
      <h2 className='font-bold'>9. Updates to this Privacy Policy:</h2>
        <p style={paragraphStyle}>We reserve the right to update or modify this Privacy Policy at any time. 
        The updated policy will be posted on our website with a revised effective date. We encourage you to 
        review this policy periodically to stay informed about how we protect your information.
        </p>
        <p style={paragraphStyle}><p style={paragraphStyle}>If you have any questions, concerns, or requests regarding 
        this Privacy Policy, please contact us at [Admin@secondjudge.com  ].

        </p>
        </p>
      </section>

    </div>
      </>
    </>
    
  )
}

export default privacypolicy