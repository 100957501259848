import React from "react";
import Navbar from "../../Widgets/navbar";
const career = () => {
  return (
    <>
      <Navbar />
      <div className="container-fluid m-auto about-us-page">
        <h1 className="banner-style">Career</h1>
        <div className=" container m-auto about-us-box">
          <section className="mb-8">
            <p>
              Sed ut enim quis nunc lobortis pharetra eget sit amet eros. Fusce
              aliquam posuere sapien, ac sollicitudin velit semper sed. Sed
              maximus vitae turpis nec hendrerit. Suspendisse sit amet enim
              nisi. Vivamus dapibus nunc augue, nec pharetra sapien fermentum
              at. Proin feugiat imperdiet sapien nec accumsan. Nulla convallis a
              dui in sollicitudin. Maecenas scelerisque enim eu nulla efficitur
              convallis. Donec at orci a magna viverra ultricies. Maecenas
              ultricies dui sit amet leo porta viverra eu sit amet turpis. Donec
              dui risus, gravida quis rutrum eu, hendrerit vitae purus. In
              semper arcu risus, quis laoreet quam rutrum quis. Sed felis augue,
              sodales in dictum a, fermentum ac orci.
            </p>
            <p>
              Maecenas quis nisl est. Vivamus rutrum nulla turpis, et dapibus
              odio mattis et. Quisque feugiat dapibus lorem, ut congue arcu.
              Donec aliquet, augue ut faucibus feugiat, magna lectus gravida
              sem, volutpat vehicula turpis neque sed libero. Integer
              ullamcorper ipsum at nisl tincidunt molestie. Phasellus venenatis
              fringilla imperdiet. Suspendisse fringilla tortor leo, eget
              condimentum metus tincidunt quis. In hac habitasse platea
              dictumst. Nulla facilisi. Integer eu dictum ipsum. Quisque
              vulputate orci vel congue luctus.
            </p>
            <p>
              Nulla vestibulum elit non nunc pharetra iaculis. Maecenas eget
              faucibus erat. Quisque mollis urna in enim sollicitudin, quis
              dignissim arcu porttitor. Curabitur ac orci a ipsum scelerisque
              rutrum. Praesent vitae mi commodo sem mollis maximus. Morbi at
              nunc dolor. Praesent ac rhoncus tellus. Phasellus nec nisl
              tristique, elementum metus id, rutrum est. Pellentesque aliquet
              vel magna at efficitur. Morbi vitae est non nisi rutrum rutrum. Ut
              et luctus elit. Vestibulum congue vehicula pretium.
            </p>
            <p>
              Sed venenatis maximus neque eget imperdiet. Nulla facilisi. Mauris
              mollis ligula non purus vehicula pellentesque. Vivamus eget justo
              vel metus aliquet condimentum sed eu leo. Morbi sed augue urna.
              Maecenas maximus efficitur arcu, eu venenatis dui pulvinar eu. Nam
              vestibulum vehicula libero, id feugiat nibh pulvinar nec.
              Pellentesque sed ligula non urna consectetur lacinia. Ut vehicula
              nibh eu sodales gravida. Aliquam dictum risus eu tincidunt
              elementum.
            </p>
            <p className="font-semibold text-lg mt-3">
              Maecenas tincidunt ullamcorper magna, a luctus quam ultrices vel.
              Pellentesque congue, ex non venenatis auctor, nisi eros
              consectetur massa, vitae molestie purus risus ac nunc.
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default career;
