import React, { useState } from "react";
import Logo from "../../Assets/Images/Logo.png";
import Login from "../../Assets/Images/Login.jpg";
import { TextField } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import Axios from "../../Services/commonHttp";
import { useNavigate } from "react-router-dom";
const ForgetPassword = () => {
  const navigate = useNavigate();
  const [alertMssg, setAlertMssg] = useState(undefined);
  const formValidation = Yup.object().shape({
    email: Yup.string().email().required("Please enter your email or phone"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: formValidation,
    onSubmit: (values) => {
      console.log("values", values);
      forgetAccount(values);
    },
  });
  async function forgetAccount(values) {
    try {
      await Axios({
        method: "Post",
        url: "/api/method/watcherp.customizations.user.forgot_password",
        data: {
          email: values.email,
        },
      })
        .then((response) => {
          setAlertMssg({ type: "Sucess" });
          if (response.status === 200) {
            navigate("/confirmOTP");
          }
        })
        .catch((error) => {
          navigate("/ForgetPassword");
          setAlertMssg({ type: "Error" });
          console.log("error", error);
        });
    } catch (error) {
      console.log("error", error);
    }
  }
  return (
    <>
      <section class="gradient-form h-full bg-neutral-200 dark:bg-neutral-700">
        {/* <div class="container h-full p-10">
        <div class="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 dark:text-neutral-200"> */}
        <div class="w-100%">
          <div class="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
            <div class="g-0 lg:flex lg:flex-wrap">
              {/* <!-- Left column container--> */}
              {/* <!-- Left column container--> */}
              <div class="px-4 md:px-0 form-side">
                <div className="bg-white  px-4 lg:px-6 py-12 ">
                  <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                    <a href="/" class="flex items-center">
                      {" "}
                      <img
                        src={Logo}
                        className="mr-3 h-6 sm:h-9 nav-logo"
                        alt="Flowbite Logo"
                      />
                    </a>
                  </div>
                </div>
                <div class="md:mx-6 md:p-12 padding-top-1">
                  {/* Show Error Message */}
                  {alertMssg?.type === "Sucess" && (
                    <div
                      class="mb-4 rounded-lg px-6 py-5 text-base text-green-600"
                      role="alert"
                    >
                      Login Sucessfully !
                    </div>
                  )}

                  {/* <!--Logo--> */}
                  <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight pb-6 text-gray-900 md:text-2xl dark:text-gray-800">
                      Forgot Password ?
                    </h1>
                    <form
                      onSubmit={formik.handleSubmit}
                      className="space-y-4 md:space-y-6"
                      action="#"
                    >
                      <div>
                        <label
                          for="email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                        >
                          {/* Email or Phone */}
                        </label>
                        <TextField
                          label="Email or Phone"
                          variant="outlined"
                          type={"text"}
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          id="email"
                          className="bg- border border-gray-400 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-700 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder=""
                          required=""
                        />
                        <p style={{ color: "red" }}>
                          {formik.errors.email ? formik.errors.email : ""}
                        </p>
                      </div>
                      {alertMssg?.type === "Error" && (
                        <div
                          class="mb-4 rounded-lg px-6 py-5  color"
                          role="alert"
                        >
                          This email id does not exist.
                        </div>
                      )}
                      <button
                        type="submit"
                        className="w-full gradient-btn text-white bg-gray-800 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-800 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      >
                        Send OTP
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              {/* <!-- Right column container with background and description--> */}
              <div class="flex items-center rounded-b-lg image-side rounded-r-lg lg:rounded-bl-none justify-end">
                <img
                  src={Login}
                  className="second-judge-banner"
                  alt="Flowbite Logo"
                />
              </div>
            </div>
          </div>
        </div>
        {/* </div>
      </div> */}
      </section>
    </>
  );
};
export default ForgetPassword;
