import React from "react"




const PaymentCallback = (props) => {

    console.log(props)

    return (
        <>
        <h1>
            Payment Page
        </h1>

        </>
    )
}

export default PaymentCallback;